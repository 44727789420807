import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

/**
 * General inline link component.
 */
const Link = ({
  classNames,
  href = '#',
  children,
  target,
  rel,
  onClick = noop
}) => (
  <a
    className={cn('hpl2-Link', {}, classNames)}
    href={href}
    target={target}
    rel={rel}
    onClick={event => onClick(event, href)}
  >
    {children}
  </a>
);

Link.displayName = 'Link';
Link.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Arbitrary child content. */
  children: PropTypes.node.isRequired,
  /** The href target URL of the link. */
  href: PropTypes.string,
  /** Optional link target. */
  target: PropTypes.string,
  /** Optional link rel. */
  rel: PropTypes.string,
  /** preventDefault is not yet called by the component itself. Call signature: (event, href) => {} */
  onClick: PropTypes.func
};

export default Link;
