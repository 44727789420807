import React, { Component } from "react";
import axios from "axios";
import ViewStatistics from "./ViewStatistics/index"
import './Statistics.scss';
import { Spin } from "antd";

class AppStatistics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageLoading: false,
            isLoading: false,
            isViewContent: false,
            isClicked: "",
            clickCount: [],
            searchCount: [],
        }
        this.viewAppStatistics = this.viewAppStatistics.bind(this);

    }
    viewContent = (selected) => {
        this.setState({ isLoading: true, isViewContent: true, isClicked: selected });
    }

    getAppEventCount() {
        this.setState({ pageLoading: true });
        const options = {
            headers: { "Content-Type": "application/json" },
        };
        axios.post("/als-api/get-app-event-count", options)
        .then((response) => {
            if (response) {
                this.setState({ clickCount: response.data.click, searchCount: response.data.search, pageLoading: false });
            }
        });
    }

    componentDidMount() {
        this.getAppEventCount();
    }

    viewAppStatistics() {
        this.setState({ isViewContent: false });
    }

    render() {
        const { isViewContent, clickCount, searchCount } = this.state;
        return (
            <>
            <Spin spinning={this.state.pageLoading}>
            {!isViewContent? (
            <div className="statistics-container">
                <div className="statistics-card" onClick={() => this.viewContent("App Click")}>
                    <div className="statistics-header">
                        <h1 className="statistics-title">App Click</h1>
                    </div>
                    <div className="statistics-content">
                        <div className="statistics-total">
                            <p className="statistics-values">{clickCount[0]?.internal_event_count ? Number(clickCount[0]?.internal_event_count) + Number(clickCount[0]?.external_event_count) : 0}</p>
                            <p className="statistics-heading">Total</p>
                        </div>
                        <div className="statistics-internal">
                            <p className="statistics-values">{clickCount[0]?.internal_event_count ? clickCount[0]?.internal_event_count : 0}</p>
                            <p className="statistics-heading">Internal</p>
                        </div>
                        <div className="statistics-external">
                        <p className="statistics-values">{clickCount[0]?.external_event_count ? clickCount[0]?.external_event_count : 0}</p>
                            <p className="statistics-heading">External</p>
                        </div>
                    </div>
                </div>
                <div className="statistics-card" onClick={() => this.viewContent("App Search")}>
                    <div className="statistics-header">
                        <h1 className="statistics-title">App Search</h1>
                    </div>
                    <div className="statistics-content">
                        <div className="statistics-total">
                            <p className="statistics-values">{searchCount[0]?.internal_event_count ? Number(searchCount[0]?.internal_event_count) + Number(searchCount[0]?.external_event_count): 0}</p>
                            <p className="statistics-heading">Total</p>
                        </div>
                        <div className="statistics-internal">
                            <p className="statistics-values">{searchCount[0]?.internal_event_count ? searchCount[0]?.internal_event_count : 0}</p>
                            <p className="statistics-heading">Internal</p>
                        </div>
                        <div className="statistics-external">
                        <p className="statistics-values">{searchCount[0]?.external_event_count ? searchCount[0]?.external_event_count : 0}</p>
                            <p className="statistics-heading">External</p>
                        </div>
                    </div>
                </div>
            </div>) : (
                <ViewStatistics selected={this.state.isClicked} backStatistics={this.viewAppStatistics} />
            )}
            </Spin>
            </>
        )
    }

};
export default AppStatistics;