import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';
import * as icons from '../../asset/icon';

import Icon from '../Icon';

/**
 * This component is used only toghether with `OptionFlyout`.
 */
const OptionFlyoutSearchField = ({
  classNames,
  onChange = noop,
  value = '',
  placeholder = '',
  disabled = false
}) => {
  const handleChange = event => {
    onChange(event.currentTarget.value, event);
  };
  return (
    <div
      className={cn('hpl2-OptionFlyoutSearchField', { disabled }, classNames)}
    >
      <input
        disabled={disabled}
        className="hpl2-OptionFlyoutSearchField__input"
        placeholder={placeholder}
        type="text"
        onChange={handleChange}
        value={value}
      />
      <Icon source={icons.Search} />
      <div className="hpl2-OptionFlyoutSearchField__line" aria-hidden />
      {value.length > 0 && (
        <button
          className="hpl2-OptionFlyoutSearchField__clear"
          type="button"
          onClick={() => onChange('')}
        >
          <Icon block source={icons.Cancel} />
        </button>
      )}
    </div>
  );
};

OptionFlyoutSearchField.displayName = 'OptionFlyoutSearchField';
OptionFlyoutSearchField.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Call signature: (value, event) => {} */
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default OptionFlyoutSearchField;
