import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Content from "../components/Content/Content"
import Apps from "../components/AllApps/Index";
import PrivateRoute from "./PrivateRoute";
import MicroFrontend from "../components/AllApps/MicroFrontend";
import LayoutComponent from "./layout.container";
import AccessContainer from "../domain/accessWindow";
import SuccessWindow from "../domain/accessWindow/successWindow";
import Settings from "../components/Settings/settings";
import AllUsers from "../components/Settings/AllUsers";
import ViewStatistics from "../components/Settings/AppStatistics/ViewStatistics"
import Reports from "../components/Reports/index"
import ReportIframe from "../components/Reports/ReportIframe"

import Berechnungsmatrix from "../components/Others/PowerApps/BerechnungsMatrixSonderwerkzeug.js";
import AppNotFound from "../components/AppNotFound";

const {
  REACT_APP_PR_RES_HOST: priceResponseHost,
  REACT_APP_SSBI_MANAGER_HOST: ssbiMangerHost,
  REACT_APP_CONTENT_AUTOMATION_HOST: contentAutomationHost,
  REACT_APP_MARKET_LIST_PRICES_HOST: marketListPricesHost,
  REACT_APP_PM_PRICE_OPTIMIZER_HOST: pmPriceOptimizerHost,
  REACT_APP_PM_PRICING_INTERNATIONAL_HOST: pricingInternationalHost,
} = process.env;

const ContentAutomation = ({ history }) => (
  <MicroFrontend
    history={history}
    host={contentAutomationHost}
    name="ContentAutomation"
  />
);
const SSBIManager = ({ history }) => (
  <MicroFrontend history={history} host={ssbiMangerHost} name="SSBIManager" />
);
const PriceResponse = ({ history }) => (
  <MicroFrontend
    history={history}
    host={priceResponseHost}
    name="PriceResponse"
  />
);
const MarketListPrices = ({ history }) => (
  <MicroFrontend
    history={history}
    host={marketListPricesHost}
    name="MarketListPrices"
  />
);
const PMPriceOptimizer = ({ history }) => (
  <MicroFrontend
    history={history}
    host={pmPriceOptimizerHost}
    name="PMPriceOptimizer"
  />
);
const PricingInternational = ({ history }) => (
  <MicroFrontend
    history={history}
    host={pricingInternationalHost}
    name="PricingInternational"
  />
);
const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute
        path="/not-found"
        component={AppNotFound}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/others/berechnungsmatrix"
        component={Berechnungsmatrix}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/ssbi-manager"
        component={SSBIManager}
        layout={LayoutComponent}
      />
      <PrivateRoute
        path="/content-automation"
        component={ContentAutomation}
        layout={LayoutComponent}
      />
      <PrivateRoute
        path="/price-response"
        component={PriceResponse}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/market-list-prices"
        component={MarketListPrices}
        layout={LayoutComponent}
      />
      <PrivateRoute
        path="/pm-price-optimizer"
        component={PMPriceOptimizer}
        layout={LayoutComponent}
      />
      <PrivateRoute
        path="/pricing-international"
        component={PricingInternational}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/settings"
        component={Settings}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/all-users"
        component={AllUsers}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/view-statistics"
        component={ViewStatistics}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/reports"
        component={Reports}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/reports/:reportId"
        component={ReportIframe}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/apps"
        component={Apps}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/others"
        component={Apps}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/home"
        component={Apps}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/request-access"
        component={AccessContainer}
        layout={LayoutComponent}
      />
      <PrivateRoute
        exact
        path="/request-success"
        component={SuccessWindow}
        layout={LayoutComponent}
      />
      <Route exact path="/" component={Content} />
      <Route exact path="/access" component={Content} />
      <Redirect to="/not-found" />
    </Switch>
  </BrowserRouter>
);
export default Routes;
