import { useEffect } from 'react';

export default (onKeyDown, keyCode = false) => {
  useEffect(() => {
    const handleKeyDown = event => {
      if (keyCode === false || event.keyCode === keyCode) {
        onKeyDown(event);
      }
    };

    global.document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      global.document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);
};
