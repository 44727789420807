import React from "react";
import { Row, Col } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const getInformationTextAndIcon = (permission) => {
  if (permission === "granted") {
    return [
      "You already have access to this application",
      <CheckCircleOutlined />,
    ];
  } else if (permission === "denied") {
    return [
      <span style={{ color: "#ff0000" }}>Request Denied</span>,
      <CloseCircleOutlined />,
    ];
  } else {
    return ["Request already Sent", <CheckCircleOutlined />];
  }
};

const ValidatedCard = (props) => {
  const { appName, userRole } = props;
  const [msg, icon] = getInformationTextAndIcon(userRole[0].permission_name);
  return (
    <React.Fragment>
      <div className="access-card">
        <Row className="access-card-title">
          <Col style={{ margin: "auto" }}>
            <img alt={appName} src={require(`../icons/${appName}.svg`)} />
          </Col>
        </Row>
        <Row style={{ marginTop: "21px" }}>
          <Col style={{ margin: "auto" }}>{icon}</Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col className="access-card-desc">
            <span className="access-info">{msg}</span>
          </Col>
        </Row>
        {userRole[0].permission_name === "denied" ? <a>Contact Admin</a> : null}
      </div>
    </React.Fragment>
  );
};

export default ValidatedCard;
