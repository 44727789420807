export { default as ThreeDRotation } from './3d-rotation.svg';
export { default as AccountCircle } from './account-circle.svg';
export { default as AddShoppingCart } from './add-shopping-cart.svg';
export { default as Add } from './add.svg';
export { default as Apps } from './apps.svg';
export { default as Archive } from './archive.svg';
export { default as ArrowBack } from './arrow-back.svg';
export { default as ArrowDown } from './arrow-down.svg';
export { default as ArrowDropDown } from './arrow-drop-down.svg';
export { default as ArrowDropUp } from './arrow-drop-up.svg';
export { default as ArrowForward } from './arrow-forward.svg';
export { default as ArrowLeft } from './arrow-left.svg';
export { default as ArrowRight } from './arrow-right.svg';
export { default as ArrowUp } from './arrow-up.svg';
export { default as AttachFile } from './attach-file.svg';
export { default as Block } from './block.svg';
export { default as Bookmark } from './bookmark.svg';
export { default as Cached } from './cached.svg';
export { default as CalendarToday } from './calendar-today.svg';
export { default as CamTools } from './cam-tools.svg';
export { default as Cancel } from './cancel.svg';
export { default as CheckCircle } from './check-circle.svg';
export { default as Check } from './check.svg';
export { default as ChevronLeft } from './chevron-left.svg';
export { default as ChevronRight } from './chevron-right.svg';
export { default as Clear } from './clear.svg';
export { default as Close } from './close.svg';
export { default as ColorFill } from './color-fill.svg';
export { default as ContactSupport } from './contact-support.svg';
export { default as DateRange } from './date-range.svg';
export { default as Delete } from './delete.svg';
export { default as Description } from './description.svg';
export { default as Done } from './done.svg';
export { default as DragHorizontal } from './drag-horizontal.svg';
export { default as DragVertical } from './drag-vertical.svg';
export { default as Drag } from './drag.svg';
export { default as Edit } from './edit.svg';
export { default as ExpandLess } from './expand-less.svg';
export { default as ExpandMore } from './expand-more.svg';
export { default as FileCopy } from './file-copy.svg';
export { default as Filter } from './filter.svg';
export { default as FindReplace } from './find-replace.svg';
export { default as Fingerprint } from './fingerprint.svg';
export { default as FolderAdd } from './folder-add.svg';
export { default as Folder } from './folder.svg';
export { default as Fullscreen } from './fullscreen.svg';
export { default as GetApp } from './get-app.svg';
export { default as Hearing } from './hearing.svg';
export { default as Help } from './help.svg';
export { default as Home } from './home.svg';
export { default as IdentifierApp } from './identifier-app.svg';
export { default as Image } from './image.svg';
export { default as Info } from './info.svg';
export { default as Infrastructure } from './infrastructure.svg';
export { default as Inventory } from './inventory.svg';
export { default as LinkApp } from './link-app.svg';
export { default as Link } from './link.svg';
export { default as List } from './list.svg';
export { default as Login } from './login.svg';
export { default as Logout } from './logout.svg';
export { default as Machine } from './machine.svg';
export { default as Mail } from './mail.svg';
export { default as MeetingRoom } from './meeting-room.svg';
export { default as MenuItemTools } from './menu-item-tools.svg';
export { default as Menu } from './menu.svg';
export { default as Merge } from './merge.svg';
export { default as MoreHoriz } from './more-horiz.svg';
export { default as MoreVert } from './more-vert.svg';
export { default as MoveFolder } from './move-folder.svg';
export { default as NextPosition } from './next-position.svg';
export { default as NoMeetingRoom } from './no-meeting-room.svg';
export { default as Notifications } from './notifications.svg';
export { default as Open } from './open.svg';
export { default as OutlineInfo } from './outline-info.svg';
export { default as OutlineLock } from './outline-lock.svg';
export {
  default as OutlineSupervisedUserCircle
} from './outline-supervised-user-circle.svg';
export { default as OutlineUpdate } from './outline-update.svg';
export { default as Pause } from './pause.svg';
export { default as PersonAdd } from './person-add.svg';
export { default as Person } from './person.svg';
export { default as Phone } from './phone.svg';
export { default as Placeholder } from './placeholder.svg';
export { default as PlayArrow } from './play-arrow.svg';
export { default as Redo } from './redo.svg';
export { default as Remove } from './remove.svg';
export { default as Reply } from './reply.svg';
export { default as Reset } from './reset.svg';
export { default as Restore } from './restore.svg';
export { default as Rfid } from './rfid.svg';
export { default as SaveAlt } from './save-alt.svg';
export { default as Save } from './save.svg';
export { default as ScanIndentify } from './scan-identify.svg';
export { default as ScanLink } from './scan-link.svg';
export { default as Search } from './search.svg';
export { default as Settings } from './settings.svg';
export { default as Share } from './share.svg';
export { default as ShoppingCart } from './shopping-cart.svg';
export { default as Split } from './split.svg';
export { default as StarBorder } from './star-border.svg';
export { default as StarHalf } from './star-half.svg';
export { default as Star } from './star.svg';
export { default as Stop } from './stop.svg';
export { default as Straighten } from './straighten.svg';
export { default as SwapVert } from './swap-vert.svg';
export { default as Timelapse } from './timelapse.svg';
export { default as Timer } from './timer.svg';
export { default as ToolScout } from './tool-scout.svg';
export { default as Undo } from './undo.svg';
export { default as UnfoldLess } from './unfold-less.svg';
export { default as UnfoldMore } from './unfold-more.svg';
export { default as VisiblityOff } from './visibility-off.svg';
export { default as Visibility } from './visibility.svg';
export { default as Warning } from './warning.svg';
