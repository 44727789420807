import React, { Children } from 'react';
import PropTypes from 'prop-types';
import sum from 'lodash/sum';

import cn from '../../lib/class-name';

/**
 * The `Table` component. Use an array of `TableHeaderCell`s for the header and `TableRow`s as children.
 * Column widths have to be defined on each `TableHeaderCell` and affect the whole column.
 */
const Table = ({ classNames, header, children }) => {
  const widths = Children.map(header, ({ props: { width = 1, fixed } }) =>
    fixed ? 0 : width
  );

  const totalWidths = sum(widths);
  const computeWidth = (width, fixed) =>
    fixed ? `${width}px` : `${(width / totalWidths) * 100}%`;
  const computedWidths = Children.map(
    header,
    ({ props: { width = 1, fixed } }) => computeWidth(width, fixed)
  );

  return (
    <div className={cn('hpl2-Table', {}, classNames)}>
      <div className="hpl2-Table__header">
        {Children.map(header, (headCell, i) =>
          React.cloneElement(headCell, {
            computedWidth: computedWidths[i]
          })
        )}
      </div>
      <div className="hpl2-Table__content">
        {Children.map(children, child =>
          React.cloneElement(child, { computedWidths })
        )}
      </div>
    </div>
  );
};

Table.displayName = 'Table';
Table.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Array of `TableHeaderCell`s for the header. */
  header: PropTypes.node.isRequired,
  /** `TableRow`s, `TableRowGroup`s or `TableSection`s as children. */
  children: PropTypes.node.isRequired
};

export default Table;
