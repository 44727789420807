import React, { Component } from "react";
import ReturnButton from "./../ReturnButton/ReturnButton";
import { Row, Col, Avatar, Input } from "antd";
import Table from "../../Table"
import { SearchOutlined } from "@ant-design/icons";
import { ModifyRole } from "../TabelCellComponents/roleCell.component";
import UserCell from "../TabelCellComponents/userCell.component";
import ProjectCell from "../TabelCellComponents/projectCell.component";
import { ModifyScope } from "../TabelCellComponents/scopeCell.component";
import { I18nProvider } from '../../../i18n';
import translate from '../../../i18n/translate';
import { getUserLangPreference } from "../../../i18n/utils";

class AllUsersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationInfo: { current: 1, pageSize: 10 },
    };
  }

  getColumns = () => {
    const { paginationInfo } = this.state;
    const {
      datasource,
      deleteUser,
      deleteApp,
      onRoleChange,
      onScopeChange,
      appRoles,
      appScopes,
    } = this.props;

    const columns = [
      {
        title: <span>{translate('Settings.UserId')}</span>,
        dataIndex: "user_email",
        key: "user_email",
        width: "30%",
        render: (value, row, index) => {
          const userName = row.displayname ? row.displayname : "No Name";
          const trueIndex =
            index + paginationInfo.pageSize * (paginationInfo.current - 1);
          const obj = {
            children: (
              <UserCell name={userName} email={value} deleteUser={deleteUser} />
            ),
            props: {},
          };
          if (index >= 1 && value === datasource[trueIndex - 1].user_email) {
            obj.props.rowSpan = 0;
          } else {
            for (
              let i = 0;
              trueIndex + i !== datasource.length &&
              value === datasource[trueIndex + i].user_email;
              i += 1
            ) {
              obj.props.rowSpan = i + 1;
            }
          }
          return obj;
        },
      },
      {
        title: <span>{translate('Settings.Application')}</span>,
        dataIndex: "project_name",
        width: "50%",
        render: (value, row, index) => {
          return <ProjectCell data={row} deleteApp={deleteApp} />;
        },
      },
      {
        title: <span>{translate('Settings.Roles')}</span>,
        width: "25%",
        render: (value, row, index) => {
          const filteredapproles = appRoles.filter((appRole) => {
            return appRole.project_name === row.project_name;
          });
          return (
            <ModifyRole
              roles={filteredapproles}
              userData={row}
              roleChange={onRoleChange}
            />
          );
        },
      },
      {
        title: <span>{translate('Settings.Scope')}</span>,
        dataIndex: "scopes",
        key: "scopes",
        width: "25%",
        render: (value, row, index) => {
          const filteredappscopes = appScopes.filter((appScope) => {
            return appScope.project_name === row.project_name;
          });
          return (
            <Row style={{ display: "inline-flex" }}>
              <ModifyScope
                data={filteredappscopes}
                userData={row}
                scopeChange={onScopeChange}
              />
            </Row>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    const {
      isSpinning,
      datasource,
      navigateToSettings,
      onSearchChange,
      userName,
    } = this.props;
    return (
      <div className="platform-container">
        <I18nProvider locale={getUserLangPreference()}>
          <Row>
            <div className="flex-layout">
              <Col flex="auto">
                <ReturnButton
                  headerOne="Roles"
                  headerTwo="All Users"
                  path="/settings"
                  onClick={navigateToSettings}
                />
              </Col>
              <Col justify="end">
                <Avatar className="avatar" shape="square" size={60}>
                  <span className="cap-letter">{userName[0]}</span>
                </Avatar>
                <a className="settings-profile-name"> {userName} </a>
              </Col>
            </div>
          </Row>
          <Row style={{ marginTop: "42px" }}>
            <Col flex="auto">
              <Input
                placeholder="Search"
                className="input-search"
                onChange={onSearchChange}
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <Row className="table-container">
            <Table
              columns={this.getColumns()}
              loading={isSpinning}
              pagination={{}}
              scroll={{ y: 'calc(100vh - 500px)' }}
              rowKey={(record) => record["user_email"] + record["project_name"]}
              data={datasource}
              className={"alluser-table"}
            />
          </Row>
        </I18nProvider>
      </div>
    );
  }
}

export default AllUsersComponent;
