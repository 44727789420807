import React, { Component } from "react";
import RoleComponent from "./Roles.component";
import { getLoggedUserRoles, isUserAdmin, isProductOwner, getPoProductList } from "../../../Utils/Utils";
import {
  getRequestedUsersAndRoles,
  getRequestedPoUsersAndRoles,
  getDeniedUsersAndRoles,
  getDeniedPoUsersAndRoles,
  updateUserRole,
  deleteUserRole,
  appRoles,
} from "../../../services/queries/dataPlatFormApi";

class Roles extends Component {
  state = {
    logged_user_roles: [],
    allRequestedUsers: [],
    appRoles: [],
    activeKey: "1",
    accessColumn: "Settings.AccessApproval",
    isLoading: true
  };

  navigateToAllUsers = () => {
    this.props.history.push("/all-users");
  };
  componentDidMount = () => {
    const userRoles = getLoggedUserRoles();
    this.setState({ logged_user_roles: userRoles });
    if (isUserAdmin(["settings_admin"])) {
      Promise.all([getRequestedUsersAndRoles(), appRoles()])
        .then((res) => {
          console.log("response", res);
          this.setState({
            allRequestedUsers: res[0].data,
            appRoles: res[1].data,
            isLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false});
        });
    }
    else if (isProductOwner())
      Promise.all([getRequestedPoUsersAndRoles(getPoProductList()), appRoles()])
        .then((res) => {
          console.log("response", res);
          this.setState({
            allRequestedUsers: res[0].data,
            appRoles: res[1].data,
            isLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false});
        });
  };

  //Merge array cells
  createNewArr = (data) => {
    return data
      .reduce((result, item) => {
        //First, take the showHoverImage field as a new array result
        if (result.indexOf(item.user_name) < 0) {
          result.push(item.user_name);
        }
        return result;
      }, [])
      .reduce((result, user_name) => {
        //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
        const children = data.filter((item) => item.user_name === user_name);
        result = result.concat(
          children.map((item, index) => ({
            ...item,
            rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
          }))
        );
        return result;
      }, []);
  };
  handleOnAuthorize = async (grant, userRole) => {
    if (grant) {
      userRole.permission_name = "granted";
    } else {
      userRole.permission_name = "denied";
    }
    return await updateUserRole(userRole) != null;
  };
  handleOnDenyApprove = async (grant, userRole) => {
    return await deleteUserRole(userRole) != null;
  };

  handleOnRoleChange = (value, row, index) => {
    row.role_name = value;
  };
  handleOnScopeChange = (value, row, index) => {
    if (value.length > 0) {
      row.scopes = value;
    } else {
      row.scopes = null;
    }
  };
  handleOnTabChange = async (key) => {
    this.setState({isLoading: true})
    if (key === 1) {
      if (isUserAdmin(["settings_admin"])) {
        await getRequestedUsersAndRoles()
          .then((res) => {
            this.setState({
              allRequestedUsers: res.data,
              accessColumn: "Settings.AccessApproval",
              isLoading: false
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ isLoading: false});
          });
      }
      else if (isProductOwner()) {
        await getRequestedPoUsersAndRoles(getPoProductList())
          .then((res) => {
            this.setState({
              allRequestedUsers: res.data,
              accessColumn: "Settings.AccessApproval",
              isLoading: false
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ isLoading: false});
          });
      }

    } else {
      if (isUserAdmin(["settings_admin"])) {
      await getDeniedUsersAndRoles()
        .then((res) => {
          this.setState({
            allRequestedUsers: res.data,
            accessColumn: "Settings.AccessDenied",
            isLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false});
        });
      }
      else if(isProductOwner()) {
        await getDeniedPoUsersAndRoles(getPoProductList())
        .then((res) => {
          this.setState({
            allRequestedUsers: res.data,
            accessColumn: "Settings.AccessDenied",
            isLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false});
        });
      }
    }
    this.setState({
      activeKey: key
    })
  }
  render() {
    return (
      <React.Fragment>
        <RoleComponent
          loggedUserRoles={this.state.logged_user_roles}
          datasource={this.state.allRequestedUsers}
          appRoles={this.state.appRoles}
          onViewUsers={this.navigateToAllUsers}
          onClickAccess={this.handleOnAuthorize}
          onClickDenyApprove={this.handleOnDenyApprove}
          onRoleChange={this.handleOnRoleChange}
          onScopeChange={this.handleOnScopeChange}
          onTabChange={this.handleOnTabChange}
          activeKey={this.state.activeKey}
          accessColumn={this.state.accessColumn}
          isLoading={this.state.isLoading}
        ></RoleComponent>
      </React.Fragment>
    );
  }
}

export default Roles;
