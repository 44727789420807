import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

/**
 * A general flyout which can be used with arbitrary children.
 * A render function has to be provided as children where `onChange` has to be handled.
 */
const Flyout = ({ classNames, value, children, onChange = noop }) => (
  <div className={cn('hpl2-Flyout', [], classNames)}>
    <div className="hpl2-Flyout__content">{children(onChange, value)}</div>
  </div>
);

Flyout.displayName = 'Flyout';
Flyout.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /**
   * The children property is a render function with the signature (onChange, value) => node
   */
  children: PropTypes.func.isRequired,
  /** The value property can take any value. */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  /** Call signature: (value) => {} */
  onChange: PropTypes.func
};

export default Flyout;
