import React from "react";
import { Modal, Button } from "antd";
import "./modal.scss";

export const IdleTimeOutModal = ({ showModal, handleLogout }) => {
  return (
    <Modal
     className="logout-modal"
      title="Data Science Platform"
      visible={showModal}
      footer={[
        <Button key="back" onClick={handleLogout}>
          Ok
        </Button>,
      ]}
    >
      <p className="idle-label">
        Your session on the Data Science Platform timed out. Please login again
      </p>
    </Modal>
  );
};
