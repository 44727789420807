import React, { useState, useContext } from "react";
import { Row, Col, Divider, Button, Tabs } from "antd";
import Table from "../../Table"
import { UserOutlined } from "@ant-design/icons";
import "./Roles.scss";
import Avatar from "antd/lib/avatar/avatar";
import SelectRole from "../TabelCellComponents/roleCell.component";
import ButtonCell from "../TabelCellComponents/buttonCell.component";
import DeniedApproveButtonCell from "../TabelCellComponents/deniedApproveButtonCell.component";
import AuthorizedElement from "../../../Utils/AuthorizedElement";
import appIconData from "../../../domain/accessWindow/icons/appicondata";
import { ScopeContext } from "./../settings";
import SelectScope from "../TabelCellComponents/scopeCell.component";
import { I18nProvider } from "../../../i18n";
import translate from "../../../i18n/translate";
import { getUserLangPreference } from "../../../i18n/utils";
import moment from 'moment';
const { TabPane } = Tabs;
const RoleComponent = (props) => {
  const appscopes = useContext(ScopeContext);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: 10,
  });

  const {
    loggedUserRoles,
    datasource,
    onViewUsers,
    onClickAccess,
    onClickDenyApprove,
    onRoleChange,
    onScopeChange,
    appRoles,
    onTabChange,
    activeKey,
    accessColumn,
    isLoading

  } = props;
  const adminApps = [];
  const userApps = [];

  loggedUserRoles.forEach((role) => {
    if (role.endsWith("admin") && appIconData[role] !== undefined)
      adminApps.push(appIconData[role]);
    else if (role.endsWith("user") && appIconData[role] !== undefined)
      userApps.push(appIconData[role]);
  });

  const formatDateTime = (value) => {
    var requestedDate = moment(value).format("YYYY-MM-DD")
    console.log(requestedDate);
    return requestedDate;
  }

  const columns = [
    {
      title: <span >{translate('Settings.UserId')}</span>,
      dataIndex: "user_email",
      key: "user_email",
      width: "25%",
      render: (value, row, index) => {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);
        const obj = {
          children: (
            <Row>
              <div className="flex-layout">
                <Col>
                  <Avatar className="avatar" shape="square" size={60}>
                    <span className="cap-letter">K</span>
                  </Avatar>
                </Col>
                <Col style={{ height: "40px", marginLeft: "8px" }}>
                  <Row>
                    <span className="tab-label">{row.displayname}</span>
                  </Row>
                  <Row>
                    <span className="role-email">{value}</span>
                  </Row>
                </Col>
              </div>
            </Row>
          ),
          props: {},
        };
        if (index >= 1 && value === datasource[trueIndex - 1].user_email) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== datasource.length &&
            value === datasource[trueIndex + i].user_email;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: <span>{translate('Settings.Application')}</span>,
      width: "25%",
      dataIndex: "project_name",
      render: (value, row, index) => {
        return (
          <img
            style={{ height: "32px" }}
            src={require(`../../../domain/accessWindow/icons/${value}.svg`)}
            alt={value}
            className="tb-application-comp"
          />
        );
      },
    },
    {
      title: <span >{translate('Settings.Roles')}</span>,
      key: "role_name",
      width: "10%",
      render: (row, text, index) => {
        const filteredapproles = appRoles.filter((appRole) => {
          return appRole.project_name === row.project_name;
        });
        return (
          <SelectRole
            isdisabled={row.permission_name === "requested" ? false : true}
            roles={filteredapproles}
            requestedRole={row.role_name}
            roleChange={(value) => {
              onRoleChange(value, row, index);
            }}
          />
        );
      },
    },
    {
      title: <span >{translate('Settings.Scope')}</span>,
      key: "scopes",
      width: "13%",
      render: (row, text, index) => {
        const scopes = appscopes.filter(
          (scope) => row.project_name === scope.project_name
        );
        return (
          <Row style={{ display: "inline-flex" }}>
            <SelectScope
              data={scopes}
              scopeChange={(value) => {
                onScopeChange(value, row, index);
              }}
            />
          </Row>
        );
      },
    },
    {
      title: <span >{translate('Settings.Date')}</span>,
      width: "12%",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => {
        if (value != null) {
          return formatDateTime(value);
        }
        else {
          return "";
        }
      }
    },
    {
      title: <span >{translate(`${accessColumn}`)}</span>,
      width: "20%",
      render: (row) => {
        console.log(accessColumn);
        if (accessColumn === "Settings.AccessDenied") {
          return <DeniedApproveButtonCell onClickDenyApprove={onClickDenyApprove} userRole={row} />;
        } else {
          return <ButtonCell onClickAccess={onClickAccess} userRole={row} />;
        }
      },
    },
  ];
  const handleChange = (pagination) => {
    setPaginationInfo(pagination);
  };
  return (
    <I18nProvider locale={getUserLangPreference()}>
      <div className="roles-container">
        <Row>
          <div className="flex-layout">
            <Col>
              <span className="role-name admin">{translate('Settings.Admin')}</span>
            </Col>
            {adminApps.map((adminApp) => {
              return (
                <Col style={{ paddingLeft: "30px" }}>
                  <img
                    style={{ height: "32px" }}
                    src={require(`../../../domain/accessWindow/icons/${adminApp}.svg`)}
                    alt={adminApp}
                  />
                </Col>
              );
            })}
          </div>
        </Row>
        <Row style={{ marginTop: "17px" }}>
          <div className="flex-layout">
            <Col style={{ width: "51.98px" }}>
              <span className="role-name user">{translate('Settings.User')}</span>
            </Col>
            {userApps.map((userApp) => {
              return (
                <Col style={{ paddingLeft: "30px" }}>
                  <img
                    style={{ height: "32px" }}
                    src={require(`../../../domain/accessWindow/icons/${userApp}.svg`)}
                    alt={userApp}
                  />
                </Col>
              );
            })}
          </div>
        </Row>
        <AuthorizedElement roles={["settings_admin"]} checkPO={true}>
          <Divider className="divider" />
          <Row>
            <div className="flex-layout">
              <Tabs className="tab-label-container" activeKey={activeKey} onChange={onTabChange}>
                <TabPane
                  key={1}
                  tab={<span className="user-access-request">{translate('Settings.UserAccessRequest')}</span>}
                >
                </TabPane>
                <TabPane
                  key={2}
                  tab={<span className="user-access-request">{translate('Settings.UserReAccessRequest')}</span>}
                >
                </TabPane>
              </Tabs>
              <Col justify="end">
                <Button onClick={onViewUsers} className="view-users-btn">
                  <span className="view-users-lbl">
                    <UserOutlined /> {translate('Settings.ViewUsers')}
                  </span>
                </Button>
              </Col>
            </div>
          </Row>
          <Row className="table-container">
            <Table
              // className="role-table"
              pagination={{}}
              columns={columns}
              data={datasource}
              rowKey={(record) => record["user_email"] + record["project_name"]}
              loading={isLoading}
              onRow={(record, rowIndex) => {
                return { buttonVisible: false, isDisabled: false };
              }}
              onChange={handleChange}
              scroll={{ y: 'calc(100vh - 500px)' }}           
               />
          </Row>
        </AuthorizedElement>
      </div>
    </I18nProvider>
  );
};

export default RoleComponent;
