import React, { useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import escapeRegExp from 'lodash/escapeRegExp';

import cn from '../../lib/class-name';
import FlyoutActionButton from './FlyoutActionButton';
import OptionFlyoutSearchField from './OptionFlyoutSearchField';
import Icon from '../Icon';

import * as icons from '../../asset/icon';

const updateValues = (values, value) => {
  if (values.includes(value)) {
    values = values.filter(v => v !== value);
  } else {
    values = [...values, value];
  }

  return values;
};

const defaultSearchFunction = (optionValue, optionLabel, searchTerm) =>
  !!(optionLabel || optionValue).match(
    new RegExp(escapeRegExp(searchTerm), 'i')
  );

/**
 * The OptionFlyout is used e.g. toghether with the DropDownField or a FilterTag.
 * It can operate in single select or multi select mode.
 * If an array is provided as input value (even if its an empty array) the component is in multi select mode.
 *
 * The default search function, if "searchable" prop is set, is:
 *
 * `
 * (optionValue, optionLabel, searchTerm) =>
 * !!(optionLabel || optionValue).match(new RegExp('${searchTerm}', 'i'));
 * `
 *
 * To handle the search completely outside the component listen to "onSearchChange"
 * and filter the options. Therefore it is important to pass the following "searchFunciton":
 * `() => true`. This disables the default search function.
 */
const OptionFlyout = ({
  classNames,
  children,
  value,
  clearable = false,
  color = false,
  searchable = false,
  clearLabel = 'clear',
  onChange = noop,
  onClose = noop,
  searchPlaceholder = 'Search…',
  searchFunction = defaultSearchFunction,
  onSearchTermChange = noop,
  block = false,
  actionButtons = []
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const multiSelect = Array.isArray(value);
  const values = multiSelect ? value : [value];
  const hasValue = multiSelect ? value.length !== 0 : Boolean(value);

  const clear = () => {
    if (multiSelect) {
      onChange([]);
    } else {
      onChange('');
    }
  };

  return (
    <div className={cn('hpl2-OptionFlyout', { color, block }, classNames)}>
      <div className="hpl2-OptionFlyout__body">
        {searchable && (
          <div className="hpl2-OptionFlyout__searchField">
            <OptionFlyoutSearchField
              value={searchTerm}
              onChange={searchValue => {
                setSearchTerm(searchValue);
                onSearchTermChange(searchValue);
              }}
              placeholder={searchPlaceholder}
            />
          </div>
        )}
        <ul className="hpl2-OptionFlyout__list">
          {React.Children.map(
            children,
            option =>
              (searchFunction(
                option.props.value,
                option.props.label,
                searchTerm
              ) ||
                !searchable) && (
                <li>
                  {React.cloneElement(option, {
                    multiSelect,
                    selected: values.includes(option.props.value),
                    onClick: () =>
                      onChange(
                        multiSelect
                          ? updateValues(values, option.props.value)
                          : option.props.value
                      )
                  })}
                </li>
              )
          )}
        </ul>
      </div>
      {((clearable && hasValue) || actionButtons.length > 0) && (
        <div className="hpl2-OptionFlyout__actions">
          {clearable && hasValue && (
            <FlyoutActionButton
              onClick={clear}
              icon={<Icon source={icons.Close} />}
              label={clearLabel}
            />
          )}
          {actionButtons.length > 0 &&
            actionButtons.map(actionButton =>
              React.cloneElement(actionButton, {
                onClick: (...args) => {
                  onClose();
                  if (actionButton.props.onClick)
                    actionButton.props.onClick(...args);
                }
              })
            )}
        </div>
      )}
    </div>
  );
};

OptionFlyout.displayName = 'OptionFlyout';
OptionFlyout.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /**
   * Provide a string or an array of strings which should be displayed in the field.
   * If you provide an array multi select mode is enabled.
   */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  /**
   * An array of OptionFlyoutItem.
   */
  children: PropTypes.node.isRequired,
  /**
   * The onChange method returns a value or an array of values if multi select mode is enabled.
   * Call signature: (value) => {}
   */
  onChange: PropTypes.func,
  /** Called if an action button got clicked and the flyout should be closed. */
  onClose: PropTypes.func,
  /** Option Flyout with an clear action. */
  clearable: PropTypes.bool,
  /** Optional translation string for the clear label. */
  clearLabel: PropTypes.string,
  /** Optional content is of type `OptionFlyoutColorItem`. */
  color: PropTypes.bool,
  /** One or more action buttons of type FlyoutActionButton. */
  actionButtons: PropTypes.arrayOf(PropTypes.element),
  /** Show a search field in the flyout which allows to search the items. */
  searchable: PropTypes.bool,
  /** The placeholder text in the search field. */
  searchPlaceholder: PropTypes.string,
  /** Function called if the search field gets changed: Call signature: (searchTerm) => {} */
  onSearchTermChange: PropTypes.func,
  /** Custom search function which filters the options:
   * Call signature: (optionValue, optionLabel, searchTerm) => Boolean
   * This function gets called for each option and has to return
   * "true": If the option should be shown,
   * "false": If the option should be filtered.
   * By default the function in the description above is applied.
   */
  searchFunction: PropTypes.func,
  /** Set if elements width should grow like a block element. */
  block: PropTypes.bool
};

export default OptionFlyout;
