import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

/**
 *  The flyout action button
 */
const FlyoutActionButton = ({
  classNames,
  label,
  icon,
  onClick = noop,
  disabled = false
}) => (
  <button
    disabled={disabled}
    type="button"
    className={cn('hpl2-FlyoutActionButton', {}, classNames)}
    onClick={onClick}
  >
    {icon && <div className="hpl2-FlyoutActionButton__icon">{icon}</div>}
    <div className="hpl2-FlyoutActionButton__label">{label}</div>
  </button>
);

FlyoutActionButton.displayName = 'FlyoutActionButton';
FlyoutActionButton.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The label of the action button. */
  label: PropTypes.string.isRequired,
  /** An instance of Icon */
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  /** Call signature: (event) => {} */
  onClick: PropTypes.func
};

export default FlyoutActionButton;
