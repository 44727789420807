import React, { Component } from "react";
import { Row, Col, Avatar, Tabs } from "antd";
import "./settings.scss";
import ReturnButton from "./ReturnButton/ReturnButton";
import Roles from "./Roles";
import NeedAccessContainer from "../NeedAccessButton/needAccessButton.container.js";
import FeatureToggle from "./FeatureToggle/index.js"
import Table from "../Table"
import AppStatistics from "./AppStatistics/index.js"
import AppStatus from "./AppStatus/index.js"
import { I18nProvider } from '../../i18n';
import translate from '../../i18n/translate';
import { getUserLangPreference } from "../../i18n/utils";
import PipelineTimeline from "./PipelineTimeline";
import { isUserAdmin } from "../../Utils/Utils";
const { TabPane } = Tabs;
let needAccessFeature = false;
let needStatisticsFeature = false;

class SettingsComponent extends Component {
  constructor() {
    super();
    this.state = {
      locale: '',
      activeKey: "1",
    };
    this.reset = React.createRef();
    this.onEventCapture();
    this.changeTab = this.changeTab.bind(this);
  }

  onEventCapture = () => {
    document.addEventListener('custom_event', event => {
      console.log(event.detail);
      this.setState({ locale: event.detail.locale });
    });
  }

  changeTab(key) {
    this.setState({ activeKey: key })

  }

  componentWillMount() {
    let feature = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).feature ? JSON.parse(localStorage.getItem("user")).feature : [] : [];
    let accessFeature = feature.filter((item) => {
      return Object.keys(item).some((key) => item.feature === 'NEED_ACCESS_FOR_APPLICATION');
    });
    let statisticsFeature = feature.filter((item) => {
      return Object.keys(item).some((key) => item.feature === 'APP_STATISTICS');
    });
    needAccessFeature = accessFeature[0] ? accessFeature[0].isenabled : false;
    needStatisticsFeature = statisticsFeature[0] ? statisticsFeature[0].isenabled : false;
  }



  getColumns() {
    const columns = [
      {
        title: translate('Settings.ClientApiName'),
        dataIndex: 'appName',
        align: 'left',
        render: (text, item) => {
          return (
            <div className="app-name">
              <div> {item.logo ? <img
                alt="Logo"
                style={{ height: "32px" }}
                src={require(`../../domain/accessWindow/icons/${item.logo}`)}
              /> : item.appName}
              </div>
              <div className="app-text">
                {item.appType ? item.appType : ""}
              </div>


            </div>
          )
        }


      },
      {
        title: translate('Settings.LatestCodeChange'),
        align: 'left',
        render: (text, item) => (
          <PipelineTimeline projectName={item.projectName} pipelineId={item.pipelineId} stageName={item.buildStage}></PipelineTimeline>
        )
      },
      {
        title: 'Latest Deployment Time',
        align: 'left',
        render: (text, item) => (
          <PipelineTimeline projectName={item.projectName} pipelineId={item.pipelineId} stageName={item[process.env.REACT_APP_PIPELINE_STAGE]}></PipelineTimeline>
        )
      },
      // {
      //   title: 'Latest Deployed Time',
      //   align: 'left',
      //   render: (text, item) => (
      //     <LastDeployedTime projectName={item.projectName} releaseId={item.releaseId}></LastDeployedTime>
      //   )
      // }
    ];
    return columns;
  }
  getDataSorce() {
    const dataSource = [
      {
        appName: 'Platform Client',
        projectName: 'DSC - Data Science Platform',
        pipelineId: 1162,
        pipelineName: "dsc-platform-client-ci&cd",
        devStage: "Deploy to DEV environment",
        prodStage: "Deploy to PROD environment",
        buildStage: "Dev Build pipeline"
      },
      {
        appName: 'Platform API',
        projectName: 'DSC - Data Science Platform',
        pipelineId: 1164,
        pipelineName: "dsc-platform-api-ci&cd",
        devStage: "Dev deployment",
        prodStage: "prod deployment",
        buildStage: "Build stage"
      },
      {
        appName: 'SSBI Manager',
        projectName: 'DSC - Self Service BI',
        pipelineName: "dsc-ssbi-client-ci&cd",
        appType: "Client",
        pipelineId: 1145,
        logo: "SSBI Manager.svg",
        devStage: "Deploy to DEV environment",
        prodStage: "Deploy to Prod environment",
        buildStage: "Build pipeline"
      },
      {
        appName: 'SSBI Manager API',
        projectName: 'DSC - Self Service BI',
        pipelineName: "dsc-ssbi-api-ci&cd",
        appType: "API",
        pipelineId: 1146,
        logo: "SSBI Manager.svg",
        devStage: "Dev deployment",
        prodStage: "prod deployment",
        buildStage: "Dev Build stage"
      },
      {
        appName: 'Price  Responsive Curves',
        projectName: 'DSC - Data Science Platform',
        appType: "Client & API",
        pipelineName: "dsc-price-response-ci&cd",
        pipelineId: 1143,
        logo: "Price Response Curves.svg",
        devStage: "Dev deployment",
        prodStage: "prod deployment",
        buildStage: "Dev Build stage"
      },
      {
        appName: 'Content Automation',
        projectName: 'DSC - Data Science Platform',
        appType: "Client",
        pipelineName: "dsc-cat-client-ci&cd",
        pipelineId: 1132,
        logo: "Content Automation.svg",
        devStage: "Dev deployment",
        prodStage: "Prod deployment",
        buildStage: "Build stage"
      },
      {
        appName: 'Content Automation API',
        projectName: 'DSC - Data Science Platform',
        appType: "API",
        pipelineName: "dsc-cat-api-ci&cd",
        pipelineId: 1134,
        logo: "Content Automation.svg",
        devStage: "Dev deployment",
        prodStage: "prod deployment",
        buildStage: "Build stage"
      }

    ];
    return dataSource;
  }
  render() {
    const { name, navigateToApps } = this.props;
    const { activeKey } = this.state
    return (
      <div className="platform-container">
        <I18nProvider locale={getUserLangPreference()}>
          <Row>
            <div className="flex-layout">
              <Col flex="auto">
                <ReturnButton
                  headerOne=""
                  headerTwo={translate('Settings.Settings')}
                  path="/home"
                  onClick={navigateToApps}
                />
              </Col>
              <Col justify="end">
                <Avatar className="avatar" shape="square" size={60}>
                  <span className="cap-letter">{name[0]}</span>
                </Avatar>
                <a className="settings-profile-name"> {name} </a>
              </Col>
            </div>
          </Row>
          <Row className="tab-container">
            {needAccessFeature ?
              <NeedAccessContainer history={this.props.history} />
              : ""
            }
            <Tabs className="tab-label-container" activeKey={activeKey} onChange={this.changeTab}>
              <TabPane
                key={1}
                tab={<span className="tab-label role-label">{translate('Settings.Roles')}</span>}
              >
                {activeKey === "1" && <Roles history={this.props.history} />}
              </TabPane>
              {isUserAdmin(["settings_admin"]) ?
                <TabPane
                  key={2}
                  tab={
                    <span className="tab-label devops-label">{translate('Settings.DevOpsExt')}</span>
                  }
                >
                  {activeKey === "2" && <Table columns={this.getColumns()} data={this.getDataSorce()} scroll={{ y: 'calc(100vh - 500px)' }}/>}

                </TabPane> : ""}
              {isUserAdmin(["settings_admin"]) ?
                <TabPane
                  key={3}
                  tab={
                    <span className="tab-label feature-label">{translate('Settings.FeatureToggle')}</span>
                  }
                >
                  {activeKey === "3" && <FeatureToggle />}
                </TabPane> : ""}
              {isUserAdmin(["settings_admin"]) && needStatisticsFeature ?
                <TabPane
                  key={4}
                  tab={
                    <span className="tab-label feature-label">{translate('Settings.AppStatistics')}</span>
                  }
                >
                  {activeKey === "4" && <AppStatistics history={this.props.history} />}
                </TabPane>
                :
                ""}
              {isUserAdmin(["settings_admin"]) ?
                <TabPane
                  key={5}
                  tab={
                    <span className="tab-label feature-label">{translate('Settings.AppStatus')}</span>
                  }
                >
                  {activeKey === "5" && <AppStatus />}
                </TabPane> : ""}
            </Tabs>
          </Row>
        </I18nProvider>
      </div>
    );
  }
}
export default SettingsComponent;
