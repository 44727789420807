import React, { useState } from "react";
import { Select, Row, Col } from "antd";
import "./tabelCell.scss";
import { CheckOutlined } from "@ant-design/icons";
const { Option } = Select;
const constantRoles = ["User", "Admin", "Approver"];

const getRoleValue = (roleName) => {
  const roles = constantRoles.filter((role) => {
    const lower_case_label = role.toLowerCase();
    if (roleName.endsWith(lower_case_label)) {
      return <span className="tbl-options">{role}</span>;
    } else {
      return "";
    }
  });
  return roles[0];
};
export const ModifyRole = (props) => {
  const [roleChanged, setRoleChange] = useState(false);
  const { roles, userData, roleChange } = props;
  return (
    <Row style={{ display: "inline-flex" }}>
      <Col>
        <Select
          style={{ width: "100%" }}
          defaultValue={userData.role_name}
          bordered={false}
          onSelect={(value) => {
            setRoleChange(true);
            roleChange(value, userData);
          }}
        >
          {roles.map((role) => {
            return (
              <Option className="tbl-options" value={role.role_name}>
                {getRoleValue(role.role_name)}
              </Option>
            );
          })}
        </Select>
      </Col>
      <Col>
        <CheckOutlined
          style={{
            marginTop: "9px",
            color: "#40cc33",
            display: roleChanged ? "block" : "none",
          }}
        />
      </Col>
    </Row>
  );
};
const SelectRole = (props) => {
  const { roles, requestedRole, roleChange, isDisabled } = props;
  return (
    <Row style={{ display: "inline-flex" }}>
      <Select
        style={{ width: "100%" }}
        defaultValue={[requestedRole]}
        bordered={false}
        onSelect={roleChange}
        disabled={isDisabled}
      >
        {roles.map((role) => {
          return (
            <Option className="tbl-options" value={role.role_name}>
              {getRoleValue(role.role_name)}
            </Option>
          );
        })}
      </Select>
    </Row>
  );
};

export default SelectRole;
