import React from "react";
import LoginForm from "./loginForm.component.js";
import { doAuthRedirect } from "../../domain/providers/AuthProvider";
import { setUserLangPref } from "../../Utils/Utils.js";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: false,
      loggedIn: false,
      language: "en",
    };
    this.azureAD = this.azureAD.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setLoginError = this.setLoginError.bind(this);
  }

  setLoginError(flag) {
    this.setState({ loginError: flag });
  }

  azureAD(event) {
    event.preventDefault();
    doAuthRedirect(this.props);
  }

  setLanguage(event) {
    event.preventDefault();
    setUserLangPref(event.target.lang);
    this.setState({ language: event.target.lang });
    this.props.langPref(event.target.lang);
  }

  render() {
    return (<LoginForm
      loginError={this.state.loginError}
      history={this.props.history}
      azureAD={this.azureAD}
      language={this.setLanguage}
    ></LoginForm>);
  }
}
export default LoginContainer;
