import React, { Component } from "react";
import { Input, Row, Col } from "antd";
import Table from "../../Table"
import { SearchOutlined } from "@ant-design/icons";
import ReportsHeader from "../ReportIcon/ReportsHeader.svg";
import ReportsIconTable from "../ReportIcon/ReportsIconTable.svg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import "./styles.scss";

class ReportsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            reportsData: [],
            loading: true,
            filterReportsData: null,
        }
        this.onSearchChange = this.onSearchChange.bind(this);
    }
    onSearchChange(event) {
        this.setState({ searchText: event.target.value }, () => {
            const filterReportsData = this.state.reportsData.filter((o) =>
              Object.keys(o).some((k) =>
                String(o[k])
                  .toLowerCase()
                  .includes(this.state.searchText.toLowerCase())
              )
            );
            if (this.state.searchText)
              this.setState({ filterReportsData });
            else
              this.setState({ filterReportsData: null })
          });
        }
    componentDidMount() {
        let user = localStorage.getItem("user");
        if (!user) return;
        user = JSON.parse(user);
        if (!user.user_info || !user.user_info.email) return;
        let data = {};
        data["ad-groups"] = "*";
        const options = {
            headers: { "Content-Type": "application/json" },
        };
        axios
            .post(
                `ssbi-api/v1/user-management?email=${user.user_info.email}`,
                data,
                options
            )
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ reportsData: response.data, loading: false })

                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false })
            });

    }

    getColumns() {
        const columns = [
            {
                title:
                    <div className="report-header-first">
                        <img alt="Reports Header" src={ReportsHeader}></img>
                    </div>,
                key: "reportid",
                align: "center",
                dataIndex: "reportid",
                width: 85,
                render: (reportid) => (
                    <img src={ReportsIconTable} alt={reportid} style={{ cursor: "pointer"}}></img>
                ),

            },
            {
                title: "Report",
                key: "Report",
                align: "left",
                dataIndex: "reportname",
                render: (reportname, report) => (
                    <NavLink to={`reports/${report.reportid}`}>
                        <a className="report-name-link">
                            {reportname}
                        </a>
                    </NavLink>
                ),
            },
            {
                title: "Workspace",
                key: "Workspace",
                align: "left",
                dataIndex: "workspace",
                render: (workspace) => (
                    <React.Fragment>
                        <div style={{ display: "flex" }}>
                            <p style={{ textAlign: "left" }}>
                                {workspace}
                            </p>
                        </div>
                    </React.Fragment>
                )
            },

        ];
        return columns;
    }

    render() {
        const { reportsData, filterReportsData } = this.state
        return (
            <React.Fragment>
                <Row>
                    <Col flex="auto">
                        <div className="heading-container">
                            <h2 className="heading-reports">Reports</h2>
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} className="search-container">
                    <Col flex="auto">
                        <Input
                            placeholder="Search"
                            className="input-search"
                            prefix={<SearchOutlined />}
                            onChange={this.onSearchChange}
                        />
                    </Col>
                </Row>
                <Row className="table-container">
                        <Table
                            columns={this.getColumns()}
                            data={filterReportsData ? filterReportsData : reportsData}
                            loading={this.state.loading}
                        />
                </Row>
            </React.Fragment>

        )
    }
};
export default ReportsTable;