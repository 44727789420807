import React, { useState } from "react";
import { InputField } from "@hoffmann/pattern-library-2";
import validator from "validator";
import { Button } from "antd";
import { Icon, icons } from "@hoffmann/pattern-library-2";
import { NavLink } from "react-router-dom";
import axios from "axios";

const RequestAccess = (props) => {
  const [requestAccessEmail, setRequestAccessEmail] = useState("");
  const [isEmailError, setEmailError] = useState(false);

  const userEmailOnChange = (value) => {
    setRequestAccessEmail(value);
  };
  const handleAccessSelection = () => {
    if (!validator.isEmail(requestAccessEmail)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      const options = {
        headers: { "Content-Type": "application/json" },
      };
      axios
        .post(
          "/platform/user-exist",
          { user_email: requestAccessEmail },
          options
        )
        .then((res) => {
          if (res.data.length >= 1) {
            props.history.replace({
              pathname: "/request-access",
              state: { email: requestAccessEmail },
            });
          } else {
            setEmailError(true);
          }
        })
        .catch((err) => {
          setEmailError(true);
        });
    }
  };
  return (
    <div
      className="page"
      style={{ height: "520px", width: "100%", position: "relative" }}
    >
      <div className="header">
        <h className="login-heading">Request Access</h>
        <h2 className="sub-heading">Enter your Email ID below</h2>
      </div>
      <div className="username">
        <InputField
          className="demo-fix"
          label={("username", "User Email")}
          placeholder={"Enter user email"}
          type={("type", ["text"])}
          id={"id"}
          error={("error", "")}
          disabled={("disabled", false)}
          value={requestAccessEmail}
          onChange={(e) => {
            userEmailOnChange(e);
          }}
        />
      </div>
      {isEmailError ? (
        <div className="login-error">
          <Icon source={icons.Warning} classNames={["warning-icon"]} />
          <p className="error-text">Not a registered Hoffmann user</p>
        </div>
      ) : (
        ""
      )}
      <div className="login-button-holder">
        <Button
          onClick={handleAccessSelection}
          label="Select Applications"
          className="request-button"
        >
          <span className="request-button-label">SELECT APPLICATIONS</span>
        </Button>
      </div>
      <div className="forgetter">
        <NavLink to="/">
          <span className="back-login">Back to login screen</span>
        </NavLink>
      </div>
    </div>
  );
};

export default RequestAccess;
