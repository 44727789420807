import React, { Component } from "react";
import AppButton from "../AppButton";
import IconData from "../AppButton/iconsData";
import AuthorizedElement from "./../../Utils/AuthorizedElement";
import { collectLogs, pushCustomClickEvents } from "../../services/ga4/ga4.events";

export default class MainApps extends Component {
  state = {
    ssbi: {
      textColor: IconData.ssbi.textonOut,
      imgSrc: IconData.ssbi.imageOnOut,
      active: IconData.ssbi.active,
    },
    price_response: {
      textColor: IconData.price_response.textonOut,
      imgSrc: IconData.price_response.imageOnOut,
      active: IconData.price_response.active,
    },
    content_automation: {
      textColor: IconData.content_automation.textonOut,
      imgSrc: IconData.content_automation.imageOnOut,
      active: IconData.content_automation.active,
    },
    market_list: {
      textColor: IconData.market_list.textonOut,
      imgSrc: IconData.market_list.imageOnOut,
      active: IconData.market_list.active,
    },
    kam: {
      textColor: IconData.kam.textonOut,
      imgSrc: IconData.kam.imageOnOut,
      active: IconData.kam.active,
    },
    pm: {
      textColor: IconData.pm.textonOut,
      imgSrc: IconData.pm.imageOnOut,
      active: IconData.pm.active,
    },
    pin: {
      textColor: IconData.pm.textonOut,
      imgSrc: IconData.pin.imageOnOut,
      active: IconData.pin.active,
    },
  };


  handleClick(appName) {
    pushCustomClickEvents(appName);
    collectLogs(appName, JSON.parse(localStorage.getItem("user")).user_info.email, "App Click", "App Clicked successfully");
  };

  showHoverImage = (key, flag) => {
    const button = { ...this.state[key] };
    if (flag) {
      button.textColor = IconData[key].textOnHover;
      button.imgSrc = IconData[key].imageOnHover;
    } else {
      button.textColor = IconData[key].textonOut;
      button.imgSrc = IconData[key].imageOnOut;
    }
    this.setState({ [key]: button });
  };

  render() {
    return (
      <React.Fragment>
        <h2 className="heading">Applications </h2>
        <div className="app-icon-container">
          <AuthorizedElement
            roles={["ssbi_manager_user", "ssbi_manager_admin"]}
          >
            <AppButton
              Key="ssbi"
              appName="SSBI Manager"
              imgSrc={this.state.ssbi.imgSrc}
              navPath="/ssbi-manager"
              imgAltName="SSBI Manager Dashboard"
              textColor={this.state.ssbi.textColor}
              onClick={() => { this.handleClick("SSBI Manager") }}
              onButtonMouseOver={() => {
                this.showHoverImage("ssbi", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("ssbi", false);
              }}
              active={this.state.ssbi.active}
            ></AppButton>
          </AuthorizedElement>
          <AuthorizedElement
            roles={["price_response_user", "price_response_admin"]}
          >
            <AppButton
              Key="price_response"
              appName="Price Response"
              imgSrc={this.state.price_response.imgSrc}
              navPath="/price-response"
              imgAltName="Price Response Curve"
              textColor={this.state.price_response.textColor}
              onClick={() => { this.handleClick("Price Response") }}
              onButtonMouseOver={() => {
                this.showHoverImage("price_response", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("price_response", false);
              }}
              active={this.state.price_response.active}
            ></AppButton>
          </AuthorizedElement>
          <AuthorizedElement
            roles={["content_automation_admin", "content_automation_user"]}
          >
            <AppButton
              Key="content_automation"
              appName="Content Automation"
              imgSrc={this.state.content_automation.imgSrc}
              navPath="/content-automation"
              imgAltName="Content Automation"
              textColor={this.state.content_automation.textColor}
              onClick={() => { this.handleClick("Content Automation") }}
              onButtonMouseOver={() => {
                this.showHoverImage("content_automation", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("content_automation", false);
              }}
              active={this.state.content_automation.active}
            ></AppButton>
          </AuthorizedElement>
          <AuthorizedElement
            roles={["market_list_price_user", "market_list_price_admin"]}
          >
            <AppButton
              Key="market_list"
              appName="Market List Price"
              imgSrc={this.state.market_list.imgSrc}
              navPath="/market-list-prices"
              imgAltName="Market List Price"
              textColor={this.state.market_list.textColor}
              onClick={() => { this.handleClick("Market List Price") }}
              onButtonMouseOver={() => {
                this.showHoverImage("market_list", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("market_list", false);
              }}
              active={this.state.market_list.active}
            ></AppButton>
          </AuthorizedElement>
          <AuthorizedElement
            roles={["kam_price_optimizer_user", "kam_price_optimizer_admin"]} >
            <AppButton
              Key="kam"
              appName="KAM Price Optimizer"
              imgSrc={this.state.kam.imgSrc}
              navPath="/home"
              imgAltName="KAM Price Optimizer"
              textColor={this.state.kam.textColor}
              // onClick={()=>{this.handleClick("KAM Price Optimizer")}}
              onButtonMouseOver={() => {
                this.showHoverImage("kam", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("kam", false);
              }}
              active={this.state.kam.active}
            ></AppButton>
          </AuthorizedElement>
          <AuthorizedElement
            roles={["pm_price_optimizer_user", "pm_price_optimizer_admin"]}
          >
            <AppButton
              Key="pm"
              appName="PM Price Optimizer"
              imgSrc={this.state.pm.imgSrc}
              navPath="/pm-price-optimizer"
              imgAltName="PM Price Optimizer"
              textColor={this.state.pm.textColor}
              onClick={() => { this.handleClick("PM Price Optimizer") }}
              onButtonMouseOver={() => {
                this.showHoverImage("pm", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("pm", false);
              }}
              active={this.state.pm.active}
            ></AppButton>
          </AuthorizedElement>
          <AuthorizedElement
            roles={["pricing_international_user", "pricing_international_admin", "pricing_international_approver"]} >
          <AppButton
              Key="pin"
              appName="Pricing International"
              imgSrc={this.state.pin.imgSrc}
              navPath="/pricing-international"
              imgAltName="Pricing International"
              textColor={this.state.pin.textColor}
              onClick={() => { this.handleClick("Pricing International") }}
              onButtonMouseOver={() => {
                this.showHoverImage("pin", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("pin", false);
              }}
              active={this.state.pin.active}
            ></AppButton>
            </AuthorizedElement>
        </div>
      </React.Fragment>
    );
  }
}
