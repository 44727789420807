import React from "react";
import NeedAccessButton from "./needAccessButton.component";

class NeedAccessContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
    };
    this.getAccess = this.getAccess.bind(this);
  }

  getAccess = () => {
    let userEmail = JSON.parse(localStorage.getItem("user")).user_info.email;
    this.props.history.push({
      pathname: "/request-access",
      state: { email: userEmail },
    });
  };

  render() {
    return (
      <NeedAccessButton
        getAccess={this.getAccess}>
      </NeedAccessButton>);
  };
};
export default NeedAccessContainer;