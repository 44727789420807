import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import cn from '../../lib/class-name';

/**
 * A radio input item to be used in a RadioGroupField.
 *
 * A ref created with "React.createRef()" can be passed to the component. It makes the inputs "focus()" function accessible.
 */
class RadioGroupItem extends React.Component {
  static displayName = 'RadioGroupItem';

  static propTypes = {
    /** Optional array of CSS utility classes. */
    classNames: PropTypes.arrayOf(PropTypes.string),
    /** Label string */
    label: PropTypes.string,
    /** Element name */
    name: PropTypes.string,
    /** Checked state */
    checked: PropTypes.bool,
    /** Value name */
    value: PropTypes.string,
    /** Call signature: (event) => {} */
    onClick: PropTypes.func,
    /** Call signature: (event) => {} */
    onBlur: PropTypes.func,
    /** Disabled state modifier */
    disabled: PropTypes.bool,
    /** If no id is provided an id is generated automatically. */
    id: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    value: '',
    onBlur: noop
  };

  focusRef = React.createRef();

  inputId = uniqueId('radio-');

  focus = () => {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  };

  render() {
    const {
      classNames,
      id = this.inputId,
      name,
      label,
      checked,
      onClick,
      disabled,
      value,
      onBlur
    } = this.props;

    return (
      <label
        htmlFor={id}
        role="radio"
        className={cn('hpl2-RadioGroupItem', { checked }, classNames)}
        aria-checked={checked}
        aria-disabled={disabled}
      >
        <input
          id={id}
          onBlur={onBlur}
          className="hpl2-RadioGroupItem__input"
          type="radio"
          name={name}
          value={value}
          onChange={onClick}
          checked={checked}
          disabled={disabled}
          ref={this.focusRef}
        />
        {label && <div className="hpl2-RadioGroupItem__label">{label}</div>}
      </label>
    );
  }
}

export default RadioGroupItem;
