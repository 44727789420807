import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * A list of navigation items
 */
const NavigationList = ({ classNames, children }) => (
  <ul className={cn('hpl2-NavigationList', {}, classNames)}>
    {React.Children.map(children, child => (
      <li className="hpl2-NavigationList__item">{child}</li>
    ))}
  </ul>
);

NavigationList.displayName = 'NavigationList';
NavigationList.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Navigation items as children. */
  children: PropTypes.node.isRequired
};

export default NavigationList;
