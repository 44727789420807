export default {
  ssbi_manager_user: "SSBI Manager",
  ssbi_manager_admin: "SSBI Manager",
  price_response_user: "Price Response Curves",
  price_response_admin: "Price Response Curves",
  content_automation_user: "Content Automation",
  content_automation_admin: "Content Automation",
  market_list_price_user: "Market List Prices",
  market_list_price_admin: "Market List Prices",
  pm_price_optimizer_user: "PM Price Optimizer",
  pm_price_optimizer_admin: "PM Price Optimizer",
  pricing_international_user: "Pricing International",
  pricing_international_admin: "Pricing International",
  others_user: "Others",
};
