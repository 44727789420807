import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';
import InputMask from 'react-input-mask';

import * as icons from '../../asset/icon';
import cn from '../../lib/class-name';

import Icon from '../Icon';
import FieldActionButton from './FieldActionButton';
import Tooltip from '../Tooltip';

const sanitizeNumberInput = value =>
  value.replace(/[^\d]/g, '').replace(/^0+(.)/, '$1');

/**
 * General input field with included label.
 *
 * A ref created with "React.createRef()" can be passed to the component. It makes the inputs "focus()" function accessible.
 *
 * ### Input Masking
 *
 * This field has support for input masking with ([react-input-mask](https://github.com/sanniassin/react-input-mask)).
 * Default format characters for the `mask` property are:
 *
 * - __9__: 0-9
 * - __a__: A-Z, a-z
 * - __*__: A-Z, a-z, 0-9
 *
 * Any character can be escaped with a backslash.
 * It will appear as a double backslash in JS strings.
 * For example, a German phone mask with unremoveable prefix
 * +49 will look like `+4\9 99 999 99`
 *
 * Input masking does not replace input validation. It is still necessary to validate the input value.
 * With input masking it may also contain `_` symbols when the user has not completely entered all required characters.
 */
class InputField extends React.Component {
  static displayName = 'InputField';

  static propTypes = {
    /** Optional array of CSS utility classes. */
    classNames: PropTypes.arrayOf(PropTypes.string),
    /** Label of the input. */
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    /** Call signature: (value, event) => {} */
    onChange: PropTypes.func,
    /** Call signature: (event) => {} */
    onBlur: PropTypes.func,
    /**
     * If the type is "password" a toggle icon is shown instead of a custom icon (or the clear button)
     * to be able to toggle the visibility of the input value.
     * Type number only supports non-negative integers.
     */
    type: PropTypes.oneOf(['text', 'password', 'number']),
    /** Id to set on the HTML input field. */
    id: PropTypes.string,
    /** Signal validation error. If a string is given then a tooltip is shown while hovering the error icon. */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    /**
     * The input field can be customized with a custom icon.
     */
    icon: PropTypes.element,
    /**
     * Optional instance of FieldActionButton to add a custom action to the field.
     * If a customAction is passed the default behaviour of the InputField gets overwritten.
     * Not compatibel to number and password inputs!
     */
    customAction: PropTypes.element,
    /**
     * Input mask as described above.
     */
    mask: PropTypes.string,
    /** Restrict input value to a maximum length. */
    maxLength: PropTypes.number
  };

  static defaultProps = {
    type: 'text',
    value: ''
  };

  state = {
    passwordVisible: false
  };

  defaultId = uniqueId('hpl2-InputField-');

  inputRef = React.createRef();

  setPasswordVisible(passwordVisible) {
    this.setState({
      passwordVisible
    });
  }

  focus = () => {
    if (!this.props.mask && this.inputRef.current) {
      this.inputRef.current.focus();
    } else if (this.props.mask) {
      this.inputRef.focus();
    }
  };

  setCursorPosition = pos => {
    if (!this.props.mask && this.inputRef.current) {
      this.inputRef.current.selectionStart = pos;
      this.inputRef.current.selectionEnd = pos;
    } else if (this.props.mask) {
      this.inputRef.selectionStart = pos;
      this.inputRef.selectionEnd = pos;
    }
  };

  render() {
    const {
      classNames,
      value,
      onChange = noop,
      onBlur = noop,
      type,
      disabled,
      error,
      placeholder,
      icon,
      customAction,
      mask,
      maxLength,
      label,
      id = this.defaultId,
      ...rest
    } = this.props;

    const { passwordVisible } = this.state;

    const isPassword = type === 'password';
    const isNumber = type === 'number';
    const isEmpty = value.length === 0;
    let inputType = 'text';
    if (isPassword && !passwordVisible) {
      inputType = 'password';
    }

    const triggerChange = (nextValue, event) => {
      // Enforce max length (necessary because e.g. type number manipulates value without directly using the HTML input)
      onChange(maxLength ? nextValue.slice(0, maxLength) : nextValue, event);
    };
    const handleChange = event => {
      const val = event.currentTarget.value;
      triggerChange(isNumber ? sanitizeNumberInput(val) : val, event);
    };
    const clearClick = event => triggerChange('', event);
    const togglePasswordVisibility = () =>
      this.setPasswordVisible(!passwordVisible);
    const incrementNumber = event => {
      const nextValue = String(Number.parseInt(value, 10) + 1);
      triggerChange(nextValue === 'NaN' ? '0' : nextValue, event);
    };
    const decrementNumber = event => {
      const nextValue = String(Math.max(0, Number.parseInt(value, 10) - 1));
      triggerChange(nextValue === 'NaN' ? '0' : nextValue, event);
    };

    const renderAction = () => {
      if (isNumber) {
        if (disabled) {
          return null;
        }

        return (
          <div className="hpl2-InputField__numberButtons">
            <button
              className="hpl2-InputField__numberIncrement"
              type="button"
              tabIndex="-1"
              disabled={
                maxLength && value.length === maxLength && /^9+$/.test(value)
              }
              onClick={incrementNumber}
            >
              <Icon source={icons.ArrowDropUp} />
            </button>
            <button
              className="hpl2-InputField__numberDecrement"
              type="button"
              tabIndex="-1"
              disabled={value === '0'}
              onClick={decrementNumber}
            >
              <Icon source={icons.ArrowDropDown} />
            </button>
          </div>
        );
      }

      if (isPassword) {
        if (disabled || isEmpty) {
          return null;
        }

        return (
          <FieldActionButton
            onClick={togglePasswordVisibility}
            icon={
              <Icon
                source={passwordVisible ? icons.VisiblityOff : icons.Visibility}
              />
            }
          />
        );
      }

      if (customAction) {
        return React.cloneElement(customAction, {
          disabled: isEmpty || disabled
        });
      }

      return null;
    };

    const action = renderAction();
    const hasClear =
      !action && !isNumber && !isPassword && !isEmpty && !disabled;

    return (
      <div
        className={cn(
          'hpl2-InputField',
          {
            icon: Boolean(icon),
            action: Boolean(action),
            number: isNumber,
            hasValue: !isEmpty,
            hasClear,
            error
          },
          classNames
        )}
        // To keep field focused when interacting with child elements!
        onMouseDown={() => {
          this.focus();
        }}
        onTouchStart={() => {
          this.focus();
        }}
      >
        {React.createElement(mask ? InputMask : 'input', {
          ...rest,
          className: 'hpl2-InputField__input',
          type: inputType,
          ref: !mask ? this.inputRef : undefined,
          onChange: handleChange,
          onBlur,
          id,
          value,
          disabled,
          placeholder,
          maxLength,
          ...(mask
            ? {
                mask,
                maskChar: '_',
                inputRef: ref => {
                  this.inputRef = ref;
                }
              }
            : {})
        })}
        <div className="hpl2-InputField__box" aria-hidden />
        <label htmlFor={id} className="hpl2-InputField__label">
          {label}
        </label>
        {error && (
          <div className="hpl2-InputField__error">
            {error === true && <Icon source={icons.Info} />}
            {error !== true && (
              <Tooltip preferredPosition="top" content={error}>
                <Icon source={icons.Info} />
              </Tooltip>
            )}
          </div>
        )}
        {action}
        {hasClear && (
          <div className="hpl2-InputField__clear">
            <FieldActionButton
              onClick={clearClick}
              icon={<Icon source={icons.Cancel} />}
            />
          </div>
        )}
        {icon}
      </div>
    );
  }
}

export default InputField;
