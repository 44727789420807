import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * A 2-column Footer.
 */
const Footer = ({ classNames, left, right }) => (
  <div className={cn('hpl2-Footer', {}, classNames)}>
    <div className="hpl2-Footer__column">{left}</div>
    <div className="hpl2-Footer__column">{right}</div>
  </div>
);

Footer.displayName = 'Footer';
Footer.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Left column content. */
  left: PropTypes.node,
  /** Right column content. */
  right: PropTypes.node
};

export default Footer;
