import React from "react";
import "@hoffmann/pattern-library-2/core.css";
import { Icon, icons } from "@hoffmann/pattern-library-2";
import "./login-form.scss";
import MSlogo from "./logo/Microsoft_logo.svg";
import { I18nProvider, LOCALES } from '../../i18n';
import translate from '../../i18n/translate';
import { getUserLangPreference } from "../../i18n/utils";


const LoginForm = (props) => {
  const {
    loginError,
    azureAD,
    language
  } = props;

  return (
    <I18nProvider locale={getUserLangPreference()}>
      <div className="page" style={{ width: "100%", position: "relative" }}>
        <div className="header">
          <h className="login-heading">{translate('Login.Welcome')}</h>
        </div>
        {
          loginError ? (
            <div className="login-error">
              <Icon source={icons.Warning} classNames={["warning-icon"]} />
              <p className="error-text">Incorrect username or password</p>
            </div>
          ) : ("")
        }
        <div className="login-button-holder">
          <button onClick={azureAD} className="login-button-ms" style={{ marginLeft: 15 }}>
            <img className="ms-logo" alt="Mslogo" src={MSlogo} />
            {translate('Login.ButtonText')}
          </button>
        </div>
        <div className="login-button-holder">
          <div className="lang-btn-container">
            <button lang={LOCALES.ENGLISH} className={[getUserLangPreference() === LOCALES.ENGLISH ? "lang-btn-active" : "lang-btn-inactive"]} onClick={language}>
              EN
              </button>
            <button lang={LOCALES.GERMAN} className={[getUserLangPreference() === LOCALES.GERMAN ? "lang-btn-active" : "lang-btn-inactive"]} onClick={language}>
              DE
              </button>
          </div>
        </div>
      </div>
    </I18nProvider>
  );
};

export default LoginForm;
