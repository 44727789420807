import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import * as icons from '../../asset/icon';
import cn from '../../lib/class-name';

import Icon from '../Icon';

/**
 * Suggestion item used in the SearchField.
 */
const SuggestionItem = ({
  classNames,
  children,
  value,
  annotation = '',
  onClick = noop,
  defaultItem = false
}) => (
  <button
    onClick={event => onClick(value, event)}
    type="button"
    className={cn('hpl2-SuggestionItem', { defaultItem }, classNames)}
  >
    <Icon block source={icons.Search} />
    <span className="hpl2-SuggestionItem__suggestion">{children}</span>
    {annotation && (
      <span className="hpl2-SuggestionItem__annotation">{annotation}</span>
    )}
  </button>
);

SuggestionItem.displayName = 'SuggestionItem';
SuggestionItem.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Call signature: (value, event) => {} */
  onClick: PropTypes.func,
  /** The search value submitted in the search. */
  value: PropTypes.string.isRequired,
  /** A suggestion string. */
  children: PropTypes.node,
  /** An optional string shown after the suggestion. */
  annotation: PropTypes.string,
  /** The item is the default search item. */
  defaultItem: PropTypes.bool
};

export default SuggestionItem;
