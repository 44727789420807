import React from 'react';
import PropTypes from 'prop-types';
import * as contrast from 'wcag-contrast';

import cn from '../../lib/class-name';

/**
 * A component which shows a color as a circle inside the `DropDownField`.
 */
const DropDownColor = ({ classNames, hex }) => (
  <span
    className={cn(
      'hpl2-DropDownColor',
      {
        border: contrast.hex(hex, '#ffffff') < 1.2
      },
      classNames
    )}
    style={{ backgroundColor: hex }}
  />
);

DropDownColor.displayName = 'DropDownColor';
DropDownColor.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The hex value of the color to show. */
  hex: PropTypes.string
};

export default DropDownColor;
