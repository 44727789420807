
export default function AuthorizedElement({ roles, children, checkPO }) {
  let user = localStorage.getItem("user");
  if (!user) return "";
  user = JSON.parse(user);
  const isAutherized = () => {
    if (checkPO) {
      if (
        user &&
        user.user_info &&
        user.user_info.realm_access &&
        user.user_info.realm_access.roles
      ) {
        var result = roles.some((r) => {
         return user.user_info.realm_access.roles.includes(r);
        });
        if (result) {
          return true;
        }
      }
      if (!result) {
        let productOwners = [];
        productOwners.push(process.env.REACT_APP_CAT_PO);
        productOwners.push(process.env.REACT_APP_PRC_PO);
        productOwners.push(process.env.REACT_APP_SSBI_PO);
        productOwners.push(process.env.REACT_APP_MLP_PO);
        productOwners.push(process.env.REACT_APP_PMPOT_PO);
        productOwners.push(process.env.REACT_APP_PIN_PO);
        return productOwners.includes(user.user_info.email);
      }
    }
    else if (
      user &&
      user.user_info &&
      user.user_info.realm_access &&
      user.user_info.realm_access.roles
    ) {
      return roles.some((r) => {
        return user.user_info.realm_access.roles.includes(r);
      });
    }
    return false;
  };

  return isAutherized() && children;
}
