import React from 'react';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import uniqueId from 'lodash/uniqueId';
import noop from 'lodash/noop';

import * as icons from '../../asset/icon';
import cn from '../../lib/class-name';

import PositioningPortalWithState from '../PositioningPortalWithState';
import Icon from '../Icon';
import DropDownColor from './DropDownColor';

/**
 * A dropdown field which allows single or multiple item selection.
 * The dropdown is in multiselect mode if the value provided is an array (even if its an empty array).
 *
 * A ref created with "React.createRef()" can be passed to the component. It makes the inputs "focus()" function accessible.
 */

class DropDownField extends React.Component {
  static displayName = 'DropDownField';

  static propTypes = {
    /** Optional array of CSS utility classes. */
    classNames: PropTypes.arrayOf(PropTypes.string),
    /** Label of the input. */
    label: PropTypes.string.isRequired,
    /**
     * The onChange method returns a value or an array of values if multi select mode is enabled.
     * Call signature: (value) => {}
     */
    onChange: PropTypes.func,
    /**
     * Call signature: () => {}
     */
    onBlur: PropTypes.func,
    /**
     * Provide a string or an array of strings which should be displayed in the field.
     * If you provide an array multi select mode is enabled.
     */
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string
    ]),
    /**
     * Provide an OptionFlyout as child.
     */
    children: PropTypes.element.isRequired,
    /** Id to set on the HTML input field. */
    id: PropTypes.string,
    /** Signal validation error. */
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    /** Set if flyout should have exact same width as the input field. */
    keepWidth: PropTypes.bool
  };

  static defaultProps = {
    value: '',
    placeholder: '',
    disabled: false,
    keepWidth: true,
    onChange: noop,
    onBlur: noop
  };

  inputRef = React.createRef();

  defaultId = uniqueId('hpl2-DropDownField-');

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  render() {
    const {
      classNames,
      onChange,
      onBlur,
      label,
      value,
      placeholder,
      disabled,
      children,
      error,
      keepWidth,
      id = this.defaultId,
      ...rest
    } = this.props;

    const child = React.Children.only(children);
    const hasColors = child.props.color;
    const flyoutItemLabels = React.Children.map(
      child.props.children,
      optionFlyoutItem =>
        hasColors
          ? {
              label:
                optionFlyoutItem.props.label ||
                `${optionFlyoutItem.props.code} (${
                  optionFlyoutItem.props.name
                })`,
              hex: optionFlyoutItem.props.hex,
              value: optionFlyoutItem.props.value
            }
          : {
              label:
                optionFlyoutItem.props.label || optionFlyoutItem.props.value,
              value: optionFlyoutItem.props.value
            }
    );
    const byValue = keyBy(flyoutItemLabels, 'value');

    const multiselect = Array.isArray(value);
    const hasValue =
      (multiselect ? value : [value]).filter(v => byValue[v]).length > 0;

    const formattedLabel = (multiselect ? value : [value])
      .filter(v => byValue[v])
      .map(v => byValue[v].label)
      .join(', ');

    const colorHexValue =
      hasColors && hasValue ? byValue[value].hex : undefined;

    return (
      <PositioningPortalWithState
        onClose={() => {
          onBlur();
        }}
        portalContent={({ close, relatedWidth }) => (
          <div
            className="hpl2-DropDownField__flyout"
            style={{ width: keepWidth ? `${relatedWidth}px` : undefined }}
          >
            {React.cloneElement(child, {
              value,
              block: keepWidth,
              onClose: () => close(),
              onChange: nextValue => {
                onChange(nextValue);
                if (!multiselect) {
                  close();
                }
              }
            })}
          </div>
        )}
      >
        {({ open, close, isOpen }) => (
          <div
            className={cn(
              'hpl2-DropDownField',
              {
                error,
                hasValue,
                disabled,
                isOpen,
                color: !!colorHexValue
              },
              classNames
            )}
          >
            <input id={id} type="hidden" value={value} disabled={disabled} />
            <button
              {...rest}
              ref={this.inputRef}
              type="button"
              onClick={isOpen ? close : open}
              disabled={disabled}
              className="hpl2-DropDownField__button"
              onBlur={() => {
                if (!isOpen) {
                  onBlur();
                }
              }}
            >
              {hasValue ? (
                formattedLabel
              ) : (
                <div className="hpl2-DropDownField__placeholder">
                  {placeholder}
                </div>
              )}
            </button>
            <div className="hpl2-DropDownField__box" aria-hidden />
            {colorHexValue && <DropDownColor hex={colorHexValue} />}
            <label htmlFor={id} className="hpl2-DropDownField__label">
              {label}
            </label>
            <div className="hpl2-DropDownField__icon">
              <Icon source={isOpen ? icons.ArrowDropUp : icons.ArrowDropDown} />
            </div>
          </div>
        )}
      </PositioningPortalWithState>
    );
  }
}

export default DropDownField;
