import React, { Children } from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * Use `TableRow` only within the table.
 */
const TableRow = ({
  classNames,
  active = false,
  separate = false,
  minor = false,
  children,
  computedWidths
}) => (
  <div
    className={cn(
      'hpl2-TableRow',
      {
        active,
        separate,
        minor
      },
      classNames
    )}
  >
    {Children.map(children, (child, i) =>
      React.cloneElement(child, { computedWidth: computedWidths[i] })
    )}
  </div>
);

TableRow.displayName = 'TableRow';
TableRow.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The `TableCell`s of the row. */
  children: PropTypes.node.isRequired,
  /** Active variant. */
  active: PropTypes.bool,
  /** Row width bottom line. */
  separate: PropTypes.bool,
  /** Minor row can be used to introduce an additional hierarchy within the table. */
  minor: PropTypes.bool,
  /** The computed widths for each cell (injected by parent). */
  computedWidths: PropTypes.arrayOf(PropTypes.string)
};

export default TableRow;
