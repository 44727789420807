import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * Card component to tease content. It can optionally function as a link.
 */
const Card = ({
  classNames,
  title,
  children,
  actions,
  href,
  target,
  onClick,
  imageSource,
  imageTop = true,
  topActions
}) => {
  const isLink = href || onClick;

  const handleClick = event => {
    if (onClick) {
      event.preventDefault();
      onClick(event, href);
    }
  };

  const renderLink = content =>
    React.createElement(
      isLink ? 'a' : 'div',
      {
        className: cn(
          'hpl2-Card',
          { imageTop: imageSource && imageTop, hasImage: !!imageSource },
          classNames
        ),
        href: href || '#',
        target,
        onClick: isLink ? handleClick : undefined
      },
      content
    );

  const renderImage = () => (
    <div
      className="hpl2-Card__image"
      style={{ backgroundImage: `url(${imageSource})` }}
    />
  );

  return renderLink(
    <>
      {imageSource && imageTop && renderImage()}
      <div className="hpl2-Card__header">
        <strong className="hpl2-Card__title">{title}</strong>
        {!isLink && topActions && (
          <span className="hpl2-Card__topActions">{topActions}</span>
        )}
      </div>
      {imageSource && !imageTop && renderImage()}
      <div className="hpl2-Card__content">{children}</div>
      {!isLink && actions && <div className="hpl2-Card__footer">{actions}</div>}
    </>
  );
};

Card.displayName = 'Card';
Card.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Title of the card. */
  title: PropTypes.string.isRequired,
  /** Arbitrary content. */
  children: PropTypes.node.isRequired,
  /** Button instances as major actions for this card. Only possible if card does not act as a link. */
  actions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  /** Usually an instance of CircularButton which opens a context menu. Only possible if card does not act as a link. */
  topActions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  /** Href of the card when it acts as a link. If href and/or onClick are set then the card acts as a link. */
  href: PropTypes.string,
  /** Optional link target. */
  target: PropTypes.string,
  /** If href and/or onClick are set then the card acts as a link. Call signature: (event, href) => {} */
  onClick: PropTypes.func,
  /** Optional image which should have a ratio of 5:3 otherwise it will be cropped. */
  imageSource: PropTypes.string,
  /** If true image is put above title otherwise underneath. */
  imageTop: PropTypes.bool
};

export default Card;
