import React, { Component } from "react";
import axios from "axios";

const options = {
    headers: { "Content-Type": "application/json" },
};
class PipelineTimeline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date: ""
        }
    }
    convertTime12to24(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }
    formatDateTime(finishTime) {
        const date = new Date(finishTime).toLocaleDateString("en-US", { timeZone: "CET" })
        const dateArr = date.split("/")
        let month = parseInt(dateArr[0]);
        let day = parseInt(dateArr[1]);
        const year = parseInt(dateArr[2]);
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        const time = this.convertTime12to24(new Date(finishTime).toLocaleTimeString("en-US", { timeZone: "CET" }))
        const timeArr = time.split(":")
        let hour = parseInt(timeArr[0]);
        let min = parseInt(timeArr[1]);
        if (hour < 10) {
            hour = "0" + hour;
        }
        if (min < 10) {
            min = "0" + min;
        }
        return `${day}.${month}.${year} ${hour}:${min} CET`

    }

    componentDidMount() {
        axios.get('/ops-api/project/' + this.props.projectName + '/pipeline/' + this.props.pipelineId + '/pipeline-details/' + this.props.stageName, options).then((response) => {
            if (response && response.status === 200) {
                let dateTime = this.formatDateTime(response.data.finishTime)
                this.setState({ date: dateTime })
            }
        });
    }
    render() {
        const { date } = this.state;

        return (

            <p>{date}</p>
        )

    }
}
export default PipelineTimeline;