import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * A modal component used for messages and questions.
 */
class Dialog extends React.Component {
  static displayName = 'Dialog';

  static propTypes = {
    /** Optional array of CSS utility classes. */
    classNames: PropTypes.arrayOf(PropTypes.string),
    /** Title of the dialog. */
    title: PropTypes.node.isRequired,
    /** Arbitrary content. */
    children: PropTypes.node.isRequired,
    /** Button instances as major actions for this dialog. */
    actions: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ]),
    /** The size of the modal. Defines its max width. */
    size: PropTypes.oneOf([100, 200]),
    /** Emphasized variant of the footer actions. */
    emphasizeActions: PropTypes.bool,
    /** Additional action which is shown besides the title. */
    headerAction: PropTypes.node,
    /** Additional header content which is shown under the title. */
    headerContent: PropTypes.node
  };

  static defaultProps = {
    size: 100,
    emphasizeActions: false
  };

  state = {
    hasScroll: false
  };

  contentRef = React.createRef();

  componentDidMount() {
    global.addEventListener('resize', this.onResize, false);
    global.addEventListener('orientationchange', this.onResize, false);

    this.onResize();
  }

  componentWillUnmount() {
    global.removeEventListener('resize', this.onResize, false);
    global.removeEventListener('orientationchange', this.onResize, false);
  }

  onResize = () => {
    if (this.contentRef.current) {
      const contentEl = this.contentRef.current;
      const hasScroll = contentEl.clientHeight < contentEl.scrollHeight;
      if (hasScroll !== this.state.hasScroll) {
        this.setState({ hasScroll });
      }
    }
  };

  render() {
    const {
      classNames,
      actions,
      children,
      title,
      size,
      emphasizeActions,
      headerAction,
      headerContent
    } = this.props;
    const { hasScroll } = this.state;

    return (
      <section
        className={cn(
          'hpl2-Dialog',
          {
            [`size-${size}`]: true,
            emphasizeActions: actions && emphasizeActions,
            hasScroll
          },
          classNames
        )}
      >
        <header className="hpl2-Dialog__header">
          <div className="hpl2-Dialog__headerTitle">
            <h3 className="hpl2-Dialog__title">{title}</h3>
            {headerAction && (
              <div className="hpl2-Dialog__headerAction">{headerAction}</div>
            )}
          </div>
          {headerContent && (
            <div className="hpl2-Dialog__headerContent">{headerContent}</div>
          )}
        </header>
        <div className="hpl2-Dialog__content" ref={this.contentRef}>
          {children}
        </div>
        {actions && <footer className="hpl2-Dialog__footer">{actions}</footer>}
      </section>
    );
  }
}

export default Dialog;
