import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';
import * as icons from '../../asset/icon';

import Icon from '../Icon';

/**
 * This component is used only toghether with `OptionFlyout`.
 */
const OptionFlyoutItem = ({
  classNames,
  value,
  label = value,
  selected = false,
  multiSelect = false,
  disabled = false,
  onClick = noop
}) => (
  <button
    disabled={disabled}
    type="button"
    className={cn(
      'hpl2-OptionFlyoutItem',
      { selected, multiSelect },
      classNames
    )}
    onClick={onClick}
  >
    {multiSelect && (
      <span className="hpl2-OptionFlyoutItem__check">
        {selected && <Icon source={icons.Check} />}
      </span>
    )}
    <span className="hpl2-OptionFlyoutItem__label">{label}</span>
  </button>
);

OptionFlyoutItem.displayName = 'OptionFlyoutItem';
OptionFlyoutItem.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** If no label is provided the value will be shown. */
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  /** This property will be handled by OptionFlyout. */
  selected: PropTypes.bool,
  /** This property will be handled by OptionFlyout. */
  multiSelect: PropTypes.bool,
  /** This property will be handled by OptionFlyout. */
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default OptionFlyoutItem;
