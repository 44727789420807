import React, { Component } from "react";
import ReportsTable from "./ReportsTable/index.js"
import axios from "axios";
import "./styles.scss";


class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workspaces: []
        }
    }

    componentDidMount() {
        let user = localStorage.getItem("user");
        if (!user) return;
        user = JSON.parse(user);
        if (!user.user_info || !user.user_info.email) return;
        let data = {};
        data["ad-groups"] = "*";
        const options = {
            headers: { "Content-Type": "application/json" },
        };
        axios
            .post(
                `ssbi-api/v1/user-management?email=${user.user_info.email}`,
                data,
                options
            )
            .then((response) => {
                if (response.status === 200) {
                    let workspaces = {};
                    response.data.map((item) => {
                        if (!workspaces[item.workspace]) {
                            workspaces[item.workspace] = [];
                        }
                        let report = {}
                        report.reportid = item.reportid;
                        report.reportname = item.reportname;
                        workspaces[item.workspace].push(report);
                        return;
                    })
                    let _workspaces = []
                    for (let workspace in workspaces) {
                        let _workspace = {};
                        _workspace.name = workspace;
                        _workspace.reports = workspaces[workspace];
                        _workspaces.push(_workspace);
                    }
                    this.setState({ workspaces: _workspaces });
                    return;
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    render() {
        return (
            <div className="platform-container">
                <ReportsTable/>
            </div>
        );

    }
};
export default Reports