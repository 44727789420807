import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';
import * as icons from '../../asset/icon';

import Icon from '../Icon';
import useTimeout from './use-timeout';

const iconMap = {
  default: icons.Info,
  positive: icons.CheckCircle,
  negative: icons.Warning,
  warning: icons.Info
};

/**
 * A toast which is positioned by `ToastLayout`.
 */
const Toast = ({
  classNames,
  title,
  children,
  link,
  actions,
  type = 'default',
  onClose,
  timeout,
  onTimeout = noop,
  progress
}) => {
  const {
    mouseOver,
    timedOut,
    handleMouseEnter,
    handleMouseLeave
  } = useTimeout(timeout * 1000, onTimeout);

  const hasProgress = progress !== undefined;

  return (
    <section
      className={cn(
        'hpl2-Toast',

        {
          [type]: Boolean(type),
          timeout: Boolean(timeout) && !mouseOver,
          progress: hasProgress,
          timedOut
        },
        classNames
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ animationDuration: `${timeout}s` }}
    >
      <div className="hpl2-Toast__type">
        <Icon block source={iconMap[type]} />
      </div>
      <div className="hpl2-Toast__content">
        {title && <h3 className="hpl2-Toast__title">{title}</h3>}
        <div className="hpl2-Toast__description">{children}</div>
        {link}
        {actions && <div className="hpl2-Toast__actions">{actions}</div>}
      </div>
      {onClose && (
        <button type="button" className="hpl2-Toast__close" onClick={onClose}>
          <Icon block source={icons.Close} />
        </button>
      )}
      {hasProgress && (
        <div
          className="hpl2-Toast__progress"
          style={{ transform: `scaleX(${Math.min(1, Math.max(0, progress))})` }}
        />
      )}
    </section>
  );
};

Toast.displayName = 'Toast';
Toast.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Type of toast. */
  type: PropTypes.oneOf(['default', 'positive', 'negative', 'warning']),
  /** Called when user clicks on close button. Call signature: (event) => {} */
  onClose: PropTypes.func,
  /** Timeout in seconds. When timeout is set the toast fades out until onTimeout triggers or user hovers over the toast. */
  timeout: PropTypes.number,
  /** onTimeout is called when timeout triggers and only if a timeout is set. Call Signature: () => {} */
  onTimeout: PropTypes.func,
  /** Optional title of the toast content. */
  title: PropTypes.string,
  /** Text content of the toast. */
  children: PropTypes.node.isRequired,
  /** Optional instance of linked `Button` with ghostNoSpace. */
  link: PropTypes.element,
  /** Optional instance of `Button` with ghostNoSpace. */
  actions: PropTypes.node,
  /** If type is `progress` this property should be given as a percentage between 0 and 1 to show the progress. */
  progress: PropTypes.number
};

export default Toast;
