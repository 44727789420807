import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import cn from '../../lib/class-name';

/**
 * A component which groups RadioGroupItems and exposes “onChange“ and a “value“ property.
 *
 * A ref passed to this component will have a focus function. When it is called the focus will be
 * set to the first RadioGroupItem.
 *
 * A ref created with "React.createRef()" can be passed to the component. It makes the inputs "focus()" function accessible.
 */
class RadioGroupField extends React.Component {
  static displayName = 'RadioGroupField';

  static propTypes = {
    /** Optional array of CSS utility classes. */
    classNames: PropTypes.arrayOf(PropTypes.string),
    /** One or more RadioGroupItem components. The properties “disabled“ gets passed through to them. */
    children: PropTypes.node.isRequired,
    /** Optional name of the radio group. If it is not defined a unique name will be created and passed to the RadioItem components. */
    name: PropTypes.string,
    /** Disabled state modifier */
    disabled: PropTypes.bool,
    /** Value state */
    value: PropTypes.string,
    /** Call signature: (event) => {} */
    onBlur: PropTypes.func,
    /** Call signature: (value, event) => {} */
    onChange: PropTypes.func
  };

  inputRef = React.createRef();

  defaultGroupName = uniqueId('hpl2-RadioGroupField-name-');

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  render() {
    const {
      classNames,
      children,
      value,
      name = this.defaultGroupName,
      disabled,
      onChange = noop,
      onBlur = noop
    } = this.props;

    const onClick = event => {
      onChange(event.target.value);
    };

    return (
      <div className={cn('hpl2-RadioGroupField', {}, classNames)}>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            ref: index === 0 && this.inputRef,
            name,
            checked: value === child.props.value,
            disabled: child.props.disabled || disabled,
            onClick,
            onBlur
          })
        )}
      </div>
    );
  }
}

export default RadioGroupField;
