const { LOCALES } = require("./locales");
const { default: messages } = require("./messages");

const getUserLangPreference = () => {
  let userPref = localStorage.getItem("user_pref");
  let lang;
  if (userPref) {
    lang = JSON.parse(userPref).locale;
  }
  return lang ? lang : LOCALES.ENGLISH;
}

const getMessageByPref = () => {
  console.log("33");
  const locale = getUserLangPreference();
  return messages[locale];
}

module.exports = { getUserLangPreference, getMessageByPref }