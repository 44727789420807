import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import compact from 'lodash/compact';

import cn from '../../lib/class-name';

import ContextMenuSub from './ContextMenuSub';
import ContextMenuContext from './context-menu-context';

/**
 * A ContextMenu which consists of `ContextMenuLink` and `ContextMenuSub`.
 * It should be positioned with `PositioningPortal` or `PositioningPortalWithState`.
 */
const ContextMenu = ({ classNames, children, onClose = noop }) => {
  const hasSubMenu =
    compact(
      React.Children.map(children, child => child.type === ContextMenuSub)
    ).length > 0;

  return (
    <div className={cn('hpl2-ContextMenu', {}, classNames)}>
      <ContextMenuContext.Provider value={{ onClose }}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            subMenuSibling: hasSubMenu
          })
        )}
      </ContextMenuContext.Provider>
    </div>
  );
};

ContextMenu.displayName = 'ContextMenu';
ContextMenu.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Children should be mostly instances of ContextMenuLink. */
  children: PropTypes.node.isRequired,
  /** Optional function called when the context menu should close. */
  onClose: PropTypes.func
};

export default ContextMenu;
