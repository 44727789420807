import React from "react";
import Routes from "./Routes/Routes";
import TagManager from 'react-gtm-module'
import './global-component-styles'
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  return (
    <React.Fragment>
      <Routes></Routes>
    </React.Fragment>
  );
}
