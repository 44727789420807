import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

import Icon from '../Icon';
import chevronRight from '../../asset/icon/chevron-right.svg';

/**
 * A navigation item.
 */
const NavigationItem = ({
  classNames,
  label,
  href = '#',
  target,
  onClick = noop
}) => {
  const handleClick = event => {
    event.preventDefault();
    onClick(event, href);
  };

  return (
    <a
      className={cn('hpl2-NavigationItem', {}, classNames)}
      href={href}
      target={target}
      onClick={handleClick}
    >
      {label}
      <Icon source={chevronRight} />
    </a>
  );
};

NavigationItem.displayName = 'NavigationItem';
NavigationItem.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Label string. */
  label: PropTypes.string.isRequired,
  /** Call signature: (event, href) => {} */
  onClick: PropTypes.func,
  /** Href of the link. */
  href: PropTypes.string,
  /** Optional link target. */
  target: PropTypes.string
};

export default NavigationItem;
