import axios from "axios";
const API_URI = process.env.REACT_APP_API_PREFIX || "/platform";

async function doAuthRedirect(props) {
  return axios
    .get(`${API_URI}/ad-login`)
    .then(result => {
      props.loader(true);
      window.location.assign(result.data.url);
      props.loader(true);
    })
    .catch(error => {
      console.error("Error obtaining sso configuration.", error);
    });
}

async function getAuthToken(code) {
  const payload = {
    code
  };
  try {
    const result = await axios
      .post(`${API_URI}/ad-auth-token`, payload);
    localStorage.setItem("user", JSON.stringify(result.data));
    return result.status;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      return 401;
    } else {
      return 403;
    }
  }
}

async function validate() {
  let authHeader = `Bearer ${localStorage.getItem("user").access_token}`;
  try {
    const result = await axios
      .post(`${API_URI}/ad-validate-session`, {}, { headers: { Authorization: authHeader } });
    if (result.status === 200) {
      return result.status;
    } else {
      return 401;
    }
  } catch (error) {
    return 401;
  }
}

export { doAuthRedirect, getAuthToken, validate }
