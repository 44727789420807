import React, { Component } from "react";
import logo from "../../logo.svg";
import { Spin } from "antd";
import { UrlParams } from "../../support/UrlParams";
import Carousel from "../../components/Carousel/Carousel";
import { Logo } from "@hoffmann/pattern-library-2";
import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import RequestAccess from "../../domain/access/access.component";
import LoginContainer from "../../domain/login/loginForm.container";
import "../../domain/login.scss";
import { validate, getAuthToken } from "../../domain/providers/AuthProvider";
import Utils from "../../Utils/Utils";
import { collectLogs } from "../../services/ga4/ga4.events";

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDepth: this.getPathDepth(this.props.location),
      loader: false,
      locale: "en",
    };
    this.props = {
      ...props,
      isAuthenticated: false,
    }
  }

  async componentWillMount() {
    const HREF = window.location.href.trim();
    const urlParams = new UrlParams(HREF);
    let code = urlParams.get("code");
    window.history.replaceState({}, null, "/");

    if (!localStorage.getItem("user")) {
      if (code) {
        code = code.substring(code.length - 1, code.length) === "#" ? code.substring(0, code.length - 1) : code;
        try {
          this.setState({loader: true });
          await getAuthToken(code);
          this.props.isAuthenticated = true;
          Utils.isLoggedInFlag = true;
          collectLogs("Platform", JSON.parse(localStorage.getItem("user")).user_info.email, "Login", "Login Success");
          this.setState({loader: false });
          this.props.history.replace("/home");
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          this.props.isAuthenticated = false;
          localStorage.removeItem("user");
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      const status = await validate();
      if(status === 200) {
        this.props.isAuthenticated = true;
        Utils.isLoggedInFlag = true;
        this.props.history.replace("/home");
      } else {
        Utils.isLoggedInFlag = true;
        localStorage.removeItem("user");
      }
    }
  }

  componentWillReceiveProps() {
    this.setState({ prevDepth: this.getPathDepth(this.props.location) });
  }

  getPathDepth(location) {
    let pathArr = location.pathname.split("/");
    pathArr = pathArr.filter((n) => n !== "");
    return pathArr.length;
  }

  setLoadingScreen = (val) => {
    this.setState({
      loader: val
    });
  }

  setLangPref = (val) => {
    this.setState({
      locale: val
    });
  }

  render() {
    let content;
    const { location, isAuthenticated } = this.props;
    const currentKey = location.pathname.split("/")[1] || "/";
    const timeout = { enter: 200, exit: 50 };
    if (isAuthenticated) {
      content = (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Assigned Roles</h2>
          </header>
      );
    }
    // else if (authState === "error") {
    //   content = <div> {flash} </div>;
    // }
    else {
      content = (
        <Spin
        size="large"
        className="login-loading"
        spinning={this.state.loader}
      >
      <div className="login-component">
        <div className="carousel">
          <Carousel {...this.props} />
        </div>
        <div
          ClassName="login-box"
          style={{
            display: "flex",
            width: "40.8%",
            minHeight: "730px",
            height: "100vh",
          }}
        >
          <form className="login-form">
            <div className="login-logo">
              <Logo
                label="Data Science"
                topline="Hoffmann Group"
                style={{ marginRight: "15px" }}
                classNames={["ds-logo"]}
              />
            </div>
            <TransitionGroup component="div">
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames="pageSlider"
                mountOnEnter={false}
                unmountOnExit={true}
              >
                <div
                  className={
                    this.getPathDepth(location) - this.state.prevDepth >= 0
                      ? "left"
                      : "right"
                  }
                >
                  <Switch location={location}>
                    <Route
                      path="/"
                      exact
                      render={() => <LoginContainer langPref={this.setLangPref} loader={this.setLoadingScreen} {...this.props} />}
                    />
                    <Route
                      path="/access"
                      exact
                      render={() => <RequestAccess {...this.props} />}
                    />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </form>
        </div>
      </div>
      </Spin>
      );
    }

    return <div>{content}</div>;
  }
}

export default Content;