import React from "react";
import { NavLink } from "react-router-dom";
import "./appButton.scss";

const AppButton = (props) => {
  const {
    appName,
    imgSrc,
    navPath,
    imgAltName,
    textColor,
    onClick,
    onButtonMouseOver,
    onButtonMouseOut,
    active
  } = props;
  return (
    <NavLink to={navPath}>
      <button
        className={active ? "dmBtn" : "dmBtn-disabled"}
        onMouseOver={onButtonMouseOver}
        onMouseOut={onButtonMouseOut}
        onClick={onClick}
      >
        <img
          className="app-icon"
          alt={imgAltName}
          src={require(`${imgSrc}`)}
        ></img>
        <p
          className="label"
          style={{
            color: textColor,
            maxWidth:
              appName === "Berechnungsmatrix Sonderwerkzeug" ? "111px" : "66px",
          }}
        >
          {appName}
        </p>
      </button>
    </NavLink>
  );
};

export default AppButton;
