import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useKeyPress from '../../hook/use-key-press';

import ModalPortal from '../ModalPortal';

const KEY_ESC = 27;

/**
 * The ModalHandler provides a simple solution to open a modal or overlay and handle its state.
 * Usage of this component is optional. Its also possible to use the ModalPortal directly and handle the state outside.
 */
const ModalHandler = ({ children, modal, closable = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  useKeyPress(() => closable && close(), KEY_ESC);

  return (
    <>
      {children({ isOpen, open, close })}
      <ModalPortal isOpen={isOpen} onClose={closable ? close : undefined}>
        {React.cloneElement(
          React.isValidElement(modal) ? modal : modal({ isOpen, open, close }),
          { onClose: closable ? close : undefined }
        )}
      </ModalPortal>
    </>
  );
};

ModalHandler.displayName = 'ModalHandler';
ModalHandler.propTypes = {
  /** A render prop function with signature: ({isOpen, open, close}) => {}. */
  children: PropTypes.func.isRequired,
  /**
   * Either an instance of Modal, Overlay or a render prop function
   * which returns an instance of Modal or Overlay with signature: ({isOpen, open, close}) => Modal | Overlay.
   */
  modal: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  /**
   * If closable the modal can always be closed
   * via click on close button, click on background or pressing ESC.
   */
  closable: PropTypes.bool
};

export default ModalHandler;
