import React from "react";
import "./styles.scss";
import MainApps from "./MainAppsComponent";
import AllAppsReports from "../Reports/AllAppsReports";
import Others from "../Others";

export default class AllApps extends React.Component {
  state = {
    display: "block",
  };
  render() {
    return (
      <React.Fragment>
        <div className="ant-row land-height">
          <div className="ant-col ant-col-xs-24 ant-col-xl-24">
            <div className="landingpage-card">
              <div className="appIcon">
                <MainApps />
              </div>
              <div className="appIcon">
                <AllAppsReports />
              </div>
              <div className="appIcon">
                <Others />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
