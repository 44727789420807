import React, { Component } from 'react';

class ReportIframe extends Component {
    
    render() {
        const { match } = this.props;

        return (
                <iframe
                    width="100%"
                    height="100%"
                    title="PricingReport"
                    src={` https://app.powerbi.com/reportEmbed?reportId=${match.params.reportId}&autoAuth=true`}
                    frameborder="0"
                    allowFullScreen="true"
                ></iframe>            

        )

    }
}
export default ReportIframe;