import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * The ToastLayout wraps parts of the application content and positions given toasts.
 */
const ToastLayout = ({ classNames, children, toasts }) => (
  <div className={cn('hpl2-ToastLayout', {}, classNames)}>
    {children && <div className="hpl2-ToastLayout__content">{children}</div>}
    <div className="hpl2-ToastLayout__toasts">{toasts}</div>
  </div>
);

ToastLayout.displayName = 'ToastLayout';
ToastLayout.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Aribtrary content which is wrapped by ToastLayout. */
  children: PropTypes.node,
  /** Array of Toast components. The toasts will be listed from top to bottom. */
  toasts: PropTypes.arrayOf(PropTypes.element)
};

export default ToastLayout;
