module.exports = {
  isLoggedInFlag: false,
  isLoggedIn() {
    var user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      this.isLoggedInFlag = user.access_token ? true : false;
    } else {
      this.isLoggedInFlag = false;
    }
    return this.isLoggedInFlag;
  },

  isUserAdmin(roles) {
    let user = localStorage.getItem("user");
    if (!user) return false;
    user = JSON.parse(user);
    if (
      user &&
      user.user_info &&
      user.user_info.realm_access &&
      user.user_info.realm_access.roles
    ) {
      return roles.some((r) => {
        return user.user_info.realm_access.roles.includes(r);
      });
    }
    return false;
  },

  isProductOwner() {
    let user = localStorage.getItem("user");
    if (!user) return false;
    user = JSON.parse(user);
    console.log(process.env);
    let productOwners = [];
    productOwners.push(process.env.REACT_APP_CAT_PO);
    productOwners.push(process.env.REACT_APP_PRC_PO);
    productOwners.push(process.env.REACT_APP_SSBI_PO);
    productOwners.push(process.env.REACT_APP_MLP_PO);
    productOwners.push(process.env.REACT_APP_PMPOT_PO);
    productOwners.push(process.env.REACT_APP_PIN_PO);
    return productOwners.includes(user.user_info.email);

  },

  getPoProductList() {
    let user = localStorage.getItem("user");
    if (!user) return [];
    user = JSON.parse(user);
    let productOwners = [];
    var productList = [];
    productOwners.push(process.env.REACT_APP_CAT_PO);
    productOwners.push(process.env.REACT_APP_PRC_PO);
    productOwners.push(process.env.REACT_APP_SSBI_PO);
    productOwners.push(process.env.REACT_APP_MLP_PO);
    productOwners.push(process.env.REACT_APP_PMPOT_PO);
    productOwners.push(process.env.REACT_APP_PIN_PO);

    for (let i = 0; i < productOwners.length; i++) {
      if (productOwners[i] === user.user_info.email) {
        switch (i) {
          case 0:
            productList.push("Content Automation");
            break;
          case 1:
            productList.push("Price Response Curves");
            break;
          case 2:
            productList.push("SSBI Manager");
            break;
          case 3:
            productList.push("Market List Prices");
            break;
          case 4:
            productList.push("PM Price Optimizer");
            break;
          case 5:
            productList.push("Pricing International");
            break;
          default:
            // productList
            break;
        }
      }
    }
    return productList;
  },

  logOut() {
    this.isLoggedIn(false);
  },
  userDetails: {},
  getLoggedUserName() {
    let user = localStorage.getItem("user");
    if (!user) return "";
    user = JSON.parse(user);
    if (!user.user_info || !user.user_info.name) return "";
    return user.user_info.name;
  },

  getLoggedUserEmail() {
    let user = localStorage.getItem("user");
    if (!user) return "";
    user = JSON.parse(user);
    if (!user.user_info || !user.user_info.email) return "";
    return user.user_info.email;
  },

  getLoggedUserDetails() {
    let user = localStorage.getItem("user");
    if (!user) return "";
    user = JSON.parse(user);
    if (!user.user_info || !user.user_info.email) return "";
    return user.user_info.given_name + " " + user.user_info.family_name + " ( " + user.user_info.email + " )";
  },

  getLoggedUserRoles() {
    let user = localStorage.getItem("user");
    if (!user) return [];
    user = JSON.parse(user);
    if (!user.user_info.realm_access.roles) return [];
    else {
      return user.user_info.realm_access.roles.filter((role) => {
        return role !== "offline_access" && role !== "uma_authorization";
      });
    }
  },

  setUserLangPref(lang) {
    console.log("1");
    updateLangPref(lang);
  },

  triggerEvents(lang) {
    console.log("4");
    updateLangPref(lang);
    const sendEvent = new CustomEvent('custom_event', {
      detail: {
        locale: lang
      }
    });
    document.dispatchEvent(sendEvent);
  }
};

function updateLangPref(lang) {
  let userPref = {
    locale: lang,
  }
  localStorage.setItem("user_pref", JSON.stringify(userPref));
  return true;
}