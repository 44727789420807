import React, { Component } from "react";
import AppButton from "../AppButton";
import IconData from "../AppButton/iconsData";
import AuthorizedElement from "./../../Utils/AuthorizedElement";

class Others extends Component {
  state = {
    others: {
      textColor: IconData.others.textonOut,
      imgSrc: IconData.others.imageOnOut,
    },
  };
  showHoverImage = (key, flag) => {
    const button = { ...this.state[key] };
    if (flag) {
      button.textColor = IconData[key].textOnHover;
      button.imgSrc = IconData[key].imageOnHover;
    } else {
      button.textColor = IconData[key].textonOut;
      button.imgSrc = IconData[key].imageOnOut;
    }
    this.setState({ [key]: button });
  };
  render() {
    return (
      <AuthorizedElement roles={["others_user", "others_admin"]}>
        <React.Fragment>
          <h2 className="heading">Others</h2>
          <div style={{ marginTop: "10px" }}>
            <AppButton
              Key="others"
              appName="Berechnungsmatrix Sonderwerkzeug"
              imgSrc={this.state.others.imgSrc}
              navPath="/others/berechnungsmatrix"
              imgAltName="Berechnungsmatrix Sonderwerkzeug"
              textColor={this.state.others.textColor}
              onButtonMouseOver={() => {
                this.showHoverImage("others", true);
              }}
              onButtonMouseOut={() => {
                this.showHoverImage("others", false);
              }}
            ></AppButton>
          </div>
        </React.Fragment>
      </AuthorizedElement>
    );
  }
}

export default Others;
