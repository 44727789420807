import React, { Component } from "react";
import { Spin } from "antd";
import axios from "axios";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import './AppStatus.scss'
import { I18nProvider } from '../../../i18n';
import translate from '../../../i18n/translate';
import { getUserLangPreference } from "../../../i18n/utils";
import Table from '../../Table'

class AppStatus extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            platformAPI: false,
            cachingService: false,
            auditLogger: false,
            catAPI: false,
            emailService: false,
            ssbiAPI: false,
            importService: false,
            potAPI: false,
            mlpAPI: false,
            prcAPI: false,
            azureAPI: false
        }
    }

    componentDidMount() {
        this.getHealthCheck();
    }

    async getHealthCheck() {
        this.setState({ isLoading: true })
        const options = {
            headers: { "Content-Type": "application/json" },
        };
        var urlName = [
            {
                endpoint: "/platform",
                state: "platformAPI"
            },
            {
                endpoint: "/pcs-api",
                state: "cachingService"
            },
            {
                endpoint: "/als-api",
                state: "auditLogger"
            },
            {
                endpoint: "/content-api",
                state: "catAPI"
            },
            {
                endpoint: "/ses-api",
                state: "emailService"
            },
            {
                endpoint: "/ssbi-api/v1",
                state: "ssbiAPI"
            },
            {
                endpoint: "/cat-import-api",
                state: "importService"
            },
            {
                endpoint: "/pot-api",
                state: "potAPI"
            },
            {
                endpoint: "/ops-api",
                state: "azureService"
            },
            {
                endpoint: "/mlp-api",
                state: "mlpAPI"
            },
            // {
            //     endpoint: "/api",
            //     state: "prcAPI"
            // },
        ]
        try {

            for (let i = 0; i < urlName.length; i++) {
                await axios.get(urlName[i].endpoint + "/health-check", options)
                    .then((response) => {
                        if (response) {
                            if (response.status === 200) {
                                this.setState({ [`${urlName[i].state}`]: true });
                            }
                        }
                        else {
                            this.setState({ [`${urlName[i].state}`]: false });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
            this.setState({ isLoading: false });     
        }
        catch (err) {
            this.setState({ isLoading: false })
            console.log(err);
        }
    }

    getstatusColumns() {
        const columns = [
            {
                title: //<span className="first-column">{"App Name"}</span>,
                    <I18nProvider locale={getUserLangPreference()}>
                        <span>{translate("Settings.AppName")}</span>
                    </I18nProvider>,
                dataIndex: 'name',
                key: 'name',
                render: (name) => (
                    <React.Fragment>
                        <p className="first-column">
                            {name}
                        </p>

                    </React.Fragment>
                )
            },
            {
                title: <span className="app-status-header-lbl-row">{translate("Settings.Status")}</span>,
                dataIndex: 'status',
                align:'center',
                key: 'status',
                render: (status) => (
                    <React.Fragment>
                        <div className="enabled-disabled-icon-container">
                            {status === true ?  <span className="running"><CheckOutlined className="enabled" />Running</span> : <span className="stopped"><CloseOutlined className="disabled" />Stopped</span>}
                        </div>
                    </React.Fragment>
                )
            }
        ]
        return columns;
    }

    getDataSource() {
        const dataSource = [
            {
                key: '1',
                name: 'Platform API',
                status: this.state.platformAPI
            },
            {
                key: '2',
                name: 'CAT API',
                status: this.state.catAPI
            },
            {
                key: '3',
                name: 'Caching Service',
                status: this.state.cachingService
            },
            {
                key: '4',
                name: 'Import Service',
                status: this.state.importService
            },
            {
                key: '5',
                name: 'Audit Logger',
                status: this.state.auditLogger
            },
            {
                key: '6',
                name: 'Email Service',
                status: this.state.emailService
            },
            {
                key: '7',
                name: 'Azure Service',
                status: this.state.azureService
            },
            {
                key: '8',
                name: 'SSBI API',
                status: this.state.ssbiAPI
            },
            {
                key: '9',
                name: 'POT API',
                status: this.state.potAPI
            },
            {
                key: '10',
                name: 'MLP API',
                status: this.state.mlpAPI
            },
            // {
            //     key: '11',
            //     name: 'PRC API',
            //     status: this.state.prcAPI
            // }
        ];

        return dataSource;
    }

    render() {

        return (
            <Spin
                size="large"
                className="login-loading"
                spinning={this.state.isLoading}
            >
                <div className="table-container">
                    <Table columns={this.getstatusColumns()} data={this.getDataSource()} scroll={{ y: 'calc(100vh - 300px)' }}/>
                </div>
            </Spin>
        )
    }

};
export default AppStatus;