import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';
import LoadingSpinner from '../LoadingSpinner';

/**
 * Circular button component.
 * The icon property must be defined.
 */
const CircularButton = ({
  classNames,
  type = 'button',
  onClick,
  icon,
  size = 100,
  theme = 'default',
  onBackground = 'light',
  colorBar,
  label,
  ghost = false,
  outline = false,
  shadow = false,
  disabled = false,
  loading = false
}) => (
  <button
    type={type}
    className={cn(
      'hpl2-CircularButton',
      {
        ghost,
        outline,
        shadow,
        loading,
        colorBar: !!colorBar,
        [`size-${size}`]: size,
        [`theme-${theme}`]: theme,
        [`onBackground-${onBackground}`]: onBackground
      },
      classNames
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {!loading && icon}
    {!loading && label && (
      <span className="hpl2-CircularButton__label">{label}</span>
    )}
    {!loading && colorBar && (
      <span
        className="hpl2-CircularButton__colorBar"
        style={theme === 'white' || ghost ? { color: colorBar } : {}}
      />
    )}
    {loading && <LoadingSpinner inherit />}
  </button>
);

CircularButton.displayName = 'CircularButton';
CircularButton.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The size of the button element. */
  size: PropTypes.oneOf([100, 200]),
  /** The type of the button element. */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Call signature: (event) => {} */
  onClick: PropTypes.func,
  /** An instance of Icon. */
  icon: PropTypes.element,
  /** The color theme of the button itself. */
  theme: PropTypes.oneOf([
    'default',
    'brand',
    'white',
    'positive',
    'negative',
    'action'
  ]),
  /** The background context in which the button is placed. */
  onBackground: PropTypes.oneOf(['light', 'dark']),
  /** Transparent background variant of the button. */
  ghost: PropTypes.bool,
  /** Outlined variant of the button. */
  outline: PropTypes.bool,
  /** Box-shadowed variant of the button. */
  shadow: PropTypes.bool,
  /** Non-interactive variant. */
  disabled: PropTypes.bool,
  /** Variant with loading spinner. */
  loading: PropTypes.bool,
  /** Variant with label. */
  label: PropTypes.string,
  /** Variant of the button where a color bar under the icon is shown. Provide a hex color like #ff00ff here. Attention: The given color is only visible whith theme white or as a ghost button! */
  colorBar: PropTypes.string
};

export default CircularButton;
