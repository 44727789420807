import React, { Component } from "react";
import AllUsersComponent from "./allUser.component";
import RemoveModal from "./../../Modal/remove/removeDataModal.component";
import {
  getAllUsersAndRoles,
  getAllPoUsersAndRoles,
  appRoles,
  appScopes,
  deleteUser,
  deleteUserApp,
  modifyUserScopes,
  modifyUserRole,
} from "../../../services/queries/dataPlatFormApi";
import { getLoggedUserName, getPoProductList, isProductOwner, isUserAdmin } from "../../../Utils/Utils";

class AllUsers extends Component {
  state = {
    modalisVisbile: false,
    modalContent: "",
    removeAction: null,
    isSpinning: true,
    data: [],
    filteredData: [],
    appRoles: [],
    appScopes: [],
    searchTearm: "Admin",
    searchTerm: "",
    isFilter: false
  };

  componentDidMount = () => {
    if(isUserAdmin(["settings_admin"])) {
      Promise.all([getAllUsersAndRoles(), appRoles(), appScopes()])
      .then((res) => {
        this.setState({
          data: res[0].data,
          appRoles: res[1].data,
          appScopes: res[2].data,
          isSpinning: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    }
    else if(isProductOwner()) {
      Promise.all([getAllPoUsersAndRoles(getPoProductList()), appRoles(), appScopes()])
      .then((res) => {
        this.setState({
          data: res[0].data,
          appRoles: res[1].data,
          appScopes: res[2].data,
          isSpinning: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  handleDeleteUser = (user) => {
    this.setState({
      modalisVisbile: true,
      modalContent: `Are you sure you want to remove ${user} ’s Access?`,
      removeAction: () => {
        this.userDelete(user);
      },
    });
  };

  handleDeleteApp = (userApp) => {
    this.setState({
      modalisVisbile: true,
      modalContent: `Are you sure you want to remove ${userApp.role_name}’s access
      to ${userApp.project_name}?`,
      removeAction: () => {
        this.userAppDelete(userApp);
      },
    });
  };

  userDelete = (user) => {
    Promise.all([deleteUser(user), getAllUsersAndRoles()])
      .then((res) => {
        this.setState({
          modalisVisbile: false,
          data: this.state.data.filter((data) => data.user_email !== user),
        });
        this.forceUpdate();
      })
      .catch((err) => {
        console.log("  " + err);
      });
  };

  userFilter = () => {
    if (this.state.searchTerm !== "" && this.state.data.length > 0) {
      this.setState({ isFilter: true });
      const filteredData = this.state.data.filter((o) =>
        Object.keys(o).some((k) => {
          return String(o[k])
            .toLowerCase()
            .includes(this.state.searchTerm.toLowerCase());
        })
      );
      this.setState({ filteredData });
    }
    else {
      this.setState({filteredData: [], isFilter: false})
    }
  }

  userAppDelete = async (userApp) => {
    Promise.all([deleteUserApp(userApp), getAllUsersAndRoles()])
      .then((res) => {
        this.setState({
          modalisVisbile: false,
          data: this.state.data.filter((data) => data !== userApp)
        });
        this.userFilter();
      })
      .catch((err) => {
        console.log("  " + err);
      });
  };

  handleOnRoleChange = (value, row) => {
    row.new_role_name = value;
    modifyUserRole(row)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOnScopeChange = (value, row) => {
    row.scopes = value;
    modifyUserScopes(row)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleNavigateToSettings = (path) => {
    this.props.history.push(path);
  };
  handleCancel = () => {
    this.setState({ modalisVisbile: false });
  };
  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value }, () => {
      this.userFilter();
    });
  };

  render() {
    const { filteredData, data } = this.state;
    return (
      <React.Fragment>
        <AllUsersComponent
          isSpinning={this.state.isSpinning}
          datasource={this.state.isFilter ? filteredData : data}
          appRoles={this.state.appRoles}
          appScopes={this.state.appScopes}
          deleteUser={this.handleDeleteUser}
          deleteApp={this.handleDeleteApp}
          onRoleChange={this.handleOnRoleChange}
          onScopeChange={this.handleOnScopeChange}
          navigateToSettings={this.handleNavigateToSettings}
          onSearchChange={this.handleSearchChange}
          userName={getLoggedUserName()}
        />
        <RemoveModal
          visible={this.state.modalisVisbile}
          msg={this.state.modalContent}
          onRemove={this.state.removeAction}
          onCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}

export default AllUsers;
