import React, { Component } from "react";
import { Row, Col, Button, Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

class ButtonCell extends Component {
  state = {
    actionPerformed: false,
    isAuthorised: null,
  };

  getIconSymbol = () => {
    if (this.state.isAuthorised) {
      return <CheckOutlined style={{ color: "#40cc33" }} />;
    } else {
      return <CloseOutlined style={{ color: "#ff0000" }} />;
    }
  };
  showModal = () => {
    Modal.error({
      title: "Not a keycloak user",
      content: "Authorize action failed. Please request user to login and register to the Data Science Platform.",
    });
  };
  handleAuthorize = (flag) => {
    const { onClickAccess, userRole } = this.props;
    onClickAccess(flag, userRole).then((res) => {
      if (res) {
        this.setState({ isAuthorised: true, actionPerformed: true });
      } else {
        this.showModal();
        this.setState({ isAuthorised: false, actionPerformed: false });
      }
    });
  };
  render() {
      return this.state.actionPerformed ? (
        this.getIconSymbol()
      ) : (
        <Row>
          <Col flex="auto">
            <Button
              onClick={() => this.handleAuthorize(false)}
              className="deny-btn"
            >
              <span className="lbl" style={{ color: "#515151" }}>
                Deny
              </span>
            </Button>
            <Button
              onClick={() => this.handleAuthorize(true)}
              className="auth-btn"
            >
              <span className="lbl" style={{ color: "#ffffff" }}>
                Authorize
              </span>
            </Button>
          </Col>
        </Row>
      );
    }
}

export default ButtonCell;
