import React, { Component } from "react";
import { Logo } from "@hoffmann/pattern-library-2";
import { Row, Col, Button } from "antd";
import "./successWindow.scss";

class SuccessWindow extends Component {
  state = {};

  navigateToLogin = () => {
    this.props.history.push("/home");
  };
  render() {
    return (
      <Row className="success-window">
        <Row style={{ margin: "auto", paddingTop: "30%" }}>
          <Col>
            <img src={require("../icons/Success Tick.svg")} alt="success" />
          </Col>
        </Row>
        <Row style={{ margin: "auto", paddingTop: "26px" }}>
          <Col>
            <span className="success-msg">Request Successfully Sent!</span>
          </Col>
        </Row>
        <Row style={{ margin: "auto", paddingTop: "7.8%" }}>
          <Col>
            <Button className="back-btn" onClick={this.navigateToLogin}>
              <span className="btn-label">BACK TO HOME SCREEN</span>
            </Button>
          </Col>
        </Row>
        <Row style={{ margin: "auto", paddingTop: "21.2%" }}>
          <Col>
            <Logo
              label="Data Science"
              topline="Hoffmann Group"
              style={{ marginRight: "15px" }}
              classNames={["access-logo"]}
            />
          </Col>
        </Row>
      </Row>
    );
  }
}

export default SuccessWindow;
