import React, { Component } from "react";
import AccessComponent from "./accessWindow.component";
import { insertRequestedRecord } from "./../../services/queries/dataPlatFormApi";
import { getLoggedUserName, isUserAdmin, isProductOwner, getLoggedUserDetails } from "../../Utils/Utils";
import { Spin } from 'antd';
import axios from "axios";

class AccessContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
      userAppRoles: [],
      appRoles: [],
      selectedAppRoles: [],
      users: [],
      reqForEmail: "",
      reqForName: "",
      visible: true,
      selectvalue: getLoggedUserDetails()
    };
    (isUserAdmin(["settings_admin"]) || isProductOwner()) ? this.getAllUsers() : this.getAllApps();
  }

  overlayingApps = (
    selectedAppRoles,
    userAppRoles,
    final_request_app_roles
  ) => {
    for (var k = 0; k < selectedAppRoles.length; k++) {
      const found = userAppRoles.some(
        (el) => el.role_name === selectedAppRoles[k].role_name
      );
      if (!found) final_request_app_roles.push(selectedAppRoles[k]);
    }
    return final_request_app_roles;
  };

  filterRequestedApps = (
    selectedAppRoles,
    userAppRoles,
    final_request_app_roles
  ) => {
    for (var i = 0; i < selectedAppRoles.length; i++) {
      for (var j = 0; j < userAppRoles.length; j++) {
        if (userAppRoles[j].role_name === selectedAppRoles[i].role_name) {
          if (
            userAppRoles[j].permission_name !== "granted" &&
            selectedAppRoles[i].permission_name === "requested"
          ) {
            final_request_app_roles.push(selectedAppRoles[i]);
          }
        }
      }
    }
    return final_request_app_roles;
  };

  handleOnSelectRequest = () => {
    const { userAppRoles, selectedAppRoles } = this.state;
    let final_request_app_roles = [];
    final_request_app_roles = this.overlayingApps(
      selectedAppRoles,
      userAppRoles,
      final_request_app_roles
    );

    final_request_app_roles = this.filterRequestedApps(
      selectedAppRoles,
      userAppRoles,
      final_request_app_roles
    );

    insertRequestedRecord(final_request_app_roles).then((res) => {
      if (res.data) {
        this.props.history.replace("request-success");
      } else {
        console.log("All apps are requested or granted");
      }
    });
  };

  handleNavigation = () => {
    this.props.history.push("/settings");
  };


  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };
  handleModifyingAppRoles = (appName, role) => {
    if (role === "") {
      this.setState({
        selectedAppRoles: this.state.selectedAppRoles.filter(
          (selected_app_role) => {
            return selected_app_role.project_name !== appName;
          }
        ),
      });
    } else {
      const { selectedAppRoles } = this.state;
      const index = this.findWithAttr(
        this.state.selectedAppRoles,
        "project_name",
        appName
      );
      if (index !== -1) {
        const item = { ...selectedAppRoles[index] };
        item.role_name = role;
        selectedAppRoles[index] = item;
        this.setState({ selectedAppRoles });
      } else {
        const user_app_role_obj = {
          permission_name: "requested",
          role_name: role,
          project_name: appName,
          req_by_user_email: this.props.location.state.email,
          req_by_user_name: JSON.parse(localStorage.getItem("user")).user_info.name,
          req_for_user_email: this.state.reqForEmail,
          req_for_user_name: this.state.reqForName,
        };
        this.setState({
          selectedAppRoles: [...selectedAppRoles, user_app_role_obj],
        });
      }
    }
  };

  getAllUsers = () => {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    axios.get("/platform/users",options).then(
      (response) => {
        this.setState({ users: response.data});
        this.getAllApps();
      }).catch(err=> console.log(err));
  };

  getAllApps = () => {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    axios.get("/platform/apps", options).then((res) => {
      this.allApps = res.data;
      this.setState({ apps: res.data});
      this.getAllAppRoles();
    }).catch(err=> console.log(err));
  };

  getAllAppRoles = () => {
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    axios.get("/platform/app-roles", options).then((res) => {
      this.setState({ appRoles: res.data});
      this.getUserRolesByEmail();
    }).catch(err=> console.log(err));
  };

  getUserRolesByEmail = (value1, value2) => {
    let email, name;
    if(value1) {
      email = value1;
      name = value2;
      this.setState({ visible: true,  selectvalue: value2+ " ( " +value1+ " )" });
    } else {
      email = this.props.location.state.email;
      name=JSON.parse(localStorage.getItem("user")).user_info.name;
    }
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    
    axios.post("/platform/user-roles",{ user_email: email },options).then((res) => {
      this.setState({ userAppRoles: res.data, reqForEmail: email, reqForName: name, visible:false});
    }).catch(err=> console.log(err));
  };

  render() {
    return (
      <>
      <Spin spinning={this.state.visible}>
      <AccessComponent
        apps={this.state.apps}
        name={getLoggedUserName()}
        navigateToApps={this.handleNavigation}
        userAppsRoles={this.state.userAppRoles}
        appRoles={this.state.appRoles}
        onSelectRequest={this.handleOnSelectRequest}
        modifyAppRoles={this.handleModifyingAppRoles}
        users={this.state.users}
        getUserRolesByEmail={this.getUserRolesByEmail}
        selectvalue = {this.state.selectvalue}
      />
      </Spin>
      </>
    );
  }
}

export default AccessContainer;
