import React, { Component } from "react";
import { chunk } from "lodash";
import { Row, Col, Button, Avatar, Select } from "antd";
import "./accessWindow.scss";
import CardComponent from "./applicationCard/card.component";
import ReturnButton from "../../components/Settings/ReturnButton/ReturnButton";
import Slider from "react-slick";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import ValidatedCard from "./applicationCard/validatedCard.component";

const { Option } = Select;

class AccessComponent extends Component {
  state = {
    selected_app_roles: {},
    index: 0,
  };

  handleSelectedAppRoles = (roles) => {
    this.setState({ appName: roles });
  };

  toggle = (i) => {
    this.setState({ index: i });
    this.slider.slickGoTo(i);
  };

  handleSelectedUser = (value) => {
    this.props.getUserRolesByEmail(value[1], value[0]);
  };
  render() {
    const shadowColor = {
      contentautomation: "#9F66EF",
      kampriceoptimizer: "#38cac3",
      marketlistprices: "#ff5a62",
      priceresponsecurves: "#24bf97",
      pmpriceoptimizer: "#c44a83",
      ssbimanager: "#25b4f9",
    };
    const {
      userAppsRoles,
      apps,
      onSelectRequest,
      modifyAppRoles,
      appRoles,
      navigateToApps,
      name,
      users,
      selectvalue
    } = this.props;
    const scrollButton = chunk(apps, 6);
    const settings = {
      vertical: true,
      slidesToShow: 1,
      infinite: true,
      slidesToScroll: 1,
      arrow: false,
      speed: 500,
      accessibility: false,
    };
    return (
      <React.Fragment>
        <div className="access-container">
          <Row>
            <div className="flex-layout">
              <Col flex="auto">
                <ReturnButton
                  headerOne=""
                  headerTwo="Settings"
                  path="/home"
                  onClick={navigateToApps}
                />
              </Col>
              <Col justify="end">
                <Avatar className="avatar" shape="square" size={60}>
                  <span className="cap-letter">{name[0]}</span>
                </Avatar>
                <a className="settings-profile-name">{name}</a>
              </Col>
            </div>
          </Row>
          <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div className="flex-layout">
              <Col>
                <span className="access-request-label">Request Access</span>
              </Col>
              <Col flex={"auto"}>
                <Select showSearch className="user-select" value={selectvalue} onSelect={(e, labledValue) => this.handleSelectedUser(labledValue.value)}>
                  {
                    users.map((userItem, i) => (
                      <Option key={i} value={[userItem.displayname, userItem.email]}>{userItem.displayname}  ( {userItem.email} )</Option>
                    ))
                  }
                </Select>
              </Col>
              <Col flex={"auto"}>
                <Button
                  onClick={onSelectRequest}
                  className="access-request-button"
                >
                  <span className="access-request-button-label">
                    Send Request
                </span>
                </Button>
              </Col>
            </div>
          </Row>
          <div className="container-box">
            <Slider ref={(slider) => (this.slider = slider)} {...settings}>
              {scrollButton.map((appsInScroll) => {
                const appsInRows = chunk(appsInScroll, 3);
                return (
                  <div>
                    <Row
                      key={scrollButton.indexOf(appsInScroll)}
                      className="cards-container"
                    >
                      <Col flex="auto">
                        {appsInRows.map((appsInRow) => {
                          return (
                            <Row
                              className="mg-tp-40 txt-algn-cntr"
                              gutter={[24, 24]}
                            >
                              <div className="flex-layout">
                                {appsInRow.map((app) => {
                                  const userAppRoles = userAppsRoles.filter(
                                    (role) => {
                                      return (
                                        app.project_name === role.project_name
                                      );
                                    }
                                  );
                                  const aAppRoles = appRoles.filter((appRole) => {
                                    return (
                                      app.project_name === appRole.project_name
                                    );
                                  });
                                  return (
                                    <Col span={8}>
                                      {userAppRoles.length === 0 ? (
                                        <CardComponent
                                          key={`${app.project_name}-`}
                                          appName={app.project_name}
                                          desc={app.project_description}
                                          modifyAppRoles={modifyAppRoles}
                                          userAppRoles={userAppRoles.filter(
                                            (userAppRole) =>
                                              app.project_name ===
                                              userAppRole.project_name
                                          )}
                                          appRoles={aAppRoles}
                                          shadowColor={
                                            shadowColor[
                                            app.project_name
                                              .replace(/\s/g, "")
                                              .toLowerCase()
                                            ]
                                          }
                                        />
                                      ) : (
                                        <ValidatedCard
                                          key={app.project_name}
                                          appName={app.project_name}
                                          userRole={userAppRoles}
                                        />
                                      )}
                                    </Col>
                                  );
                                })}
                              </div>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row></Row>
                  </div>
                );
              })}
            </Slider>
            <Row style={{ marginTop: "59px" }}>
              <Button
                style={{
                  margin: "auto",
                  borderRadius: "3px",
                }}
                onClick={() => {
                  this.toggle(!this.state.index);
                }}
              >
                {!this.state.index ? <DownOutlined /> : <UpOutlined />}
              </Button>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccessComponent;
