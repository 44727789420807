import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 *  All children of the `TabBar` must be of type `TabBarItem`
 */
const TabBar = ({ children, classNames }) => (
  <div className={cn('hpl2-TabBar', {}, classNames)}>{children}</div>
);

TabBar.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** An array of TabBarItems. */
  children: PropTypes.node
};

export default TabBar;
