import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

/**
 * A button which can be passed to an InputField to place a custom action button inside.
 */
const FieldActionButton = ({
  classNames,
  type = 'button',
  icon,
  onClick = noop,
  disabled = false
}) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={cn('hpl2-FieldActionButton', [], classNames)}
  >
    {icon}
  </button>
);

FieldActionButton.displayName = 'FieldActionButton';
FieldActionButton.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Button type. */
  type: PropTypes.oneOf(['button', 'submit']),
  /** Instance of Icon component. */
  icon: PropTypes.element.isRequired,
  /** Call signature: (event) => {} */
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default FieldActionButton;
