import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

import logo from '../../asset/image/logo.svg';

/**
 * General application logo with optional label.
 */
const Logo = ({ classNames, topline, label, onClick }) =>
  React.createElement(
    onClick ? 'button' : 'div',
    {
      className: cn('hpl2-Logo', {}, classNames),
      type: onClick ? 'button' : undefined,
      onClick
    },
    <>
      <img className="hpl2-Logo__signet" src={logo} alt="Hoffmann Group Logo" />
      {label && (
        <span className="hpl2-Logo__content">
          {topline && <span className="hpl2-Logo__topline">{topline}</span>}
          <strong className="hpl2-Logo__label">{label}</strong>
        </span>
      )}
    </>
  );

Logo.displayName = 'Logo';
Logo.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Topline usually stating "Hoffmann Group". */
  topline: PropTypes.string,
  label: PropTypes.string,
  /** If set makes the logo clickable. Call signature: (event) => {} */
  onClick: PropTypes.func
};

export default Logo;
