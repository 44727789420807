import React, { Children } from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * A component with a sticky title used to divide `TableRow`s into multiple sections.
 */
const TableSection = ({ classNames, title, children, computedWidths }) => (
  <div className={cn('hpl2-TableSection', {}, classNames)}>
    <div className="hpl2-TableSection__title">{title}</div>
    <div className="hpl2-TableSection__content">
      {Children.map(children, child =>
        React.cloneElement(child, { computedWidths })
      )}
    </div>
  </div>
);

TableSection.displayName = 'TableSection';
TableSection.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Section title. */
  title: PropTypes.node.isRequired,
  /** `TableRow`s as children. */
  children: PropTypes.node.isRequired,
  /** The computed widths for each cell (injected by parent). */
  computedWidths: PropTypes.arrayOf(PropTypes.string)
};

export default TableSection;
