import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * Use `TableHeaderCell` only within the table.
 */
const TableHeaderCell = ({
  classNames,
  textAlign = 'left',
  computedWidth,
  children
}) => (
  <div
    className={cn(
      'hpl2-TableHeaderCell',
      {
        [`textAlign-${textAlign}`]: true
      },
      classNames
    )}
    style={{
      width: computedWidth
    }}
  >
    {children}
  </div>
);

TableHeaderCell.displayName = 'TableHeaderCell';
TableHeaderCell.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The content of an cell. In certain cases header cells are also allowed to be empty. */
  children: PropTypes.node,
  /** The width of a column. It accepts a value that serves as a proportion or as a pixel value depending on whether fixed is set or not. */
  width: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  /** If set the width is in pixel instead of a proportion. */
  fixed: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  /** Aligns the text left, center or right. */
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  /** The computed width of the cell (injected by parent). */
  computedWidth: PropTypes.string
};

export default TableHeaderCell;
