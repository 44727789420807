import React from "react";
import axios from "axios";
import { Spin } from "antd";
import AppNotFound from "../AppNotFound";
import "./styles.scss";

class MicroFrontend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      AppLoaded: true,
    };
  }
  componentDidMount() {
    const { name, host, document } = this.props;
    const scriptId = `micro-frontend-script-${name}`;
    const styleId = `micro-frontend-style-${name}`;

    if (document.getElementById(scriptId)) {
      this.renderMicroFrontend();

      return;
    }

    axios
      .get(`${host}/asset-manifest.json`)
      .then((res) => res.data)
      .then((manifest) => {
        // loading scripts
        const script = document.createElement("script");
        script.id = scriptId;
        script.crossOrigin = "";
        script.src = `${host}${manifest["files"]["main.js"]}`;
        script.onload = this.renderMicroFrontend;
        document.head.appendChild(script);
        // loading styles
        const urlStyle = `${host}${manifest["files"]["main.css"]}`;
        const style = document.createElement("link");
        style.id = styleId;
        style.crossOrigin = "";
        style.rel = "stylesheet";
        style.href = urlStyle;
        document.head.appendChild(style);
      })
      .catch((err) => {
        this.setState({ loading: false, AppLoaded: false });
      });
  }

  componentWillUnmount() {
    const { name, window } = this.props;
    if (window[`unmount${name}`]) window[`unmount${name}`](`${name}-container`);
  }

  renderMicroFrontend = () => {
    const { name, window, history } = this.props;
    this.setState({ loading: false });
    window[`render${name}`](`${name}-container`, history);
  };

  render() {
    if (this.state.AppLoaded) {
      return (
        <main style={{ height: "100%" }} id={`${this.props.name}-container`}>
          <Spin
            size="large"
            className="loading"
            spinning={this.state.loading}
          ></Spin>
        </main>
      );
    } else {
      return <AppNotFound />;
    }
  }
}

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
