import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * Wraps two components and flips between the two states on hover.
 * Both states should have the exact same size!
 */
const FlipContent = ({ classNames, content, activeContent }) => {
  const [touched, setTouched] = useState(false);

  return (
    <span
      className={cn('hpl2-FlipContent', { touched }, classNames)}
      onTouchStart={() => setTouched(true)}
    >
      <span className="hpl2-FlipContent__content">{content}</span>
      <span className="hpl2-FlipContent__activeContent">{activeContent}</span>
    </span>
  );
};

FlipContent.displayName = 'FlipContent';
FlipContent.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Content shown when not hovered. */
  content: PropTypes.node.isRequired,
  /** Content to flip to when hovered. */
  activeContent: PropTypes.node.isRequired
};

export default FlipContent;
