import React, { Component } from "react";
import { Checkbox } from "antd";
import './FeatureToggle.scss';
import { I18nProvider } from '../../../i18n';
import translate from '../../../i18n/translate';
import { getUserLangPreference } from "../../../i18n/utils";
import Table from '../../Table'
import {getFeatureToggles, updateFeatureToggle} from '../../../Utils/api-handler'

class FeatureToggle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            featureToggleData: [],
            isEnabled: false,
            checked: false,
            isLoading: false
        }
    }
    async componentDidMount() {
        this.getFeatureTable();
    }

    async getFeatureTable() {
        this.setState({ isLoading: true });
        const response = await getFeatureToggles();
        if(response) {
            let featureData = response.data;
            this.setState({ featureToggleData: featureData });
        }
        this.setState({ isLoading: false });
    }


    isEnabledOnChange = async(e, data) => {
        this.setState({ isLoading: true });
        data.isenabled = e;
        await updateFeatureToggle(data);
        this.setState({ isLoading: false });
        await this.getFeatureTable();
    }

    getFeatureColumns() {
        const columns = [
            {
                title: 
                <I18nProvider locale={getUserLangPreference()}>
                    <span className="tb-header-lbl-first">{translate("Settings.Feature")}</span>
                </I18nProvider>,
                dataIndex: 'feature',
                key: 'feature',
                align: 'left',
                width: 500,
                render: (feature) => (
                    <React.Fragment>
                        <p className="first-column">
                        {feature}
                        </p>

                    </React.Fragment>
                )
            },
            {
                title: <span className="tb-header-lbl-row">{translate("Settings.Description")}</span>,
                dataIndex: 'description',
                key: 'description',
                align: 'left',
                width: 500,
                render: (description) => (
                    <React.Fragment>
                        <div style={{ display: "flex" }}>
                            <p style={{ textAlign: "left" }}>
                                {description}
                            </p>
                        </div>
                    </React.Fragment>
                )
            },
            {
                title: <span className="tb-header-lbl-row">{translate("Settings.Enabled")}</span>,
                dataIndex: 'isenabled',
                key: 'toggle_feature_id',
                align: 'center',
                width: 200,
                render: (isenabled, data) => (
                    <React.Fragment>
                        <div style={{ display: "flex" }}>
                            <Checkbox
                            style={{ marginLeft: 20 }}
                                checked={isenabled}
                                onChange={e => this.isEnabledOnChange(e.target.checked, data)}
                            >
                            </Checkbox>
                        </div>
                    </React.Fragment>
                )
            }
        ]
        return columns;
    }

    getDataSorce() {
        const dataSource = [
            {
                key: '1',
                feature: 'New Feature',
                description: 'Short Description',
                enabled: "Yes",
            },
        ];
        return dataSource;
    }

    render() {
        return (
            <div className="table-container">
                <Table columns={this.getFeatureColumns()} data={this.state.featureToggleData} loading={this.state.isLoading} scroll={{ y: 'calc(100vh - 500px)' }}
/>
            </div>
        )
    }

};
export default FeatureToggle;