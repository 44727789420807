import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

import CircularButton from '../CircularButton';
import Icon from '../Icon';

import * as icons from '../../asset/icon';

const sanitizeNumberInput = value =>
  value.replace(/[^\d]/g, '').replace(/^0+(.)/, '$1');

const sanitizeIntegerValue = value => {
  const val = parseInt(sanitizeNumberInput(value), 10);

  if (Number.isNaN(val)) {
    return 0;
  }

  return val;
};

/**
 * A number input field for positive integers with buttons for easy incrementation and decrementation of values.
 */
const NumberCounterField = ({
  classNames,
  id,
  value,
  onChange = noop,
  onBlur,
  placeholder,
  disabled = false,
  error = false
}) => {
  const integerValue = sanitizeIntegerValue(value);

  const handleChange = event => {
    const val = event.currentTarget.value;
    onChange(sanitizeNumberInput(val));
  };

  const incrementValue = () => {
    onChange(`${integerValue + 1}`);
  };

  const decrementValue = () => {
    onChange(`${Math.max(0, integerValue - 1)}`);
  };

  return (
    <div
      className={cn('hpl2-NumberCounterField', { disabled, error }, classNames)}
    >
      <CircularButton
        ghost
        size={200}
        icon={<Icon source={icons.Remove} />}
        onClick={decrementValue}
        disabled={disabled || integerValue === 0}
      />
      <div className="hpl2-NumberCounterField__inputContainer">
        <input
          type="text"
          className="hpl2-NumberCounterField__input"
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
        />
        <div className="hpl2-NumberCounterField__box" aria-hidden />
      </div>
      <CircularButton
        ghost
        size={200}
        icon={<Icon source={icons.Add} />}
        onClick={incrementValue}
        disabled={disabled}
      />
    </div>
  );
};

NumberCounterField.displayName = 'NumberCounterField';
NumberCounterField.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  /** Call signature: (value, event) => {} */
  onChange: PropTypes.func,
  /** Call signature: (event) => {} */
  onBlur: PropTypes.func,
  /** Id to set on the HTML input field. */
  id: PropTypes.string,
  /** Signal validation error. */
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

export default NumberCounterField;
