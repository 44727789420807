import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Input, message } from "antd";
import Table from '../../../Table'
import './viewStatistics.scss';
import { FilterFilled, LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "react-bootstrap";
import StatisticsFilter from "../StatisticsFilterContainer";
import moment from 'moment';

class ViewStatistics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isViewContent: false,
            isClicked: "",
            viewStatistics: [],
            showFilterModal: false,
            groupBy: ["app_name"],
            where:["External"],
            zeroHits: false,
            searchTerm: "",
            filterTableData: null,
            getLastTwoPlusCurrentMonth: [],
            currentMonth:"",
            duration: {},
            paginationInfo: {
                current: 1,
                pageSize: 10,
            }
        }
    }
    onCancel = () => {
        this.setState({ showFilterModal: false });
    }
    onFilterClick = () => {
        this.setState({ showFilterModal: true });
    }
    handleFilterChange = async (value1, value2, value3, value4) => {
       await this.setState({ groupBy: value1, where: value2, zeroHits: value3, duration: value4, showFilterModal: false });
        this.getDataList();
    };

    handleReset = async () => {
        let duration = { fromMonth: this.state.currentMonth, toMonth: this.state.currentMonth };
       await this.setState({ groupBy:["app_name"], where: ["External"], zeroHits: false, duration: duration, showFilterModal: false, });
        this.getDataList();
    }

    onSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value }, () => {
          const filterTableData = this.state.viewStatistics.filter((o) =>
            Object.keys(o).some((k) =>
              String(o[k])
                .toLowerCase()
                .includes(this.state.searchTerm.toLowerCase())
            )
          );
          if (this.state.searchTerm) {
            this.setState({ filterTableData });
          } else {
            this.setState({ filterTableData: null });
          }
        });
      }

      componentDidMount() {
        this.getDataList();
    }

    componentWillMount = () => {
        this.getLastTwoPlusCurrentMonth();
    }
    
    getLastTwoPlusCurrentMonth = () => {
        let months = [];
        
        for (let i = 0; i <= 2; i++) {
            let momentDate =  moment().subtract(i, "month");
            let format1 = momentDate.format('MMMM');
            let format2 = momentDate.format('MMYYYY');
            months.push(`${format1}-${format2}`);
        }
        let yearAndMonth = moment().format('YYYY-MM');
        this.setState({ getLastTwoPlusCurrentMonth: months, currentMonth: `${yearAndMonth}`,  duration: {fromMonth: `${yearAndMonth}`, toMonth: `${yearAndMonth}`} });
    };

    getDataList() {
        if( new Date(this.state.duration.fromMonth).getTime() > new Date(this.state.duration.toMonth).getTime() ) {
            message.error('Please provide a valid month range in the filter screen.!');
            return;
        }

        this.setState({ isLoading: true });
        const options = {
            headers: { "Content-Type": "application/json" },
        };
        let groupData = {}
        groupData.user = this.state.where;
        groupData.groupBy = this.state.groupBy;
        groupData.duration = this.state.duration;
        if (this.props.selected === "App Search") {
            groupData.zeroHits = this.state.zeroHits;
        }
        if (this.props.selected === "App Click") {
            axios.post("/als-api/get-app-click-statistics", groupData, options)
            .then((response) => {
                if (response) {
                    let statisticsData = response.data;
                    this.setState({ viewStatistics: statisticsData, isLoading: false })
                }
            }).catch(e => console.log(e));
        } else {
            axios.post("/als-api/get-app-search-statistics", groupData, options)
                .then((response) => {
                    if (response) {
                        let statisticsData = response.data;
                        this.setState({ viewStatistics: statisticsData, isLoading: false });
                    }
                });
        }
    }

    getColumns() {
        if (this.props.selected === "App Click") {
            if (this.state.groupBy.includes("user_email")) {
                return [
                    {
                        title: "App Name",
                        key: "app_name",
                        align: "left",
                        dataIndex: "app_name",
                        render: (value, row, index) => {
                            const trueIndex = index + this.state.paginationInfo.pageSize * (this.state.paginationInfo.current - 1);
                            const obj = {
                                children: 
                                     <React.Fragment>
                                        <div style={{ display: "flex" }}>
                                            <p >
                                                {value}
                                            </p>
                                        </div>
                                    </React.Fragment>
                                ,
                                props: {}
                              };
                              if (index >= 1 && value === this.state.viewStatistics[trueIndex - 1].app_name) {
                                obj.props.rowSpan = 0;
                              } else {
                                for (
                                  let i = 0;
                                  trueIndex + i !== this.state.viewStatistics.length &&
                                  value === this.state.viewStatistics[trueIndex + i].app_name;
                                  i += 1
                                ) {
                                  obj.props.rowSpan = i + 1;
                                }
                              }
                              return obj;
                        }
                        // (
                            
                       
                    },
                    {
                        title: "User Email",
                        key: "user_email",
                        align: "left",
                        dataIndex: "user_email",
                        render: (user_email_one) => (
                            <div
                                className="report-link"
                            >
                                {user_email_one}
                            </div>
                        ),
                    },
                    {
                        title: "Event Count",
                        key: "event_count",
                        align: "right",
                        dataIndex: "event_count",
                        render: (event_count_one) => (
                            <React.Fragment>
                                <div>
                                    <p >
                                        {event_count_one}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                ];
            } else {
                return [
                    {
                        title: "App Name",
                        key: "app_name",
                        align: "left",
                        dataIndex: "app_name",
                        render: (app_name) => (
                            <React.Fragment>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        {app_name}
                                    </p>
                                </div>
                            </React.Fragment>
                        )
                    },
                    {
                        title: "Event Count",
                        key: "event_count",
                        align: "right",
                        dataIndex: "event_count",
                        render: (even_count_two) => (
                            <React.Fragment>
                                <div>
                                    <p >
                                        {even_count_two}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                ];
            }

        } else {
            if (this.state.groupBy.includes("user_email")) {
                return [
                    {
                        title: "User Email",
                        key: "user_email",
                        align: "left",
                        dataIndex: "user_email",
                        render: (user_email_final) => (
                            <div
                                className="report-link"
                            >
                                {user_email_final}
                            </div>
                        ),
                    },
                    {
                        title: "App Name",
                        key: "app_name",
                        align: "left",
                        dataIndex: "app_name",
                        render: (app_name_one) => (
                            <React.Fragment>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        {app_name_one}
                                    </p>
                                </div>
                            </React.Fragment>
                        )
                    },
                    {
                        title: "Search Term",
                        key: "search_term",
                        align: "left",
                        dataIndex: "search_term",
                        render: (search_term_one) => (
                            <React.Fragment>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        {search_term_one}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                    {
                        title: "Search Count",
                        key: "search_count",
                        align: "left",
                        dataIndex: "search_count",
                        render: (search_count_one) => (
                            <React.Fragment>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        {search_count_one}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                    {
                        title: "Event Count",
                        key: "event_count",
                        align: "right",
                        dataIndex: "event_count",
                        render: (event_count_third) => (
                            <React.Fragment>
                                <div>
                                    <p >
                                        {event_count_third}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                ];
            } else {
                return [
                    {
                        title: "App Name",
                        key: "app_name",
                        align: "right",
                        dataIndex: "app_name",
                        render: (app_name_final) => (
                            <React.Fragment>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        {app_name_final}
                                    </p>
                                </div>
                            </React.Fragment>
                        )
                    },
                    {
                        title: "Search Term",
                        key: "search_term",
                        align: "left",
                        dataIndex: "search_term",
                        render: (search_term_two) => (
                            <React.Fragment>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        {search_term_two}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                    {
                        title: "Search Count",
                        key: "search_count",
                        align: "left",
                        dataIndex: "search_count",
                        render: (search_count_two) => (
                            <React.Fragment>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        {search_count_two}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                    {
                        title: "Event Count",
                        key: "event_count",
                        align: "right",
                        dataIndex: "event_count",
                        render: (event_count_final) => (
                            <React.Fragment>
                                <div>
                                    <p >
                                        {event_count_final}
                                    </p>
                                </div>
                            </React.Fragment>
                        ),
                    },
                ];
            }

        };
    };

    render(props) {
        const { backStatistics } = this.props;
        const { viewStatistics, showFilterModal, isChecked, filterTableData, duration, currentMonth, getLastTwoPlusCurrentMonth } = this.state;
        return (
            <>
                <Row gutter={20} className="search-container" style={{ marginTop: 20 }}>
                <div className="flex-layout">
                    <Col>
                    <button className="statistics-back-btn" onClick={backStatistics}>
                        <LeftOutlined/>
                    </button>
                    </Col>
                    <Col flex="auto">
                        <Input
                            placeholder="Search"
                            className="input-search"
                            prefix={<SearchOutlined />}
                            onChange={this.onSearchChange}
                        />
                    </Col>
                    <Col>
                        <Button
                            onClick={this.onFilterClick}
                            className="filter-button"
                        >
                            <FilterFilled /> Filter
                        </Button>
                    </Col>
                    </div>
                </Row>
                <Row className="table-container">
                    <Table columns={this.getColumns()} data={filterTableData ? filterTableData : viewStatistics} loading={this.state.isLoading} scroll={{ y: 'calc(100vh - 500px)' }}
 />
                </Row>
                <StatisticsFilter
                    onCancel={this.onCancel}
                    showModal={showFilterModal}
                    handleFilterChange={this.handleFilterChange}
                    isChecked={isChecked}
                    handleReset={this.handleReset}
                    selected={this.props.selected}
                    duration={duration}
                    currentMonth={currentMonth}
                    getLastTwoPlusCurrentMonth={getLastTwoPlusCurrentMonth}
                />
            </>
        )
    }

};
export default ViewStatistics;