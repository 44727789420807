import React, { Component } from "react";
import StatisticsFilterComponent from "./statisticsFilterComponent"

class statisticsFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groupBy: ["app_name"],
      where: ["External"],
      zeroHits: false,
      getMonths: [],
      duration: {},
      currentMonth:""
    }
  }

  hideModal = () => {
    this.props.onCancel();
  };

  handleChange = (value1, value2, value3, value4) => {
    this.setState({ groupBy: value1,  where: value2, zeroHits: value3, duration: value4 });
    this.props.handleFilterChange(value1, value2, value3, value4);
  };

  handleFilterReset = () => {
    this.props.handleReset();
  };

  render() {
    return (
      <StatisticsFilterComponent
        hideModal={this.hideModal}
        visible={this.props.showModal}
        handleChange={this.handleChange}
        groupBy={this.state.groupBy}
        where={this.state.where}
        zeroHits={this.state.zeroHits}
        handleReset={this.handleFilterReset}
        selected={this.props.selected}
        getMonths={this.props.getLastTwoPlusCurrentMonth}
        duration={this.props.duration}
        currentMonth={this.props.currentMonth}
      />
    );
  }
}

export default statisticsFilter;
