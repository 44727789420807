import axios from "axios";
const header = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};
let apiEndPoint = `platform`;

export const getFeatureToggles = async () => {
    return await axios.get(`/${apiEndPoint}/feature-toggle`, header);
}

export const updateFeatureToggle = async (data) => {
    return await axios.post(`/${apiEndPoint}/update-feature`, data, header);
}