import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Transition } from 'react-transition-group';

import cn from '../../lib/class-name';

// This has to be synced with the transition timeouts in the styles
const TRANSITION_TIMEOUT = 0.3 * 1000;

/**
 * The modal portal positions a modal or overlay centered over the page
 * and handles enter and exit transitions. But it does not handle the isOpen state.
 * If you don't want to handle the state yourself you can instead use the ModalHandler.
 */
const ModalPortal = ({
  classNames,
  children,
  isOpen,
  onClose,
  full = false
}) => (
  <Transition
    in={isOpen}
    appear
    timeout={TRANSITION_TIMEOUT}
    mountOnEnter
    unmountOnExit
    onEnter={node => node && node.scrollTop} // Force first paint before going into entering state!
  >
    {state => (
      <Portal>
        <div
          className={cn(
            'hpl2-ModalPortal',
            { [state]: !!state, closable: !!onClose, full },
            classNames
          )}
        >
          <div className="hpl2-ModalPortal__content">{children}</div>
          <div
            className="hpl2-ModalPortal__background"
            aria-hidden
            onClick={onClose}
          />
        </div>
      </Portal>
    )}
  </Transition>
);

ModalPortal.displayName = 'ModalPortal';
ModalPortal.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Instance of a Modal or Overlay. */
  children: PropTypes.element.isRequired,
  /** Whether the modal is visible. Do not just remove the portal from the DOM otherwise animations will not work. */
  isOpen: PropTypes.bool,
  /**
   * Called when user clicks on the background.
   * If the onClose handler is not provided
   * then the background is not clickable.
   * Call signature: (event) => {} */
  onClose: PropTypes.func,
  /** Activate if the modal content occupies the whole viewport. */
  full: PropTypes.bool
};

export default ModalPortal;
