import React from "react";
import { Modal, Button } from "antd";
import "./removemodal.scss";

const RemoveModal = (props) => {
  const { visible, onRemove, onCancel, msg } = props;
  return (
    <Modal
      centered
      visible={visible}
      onOk={onRemove}
      onCancel={onCancel}
      //className="remove-modal"
      footer={[
        <Button key="back" className="cancel-btn" onClick={onCancel}>
          <span style={{ color: "#ff7000" }} className="btn-lbl">
            Cancel
          </span>
        </Button>,
        <Button key="submit" className="remove-btn" onClick={onRemove}>
          <span style={{ color: "#ffffff" }} className="btn-lbl">
            Remove
          </span>
        </Button>,
      ]}
    >
      <p className="modal-msg">{msg}</p>
    </Modal>
  );
};

export default RemoveModal;
