import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./ReportButton.scss";
import ReportIcon from "../ReportIcon/Reports icon.svg"
import ReportIconHover from "../ReportIcon/Reports icon rollover.svg"

const ReportButton = (props) => {
  const {
    appName,
    navPath,
  } = props;
  const [ reportImg, setReportImg] = useState(ReportIcon);
  return (
    <NavLink to={navPath}>
      <button
        className="report-btn"
      >
        <img
          className="report-icon"
          alt="Report Icon"
          src={reportImg}
          onMouseOver={() => setReportImg(ReportIconHover)}
          onMouseOut={() => setReportImg(ReportIcon)}
        ></img>
        <p
          className="report-label"
          style={{
            maxWidth: "119px",
          }}
        >
          {appName}
        </p>
      </button>
    </NavLink>
  );
};

export default ReportButton;