import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * A Paragraph component which also supports "strong".
 */
const Paragraph = ({ classNames, children }) => (
  <p className={cn('hpl2-Paragraph', {}, classNames)}>{children}</p>
);

Paragraph.displayName = 'Paragraph';
Paragraph.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
};

export default Paragraph;
