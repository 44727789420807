import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

/**
 * A navigation item to be used within Footer.
 */
const FooterLink = ({
  classNames,
  label,
  href = '#',
  target,
  rel,
  onClick = noop,
  disabled
}) => {
  const handleClick = event => {
    event.preventDefault();

    if (!disabled) {
      onClick(event, href);
    }
  };

  return (
    <a
      className={cn('hpl2-FooterLink', { disabled }, classNames)}
      href={href}
      target={target}
      rel={rel}
      onClick={handleClick}
    >
      {label}
    </a>
  );
};

FooterLink.displayName = 'FooterLink';
FooterLink.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Label string. */
  label: PropTypes.string.isRequired,
  /** Call signature: (event, href) => {} */
  onClick: PropTypes.func,
  /** Href of the link. */
  href: PropTypes.string,
  /** Optional link target. */
  target: PropTypes.string,
  /** Optional link rel. */
  rel: PropTypes.string,
  /** Disabled state modifier. */
  disabled: PropTypes.bool
};

export default FooterLink;
