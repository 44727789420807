import React from "react";
import { Redirect, Route } from "react-router-dom";
import Utils from "../Utils/Utils";

function PrivateRoute({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        Utils.isLoggedIn() ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
}
export default PrivateRoute;
