import React, { Component } from "react";
import { Row, Col, Avatar } from "antd";
import { CloseOutlined } from "@ant-design/icons";

class UserCell extends Component {
  state = {
    hover: "none",
  };
  handleMouseEnter = () => {
    this.setState({ hover: "block" });
  };
  handleMouseLeave = () => {
    this.setState({ hover: "none" });
  };

  render() {
    const { name, email, deleteUser } = this.props;
    return (
      <Row
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="flex-layout">
        <Col>
          <CloseOutlined
            className="delete-icon"
            onClick={() => {
              deleteUser(email);
            }}
            style={{ display: this.state.hover }}
          />
        </Col>
        <Col style={{ paddingLeft: "5px" }}>
          <Avatar className="avatar" shape="square" size={60}>
            <span className="cap-letter">{name[0]}</span>
          </Avatar>
        </Col>
        <Col style={{ height: "40px", marginLeft: "8px" }}>
          <Row>
            <span className="tab-label">{name}</span>
          </Row>
          <Row>
            <span className="role-email">{email}</span>
          </Row>
        </Col>
        </div>
      </Row>
    );
  }
}

export default UserCell;
