import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

const getTransform = (position, shift) => {
  switch (position) {
    case 'left':
      return `translateY(${-shift}px)`;
    case 'right':
      return `translateY(${-shift}px)`;
    case 'bottom':
      return `translateX(${-shift}px)`;
    default:
      return `translateX(${-shift}px)`;
  }
};

/**
 *  The balloon internally used by the tooltip.
 */
const TooltipBalloon = ({
  classNames,
  children,
  position = 'top',
  shift = 0
}) => (
  <div
    className={cn('hpl2-TooltipBalloon', { [position]: position }, classNames)}
  >
    {children}
    <div
      className="hpl2-TooltipBalloon__arrow"
      style={{
        transform: getTransform(position, shift)
      }}
    />
  </div>
);

TooltipBalloon.displayName = 'TooltipBalloon';
TooltipBalloon.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The content of the `Balloon`. */
  children: PropTypes.node.isRequired,
  /** Position of the tooltip. */
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  /** Shifts the arrow of the ballon by the given number of pixels. Used when ballon has not enough space in the viewport. */
  shift: PropTypes.number
};

export default TooltipBalloon;
