import axios from "axios";
import {logSecretKey} from '../../config/credentials';

const options = {
  headers: {
    'secretkey': process.env.REACT_APP_LOG_SECRET_KEY || logSecretKey
  }
};

export function collectLogs(appName, userEmail, actionName, actionMessage) {
    setTimeout(pushAuditLog(appName, userEmail, actionName, actionMessage), 1000);
}

export function pushCustomClickEvents(text) {
    window.dataLayer.push({
        event: 'custom_click',
        custom_click: {
            text: text,
            user: JSON.parse(localStorage.getItem("user")).user_info.email
        }
    });
    return true;
}

function pushAuditLog(appName, userEmail, actionName, actionMessage) {
    return axios.post("/als-api/collect-click-event-logs", {
        userEmail: userEmail,
        appName: appName,
        actionName: actionName,
        actionMessage: actionMessage
    }, options)
}
