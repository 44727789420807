import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';

import cn from '../../lib/class-name';

/**
 * Cookie consent banner
 */
const CookieConsent = ({ classNames, children, actions }) => (
  <Portal>
    <section className={cn('hpl2-CookieConsent', {}, classNames)}>
      <div className="hpl2-CookieConsent__content">{children}</div>
      {actions && (
        <footer className="hpl2-CookieConsent__footer">{actions}</footer>
      )}
    </section>
  </Portal>
);

CookieConsent.displayName = 'CookieConsent';
CookieConsent.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Content of the banner. */
  children: PropTypes.node.isRequired,
  /** Button instances as major actions for this banner. */
  actions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default CookieConsent;
