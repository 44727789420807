import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * Use `TableCell` only within a `TableRow`.
 */
const TableCell = ({
  classNames,
  children,
  textAlign = 'left',
  computedWidth
}) => (
  <div
    className={cn(
      'hpl2-TableCell',
      {
        [`textAlign-${textAlign}`]: true
      },
      classNames
    )}
    style={{
      width: computedWidth
    }}
  >
    {children}
  </div>
);

TableCell.displayName = 'TableCell';
TableCell.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The content of a cell. */
  children: PropTypes.node.isRequired,
  /** Aligns the text left, center or right. */
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  /** The computed width of the cell (injected by parent). */
  computedWidth: PropTypes.string
};

export default TableCell;
