import React from "react";
import { createBrowserHistory } from "history";
import MainApps from "./MainAppsComponent";
import Reports from "../Reports";
import Others from "../Others";
import AllApps from "./AllAppsComponent";
import AppNotFound from "../AppNotFound";
import "./styles.scss";
createBrowserHistory();
export default class Apps extends React.Component {
  constructor(props) {
    super(props);
    this.getSelectedComponent = this.getSelectedComponent.bind(this);
  }

  getSelectedComponent(pathName) {
    if (pathName === "/apps") return <MainApps />;
    if (pathName === "/reports") return <Reports />;
    if (pathName === "/others") return <Others />;
    return <AppNotFound />;
  }
  render() {
    if (this.props.location.pathname === "/home") {
      return <AllApps />;
    } else {
      return (
        <React.Fragment>
          <div className="ant-row land-height">
          <div className="ant-col ant-col-xs-24 ant-col-xl-24">
            <div className="app-landingpage-card ">
          <div className="appIcon">
            {this.getSelectedComponent(this.props.location.pathname)}
          </div>
          </div>
          </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
