import React, { Component } from "react";
import { Button } from "antd";
import "./appNotFound.scss";
import { NavLink } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ReactComponent as ReactLogo } from "./404 Illustration.svg";
createBrowserHistory();
class AppNotFound extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="page-not-found-container">
          <ReactLogo />
          <h2 className="oops">Oops!</h2>
          <h4 className="sentence">
            The page you are looking for cannot be found
          </h4>
          <NavLink to="/home">
            <Button className="back-to-apps-btn">
              <span className="back-to-apps-btn-label">Back to All Apps</span>
            </Button>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default AppNotFound;
