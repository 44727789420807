import React, { Component } from "react";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

class ProjectCell extends Component {
  state = {
    hover: "none",
  };
  handleMouseEnter = () => {
    this.setState({ hover: "block" });
  };
  handleMouseLeave = () => {
    this.setState({ hover: "none" });
  };

  render() {
    const { data, deleteApp } = this.props;
    return (
      <Row
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={{ display: "inline-flex" }}
      >
        <Col>
          <CloseOutlined
            className="delete-icon"
            onClick={() => {
              deleteApp(data);
            }}
            style={{ display: this.state.hover }}
          />
        </Col>
        <Col style={{ paddingLeft: "5px" }}>
          <img
            style={{ height: "32px" }}
            src={require(`../../../domain/accessWindow/icons/${data.project_name}.svg`)}
            alt={data.project_name}
          />
        </Col>
      </Row>
    );
  }
}

export default ProjectCell;
