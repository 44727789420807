import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * General icon component to render one of the available icons in the pattern library.
 * All icons are rendered with this component.
 */
const Icon = ({ classNames, block = false, source, title }) => (
  <svg
    className={cn('hpl2-Icon', { block }, classNames)}
    role={title ? 'img' : 'presentation'}
    pointerEvents="none"
  >
    <use xlinkHref={`#${source.id}`} />
    {title && <title>{title}</title>}
  </svg>
);

Icon.displayName = 'Icon';
Icon.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether the component should be rendered as a CSS block element.
   * Otherwise its rendered as an inline element.
   */
  block: PropTypes.bool,
  /** The icon source itself. Use any of the icons contained in the pattern library. */
  source: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  /**
   * Setting a title is necessary for accessibility purposes
   * in cases where the icon is used standalone without any label nearby.
   */
  title: PropTypes.string
};

export default Icon;
