import React, { Component } from "react";

class BerechnungsMatrixSonderwerkzeug extends Component {
  render() {
    return (
      <React.Fragment>
        <iframe
          width="98%"
          height="98%"
          title="BerechnungsMatrixSonderwerkzeug"
          src="https://apps.powerapps.com/play/e3556c6b-3de9-4cb4-99f2-985897cb89c6?tenantId=bab8ae91-c774-40ac-9d72-282d0f55f03a&source=email"
        />
      </React.Fragment>
    );
  }
}

export default BerechnungsMatrixSonderwerkzeug;
