import React, { Component } from "react";
import { Row, Col, Divider } from "antd";
import tick from "../icons/Tick.svg";
import "./accessCard.scss";

class CardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_roles: {},
      app_role: "",
      tick: false,
      shadow: false,
    };
  }

  handleOnRoleClick = (e, role) => {
    e.preventDefault();

    if (this.state.app_role === role) {
      this.setState({ app_role: "", tick: false, shadow: false }, () => {
        this.props.modifyAppRoles(this.props.appName, this.state.app_role);
      });
    } else {
      this.setState({ app_role: role, tick: true, shadow: true }, () => {
        this.props.modifyAppRoles(this.props.appName, this.state.app_role);
      });
    }
  };

  componentDidMount = () => {
    const { userAppRoles, appRoles } = this.props;
    for (let i = 0; i < appRoles.length; i++) {
      if (userAppRoles.length) {
        for (let j = 0; j < userAppRoles.length; j++) {
          if ((userAppRoles[j].permission_name = "requested")) {
            this.setState({ tick: true });
          }
          if (appRoles[i].role_name === userAppRoles[j].role_name) {
            this.setState({ app_role: userAppRoles[j].role_name });
          }
        }
      }
    }
  };

  getAppRoles = (appRoles) => {
    const buttons = ["Admin", "User", "Role3", "Role4", "Approver"];
    return appRoles.map((appRole) => {
      return (
        <React.Fragment>
          <Col
            key={appRole.role_name}
            className={`access-button ${
              this.state.app_role === appRole.role_name
                ? "access-button-selected"
                : ""
            }`}
            onClick={(e) => this.handleOnRoleClick(e, appRole.role_name)}
          >
            <span
              className={`access-button-label ${
                this.state.app_role === appRole.role_name
                  ? "access-button-label-selected"
                  : ""
              }`}
            >
              {buttons.map((button_name) => {
                const lower_case_label = button_name.toLowerCase();
                if (appRole.role_name.endsWith(lower_case_label)) {
                  return button_name;
                } else {
                  return "";
                }
              })}
            </span>
          </Col>
          {appRoles.indexOf(appRole) < appRoles.length - 1 ? (
            <Col>
              <Divider className="access-button-divder" type="vertical" />
            </Col>
          ) : null}
        </React.Fragment>
      );
    });
  };
  render() {
    const { appName, desc, appRoles, shadowColor } = this.props;

    const { shadow } = this.state;

    return (
      <React.Fragment>
        <div
          style={{
            boxShadow: shadow ? `0 0 29px 0 ${shadowColor}` : "",
          }}
          className={`access-card ${shadow ? "access-card-shadow" : ""}`}
        >
          <Row className="access-card-title">
            <Col style={{ margin: "auto" }}>
              <img alt={appName} src={require(`../icons/${appName}.svg`)} />
            </Col>
            {this.state.tick ? (
              <img src={tick} alt="tick" className="access-tick" />
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col className="access-card-desc">
              <span className="access-info">{desc}</span>
            </Col>
          </Row>
          <Row>
            <Col flex="auto">
              <Divider className="divider" />
            </Col>
          </Row>
          <Row style={{ marginTop: "-10px", marginBottom: "10px" }}>
            <Col style={{ margin: "auto" }}>
              <span className="access-type">Access Type</span>
            </Col>
          </Row>
          <Row>
            <div style={{ display: "flex" }} className="access-list">
              {this.getAppRoles(appRoles)}
            </div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default CardComponent;
