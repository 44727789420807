import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import defer from 'lodash/defer';

import * as icons from '../../asset/icon';
import cn from '../../lib/class-name';

import Icon from '../Icon';

/**
 * A suggestion list used for SearchField suggestions.
 */
const SuggestionFlyout = ({
  classNames,
  children,
  onChange = noop,
  onSubmit = noop,
  onBlur = noop,
  value,
  reverse = false,
  initialCaretPosition
}) => {
  const triggerChange = (nextValue, event) => {
    onChange(nextValue, event);
  };

  const clearClick = event => triggerChange('', event);

  const handleChange = event => {
    const val = event.currentTarget.value;
    triggerChange(val, event);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    defer(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          initialCaretPosition,
          initialCaretPosition
        );
      }
    });
  }, []);

  return (
    <div className={cn('hpl2-SuggestionFlyout', { reverse }, classNames)}>
      <div className="hpl2-SuggestionFlyout__search">
        <Icon source={icons.Search} />
        <input
          type="text"
          className="hpl2-SuggestionFlyout__input"
          ref={inputRef}
          onBlur={onBlur}
          value={value}
          onChange={handleChange}
        />
        <button
          type="button"
          onClick={clearClick}
          className="hpl2-SuggestionFlyout__clear"
        >
          <Icon source={icons.Cancel} />
        </button>
      </div>
      <ul className="hpl2-SuggestionFlyout__suggestionList">
        {React.Children.map(children, (child, index) => (
          <li key={index} className="hpl2-SuggestionFlyout__suggestionItem">
            {React.cloneElement(child, {
              onClick: (itemValue, event) => {
                triggerChange(itemValue, event);
                onSubmit(itemValue, event, child);
                (child.props.onClick || noop)(itemValue, event);
              }
            })}
          </li>
        ))}
      </ul>
    </div>
  );
};

SuggestionFlyout.displayName = 'SuggestionFlyout';
SuggestionFlyout.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  /** Call signature: (value, event) => {} */
  onChange: PropTypes.func,
  /** Call signature: (event) => {} */
  onBlur: PropTypes.func,
  children: PropTypes.node,
  /** Move the input to the bottom of the flyout. */
  reverse: PropTypes.bool,
  /** Submit the search: : (event) => {} */
  onSubmit: PropTypes.func,
  /** The inital cursor position of the input. */
  initialCaretPosition: PropTypes.number
};

export default SuggestionFlyout;
