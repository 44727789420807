import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import cn from '../../lib/class-name';

import * as icons from '../../asset/icon';
import Icon from '../Icon';

/**
 * A checkbox input field.
 *
 * A ref created with "React.createRef()" can be passed to the component. It makes the inputs "focus()" function accessible.
 */
class CheckboxField extends React.Component {
  inputRef = React.createRef();

  inputId = uniqueId('checkbox-');

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  render() {
    const {
      checked,
      classNames,
      disabled = false,
      id = this.inputId,
      label,
      name,
      onBlur = noop,
      onChange = noop,
      partial,
      value
    } = this.props;

    return (
      <label
        htmlFor={id}
        role="checkbox"
        className={cn(
          'hpl2-CheckboxField',
          {
            default: !checked && !partial, // Used for explicitness in CSS only
            checked: checked && !partial,
            partial: checked && partial,
            noLabel: !label
          },
          classNames
        )}
        aria-checked={checked}
        aria-disabled={disabled}
      >
        {checked && !partial && <Icon source={icons.Check} />}
        <input
          ref={this.inputRef}
          className="hpl2-CheckboxField__input"
          type="checkbox"
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={event => onChange(!checked, event)}
          onBlur={onBlur}
        />
        {label && <span className="hpl2-CheckboxField__label">{label}</span>}
      </label>
    );
  }
}

CheckboxField.displayName = 'CheckboxField';
CheckboxField.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** If no id is provided an id is generated automatically. */
  id: PropTypes.string,
  /** Optional label string */
  label: PropTypes.string,
  /** Input field name */
  name: PropTypes.string,
  /** Input field value */
  value: PropTypes.string,
  /** Element checked state */
  checked: PropTypes.bool,
  /** Modifier for semi-filled state */
  partial: PropTypes.bool,
  /** Modifier for disabled state */
  disabled: PropTypes.bool,
  /** Call signature: (value, event) => {} */
  onChange: PropTypes.func,
  /** Call signature: (event) => {} */
  onBlur: PropTypes.func
};

export default CheckboxField;
