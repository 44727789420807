import axios from "../../axios";

const options = {
  headers: { "Content-Type": "application/json" },
};

export function insertRequestedRecord(final_request_app_roles) {
  var encodedURI = window.encodeURI("/platform/request-access");
  return axios
    .post(encodedURI, { data: final_request_app_roles }, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function getRequestedUsersAndRoles() {
  var encodedURI = window.encodeURI("/platform/user-role-requested");
  return axios
    .get(encodedURI, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function getRequestedPoUsersAndRoles(projectList) {
  const data = {
    headers: { "Content-Type": "application/json" },
    params: [projectList]
  };
  var encodedURI = window.encodeURI("/platform/user-role-po-requested");
  return axios
    .get(encodedURI, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function getDeniedUsersAndRoles() {
  var encodedURI = window.encodeURI("/platform/user-role-denied");
  return axios
    .get(encodedURI, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function getDeniedPoUsersAndRoles(projectList) {
  const data = {
    headers: { "Content-Type": "application/json" },
    params: [projectList]
  };
  var encodedURI = window.encodeURI("/platform/user-role-po-denied");
  return axios
    .get(encodedURI, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function getAllUsersAndRoles() {
  var encodedURI = window.encodeURI("/platform/user-roles");
  return axios
    .get(encodedURI, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function getAllPoUsersAndRoles(projectList) {
  const data = {
    headers: { "Content-Type": "application/json" },
    params: [projectList]
  };
  var encodedURI = window.encodeURI("/platform/user-po-roles");
  return axios
    .get(encodedURI, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function appRoles() {
  var encodedURI = window.encodeURI("/platform/app-roles");
  return axios
    .get(encodedURI, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function appScopes() {
  var encodedURI = window.encodeURI("/platform/app-scopes");
  return axios
    .get(encodedURI, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function updateUserRole(userData) {
  var encodedURI = window.encodeURI("/platform/auth-users-roles");
  return axios
    .post(encodedURI, { data: userData }, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return null;
    });
}
export function deleteUserRole(userData) {
  var encodedURI = window.encodeURI("/platform/delete-users-roles");
  return axios
    .post(encodedURI, { data: userData }, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return null;
    });
}
export function modifyUserRole(user) {
  var encodedURI = window.encodeURI("/platform/modify-role");
  return axios
    .post(encodedURI, { data: user }, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function modifyUserScopes(user) {
  var encodedURI = window.encodeURI("/platform/modify-scopes");
  return axios
    .post(encodedURI, { data: user }, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
export function deleteUser(user) {
  var encodedURI = window.encodeURI("/platform/delete-user");
  return axios
    .post(encodedURI, { user_email: user }, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function deleteUserApp(user) {
  var encodedURI = window.encodeURI("/platform/delete-app");
  return axios
    .post(encodedURI, { data: user }, options)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
