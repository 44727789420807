import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import noop from 'lodash/noop';

import * as icons from '../../asset/icon';
import cn from '../../lib/class-name';

import Icon from '../Icon';
import PositioningPortalWithState from '../PositioningPortalWithState';
import SuggestionFlyout from './SuggestionFlyout';

const KEYCODES = {
  ENTER: 13
};

const positioningStrategy = (parentRect, portalRect) => {
  const scrollX = global.scrollX || global.pageXOffset;
  const scrollY = global.scrollY || global.pageYOffset;
  const body = global.document.documentElement || global.document.body;

  const openAbove =
    parentRect.top + parentRect.height + portalRect.height >
      body.clientHeight && parentRect.top - portalRect.height > 0;

  const top = openAbove
    ? parentRect.top - portalRect.height + scrollY
    : parentRect.top + parentRect.height + scrollY;

  return {
    // Position the flyout in a way that the search fields match
    top: openAbove ? top + 44 : top - 45,
    left: parentRect.left + scrollX,
    position: openAbove ? 'ABOVE' : 'BELOW'
  };
};

/**
 * A text input dedicated for search.
 */
class SearchField extends React.Component {
  static displayName = 'SearchField';

  static propTypes = {
    /** Optional array of CSS utility classes. */
    classNames: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    /** Call signature: (value, event) => {} */
    onChange: PropTypes.func,
    /** Call signature: (event) => {} */
    onBlur: PropTypes.func,
    /** Id to set on the HTML input field. */
    id: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    children: PropTypes.node,
    /** Submit the search: : (event) => {} */
    onSubmit: PropTypes.func,
    /** An annotation shown next to the default search item like `- Your search`. */
    defaultItemAnnotation: PropTypes.string
  };

  static defaultProps = {
    value: ''
  };

  defaultId = uniqueId('hpl2-SearchField-');

  inputRef = React.createRef();

  portalRef = React.createRef();

  componentDidMount() {
    global.document.addEventListener('keydown', this.handleEnter, false);
  }

  componentWillUnmount() {
    global.document.removeEventListener('keydown', this.handleEnter, false);
  }

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  handleEnter = event => {
    const { onSubmit = noop, value } = this.props;
    if (event.keyCode === KEYCODES.ENTER) {
      this.portalRef.current.close();
      onSubmit(value, event);
    }
  };

  handleSubmit = (value, event, child) => {
    const { onSubmit = noop } = this.props;
    onSubmit(value, event, child);
    this.portalRef.current.close();
  };

  handleBlur = event => {
    const { onBlur = noop } = this.props;
    if (!this.portalRef.current.state.isOpen) {
      onBlur(event);
    }
  };

  render() {
    const {
      classNames,
      value,
      onChange = noop,
      onBlur = noop,
      disabled,
      placeholder,
      id = this.defaultId,
      children,
      defaultItemAnnotation,
      ...rest
    } = this.props;

    const isEmpty = value.length === 0;

    const triggerChange = (nextValue, event) => {
      if (nextValue === '' && this.inputRef.current) {
        this.inputRef.current.focus();
      }
      onChange(nextValue, event);
    };

    const clearClick = event => triggerChange('', event);

    return (
      <PositioningPortalWithState
        onClose={() => {
          onBlur();
        }}
        ref={this.portalRef}
        positionStrategy={positioningStrategy}
        portalContent={({ close, relatedWidth, position }) => (
          <div style={{ width: `${relatedWidth}px` }}>
            <SuggestionFlyout
              initialCaretPosition={
                this.inputRef.current ? this.inputRef.current.selectionStart : 0
              }
              defaultItemAnnotation={defaultItemAnnotation}
              onSubmit={this.handleSubmit}
              reverse={position === 'ABOVE'}
              onBlur={this.handleBlur}
              value={value}
              onChange={(val, event) => {
                if (val.length === 0) {
                  close();
                }
                triggerChange(val, event);
              }}
            >
              {children}
            </SuggestionFlyout>
          </div>
        )}
      >
        {({ open, close }) => (
          <div
            className={cn(
              'hpl2-SearchField',
              {
                hasValue: !isEmpty
              },
              classNames
            )}
          >
            <input
              {...rest}
              type="text"
              id={id}
              className="hpl2-SearchField__input"
              ref={this.inputRef}
              onBlur={this.handleBlur}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={event => {
                const val = event.currentTarget.value;
                if (children && val.length > 0) {
                  open();
                }

                if (val.length === 0) {
                  close();
                }
                triggerChange(val, event);
              }}
            />
            <Icon source={icons.Search} />
            <div className="hpl2-SearchField__box" aria-hidden />
            {!isEmpty && !disabled && (
              <button
                type="button"
                onClick={clearClick}
                className="hpl2-SearchField__clear"
              >
                <Icon source={icons.Cancel} />
              </button>
            )}
          </div>
        )}
      </PositioningPortalWithState>
    );
  }
}

export default SearchField;
