import {LOCALES} from '../locales';

export default{
    [LOCALES.GERMAN]:{
        "Sidebar.AllApps": "Alle Apps",
        "Sidebar.Apps": "Apps",
        "Sidebar.Reports":  "Dashboards",
        "Sidebar.Others": "Sonstige",
        "Sidebar.Settings": "Einstellungen",
        "Sidebar.LogOut": "Abmelden",
        "Settings.Settings": "Einstellungen",
        "Settings.Roles": "Nutzerberechtigugen",
        "Settings.DevOpsExt": "Releases",
        "Settings.FeatureToggle": "Feature Toggle",
        "Settings.Admin": "Administrator",
        "Settings.User": "Benutzer",
        "Settings.UserAccessRequest": "Benutzeranfragen",
        "Settings.UserReAccessRequest": "User Re-Access Requests",
        "Settings.ViewUsers": "Alle Nutzer",
        "Settings.UserId": "Nutzer ID",
        "Settings.Application": "Applikation",
        "Settings.AccessRights": "Nutzerberechtigungen",
        "Settings.Scope": "Umfang",
        "Settings.AccessApproval": "Freigabe",
        "Settings.AccessDenied": "Denied Approval",
        "Settings.Date": "Requested Date",
        "Settings.ClientApiName": "Client/API Name",
        "Settings.LatestCodeChange": "Latest Code Change",
        "Settings.LatestRelease": "Latest Release",
        "Settings.Feature": "Merkmal",
        "Settings.Description": "Beschreibung",
        "Settings.Enabled": "Aktiviert",
        "Settings.AppStatistics": "App Statistics",
        "Settings.AppStatus": "App Status",
        "Settings.AppName": "App Name",
        "Settings.Status": "Status",
        "Login.Welcome":"Willkommen auf der Data Science Platform",
        "Login.ButtonText":"Anmelden mit Microsoft",
        // "Carousel.Heading1":"Anmelden",
        // "Carousel.Heading2":"",
        // "Carousel.Heading3":"",
        // "Carousel.Heading4":"",
        // "Carousel.Description1":"",
        // "Carousel.Description2":"",
        // "Carousel.Description3":"",
        // "Carousel.Description4":"" 
    }
}