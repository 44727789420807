import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';

import cn from '../../lib/class-name';
import { getScrollParents } from '../../service/scroll-parent';

const KEYCODES = {
  ESCAPE: 27
};

const renderProps = (element, props) =>
  typeof element === 'function' ? element(props) : element;

export const POSITION = {
  ABOVE_LEFT: 'ABOVE_LEFT',
  ABOVE_RIGHT: 'ABOVE_RIGHT',
  BELOW_LEFT: 'BELOW_LEFT',
  BELOW_RIGHT: 'BELOW_RIGHT'
};

const sanitizeRect = rect => ({
  top: rect.top,
  left: rect.left,
  right: rect.right,
  bottom: rect.bottom,
  width: Math.ceil(rect.width * 10) / 10,
  height: Math.ceil(rect.height * 10) / 10
});

const defaultPositionStrategy = (parentRect, portalRect) => {
  const scrollX = global.scrollX || global.pageXOffset;
  const scrollY = global.scrollY || global.pageYOffset;
  const body = global.document.documentElement || global.document.body;

  // Open the content portal above the child if there is not enough space to the bottom,
  // but if there also isn't enough space at the top, open to the bottom.
  const openAbove =
    parentRect.top + parentRect.height + portalRect.height >
      body.clientHeight && parentRect.top - portalRect.height > 0;

  const top = openAbove
    ? parentRect.top - portalRect.height + scrollY
    : parentRect.top + parentRect.height + scrollY;

  // Open the content portal to the left if there is not enough space at the right,
  // but if there also isn't enough space at the right, open to the left.
  const alignRight =
    parentRect.left + portalRect.width > body.clientWidth &&
    parentRect.left - portalRect.width > 0;

  const left = !alignRight
    ? parentRect.left + scrollX
    : scrollX + parentRect.left - portalRect.width + parentRect.width;

  let position = POSITION.BELOW_RIGHT;
  if (openAbove && left) {
    position = POSITION.ABOVE_LEFT;
  }
  if (openAbove && !left) {
    position = POSITION.ABOVE_RIGHT;
  }
  if (!openAbove && left) {
    position = POSITION.BELOW_LEFT;
  }
  if (!openAbove && !left) {
    position = POSITION.BELOW_RIGHT;
  }

  return {
    top,
    left,
    position
  };
};

/**
 * PositioningPortal handles positioning of generic portal content within the viewport
 * according to a specific positioning strategy wich can also be customized.
 */
class PositioningPortal extends React.Component {
  static displayName = 'PositioningPortal';

  static propTypes = {
    /**
     * A single react element. The portal content is then positioned in relation to this element.
     */
    children: PropTypes.element.isRequired,
    /**
     * The content of the portal. A react element or a renderprop with signature: ({close, isOpen, position, state}) => React.Element
     * close(): This function closes the portal.
     * isOpen: A bool variable, whether the portal is open or closed.
     * position: An enum for the current position of the portal (provided by the positionStrategy).
     * relatedWidth: Width of the element in the children property. Use this width to give the portalContent the same width.
     * transitionStarted(): Call on open if the portal has a transition.
     * transitionEnded(): Call after close when portal transition ended. transitionStarted() has to have been called before.
     */
    portalContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
      .isRequired,
    /** Whether the portal content is shown or not. */
    isOpen: PropTypes.bool,
    /** Called when content portal did open. */
    onOpen: PropTypes.func,
    /** Called when content portal should close. In the stateless case isOpen should then be set to false. */
    onShouldClose: PropTypes.func,
    /**
     * Optional positioning strategy.
     * Call signature: (parentRect, portalRect, props) => { left, top, position }
     */
    positionStrategy: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    onOpen: noop,
    onShouldClose: noop,
    // positionStrategy should be defaultPositionStrategy, but storybook docs would render the whole source code!
    positionStrategy: null
  };

  state = {
    top: null,
    left: null,
    portalRect: null,
    parentRect: null,
    isPositioned: false,
    transitionActive: false,
    isOpen: false,
    scrollParents: [],
    position: null
  };

  portalRef = React.createRef();

  updatePortalPosition = throttle(() => {
    const parentDom = this.getParentDomElement();
    if (
      this.state.isOpen &&
      this.state.isPositioned &&
      this.portalRef.current &&
      parentDom
    ) {
      const parentRect = sanitizeRect(parentDom.getBoundingClientRect());
      const portalRect = sanitizeRect(
        this.portalRef.current.getBoundingClientRect()
      );

      const { top, left, position } = (this.props.positionStrategy ||
        defaultPositionStrategy)(parentRect, portalRect, this.props);

      this.setState({
        left,
        position,
        top,
        parentRect,
        portalRect
      });
    }
  }, 150);

  componentDidMount() {
    global.document.addEventListener('keydown', this.handleKeydown, false);
    global.document.addEventListener(
      'click',
      this.handleOutsideMouseClick,
      false
    );

    global.addEventListener('resize', this.updatePortalPosition, false);
    global.addEventListener(
      'orientationchange',
      this.updatePortalPosition,
      false
    );

    if (this.props.isOpen) {
      this.onOpen();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.onOpen();
      } else {
        this.onClose();
      }
    }
  }

  componentWillUnmount() {
    global.document.removeEventListener('keydown', this.handleKeydown, false);
    global.document.removeEventListener(
      'click',
      this.handleOutsideMouseClick,
      false
    );
    global.removeEventListener('resize', this.updatePortalPosition, false);
    global.removeEventListener(
      'orientationchange',
      this.updatePortalPosition,
      false
    );
    this.updatePortalPosition.cancel();

    // Remove scroll event listeners
    this.state.scrollParents.forEach(node =>
      node.removeEventListener('scroll', this.updatePortalPosition, false)
    );
  }

  close = () => {
    if (this.props.onShouldClose) {
      this.props.onShouldClose();
    }
  };

  transitionStarted = () => {
    this.setState({ transitionActive: true });
  };

  transitionEnded = () => {
    this.setState({ transitionActive: false });
  };

  handleOutsideMouseClick = event => {
    if (!this.state.isOpen) {
      return;
    }

    // Don't use DOM contains here. Instead we marking the native event.
    // This enables us to support nested portals because React bubbles the events
    // according to the React component tree and not the native DOM tree for us.
    if (event['hpl2-PositioningPortal-context'] === this) {
      return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const parentDom = ReactDOM.findDOMNode(this);
    if (parentDom && parentDom.contains(event.target)) {
      return;
    }

    this.close();
  };

  markClickEvent = event => {
    event.nativeEvent['hpl2-PositioningPortal-context'] = this;
  };

  handleKeydown = event => {
    if (event.keyCode === KEYCODES.ESCAPE && this.state.isOpen) {
      this.close();
    }
  };

  onOpen = () => {
    if (!this.state.isOpen) {
      // 1) Prerender portal to get stable portal rect.
      this.preRenderPortal()
        // 2) Position portal with positioning strategy and trigger final render
        .then(this.finalRenderPortal)
        // 3) Communicate that portal has opened
        .then(() => {
          this.props.onOpen();
        });
    }
  };

  onClose = () => {
    if (!this.state.isOpen) {
      return;
    }

    // Remove scroll event listeners
    this.state.scrollParents.forEach(node =>
      node.removeEventListener('scroll', this.updatePortalPosition, false)
    );

    this.setState({
      isOpen: false,
      scrollParents: []
    });
  };

  getParentDomElement = () => {
    // A tricky way to get the first child DOM element of the fragment of this component.
    // Unfortunately there seems to be no way to achieve this with refs.
    // eslint-disable-next-line react/no-find-dom-node
    const parentDom = ReactDOM.findDOMNode(this);

    if (parentDom && parentDom.nodeType === global.Node.ELEMENT_NODE) {
      return parentDom;
    }

    return null;
  };

  preRenderPortal = () =>
    new Promise(resolve => {
      const parentDom = this.getParentDomElement();
      if (parentDom) {
        const parentRect = sanitizeRect(parentDom.getBoundingClientRect());

        let scrollParents = [];
        // Register scroll listener on all scrollable parents to close the portal on scroll
        scrollParents = getScrollParents(parentDom);
        // Remove window from scrollParents
        scrollParents.pop();
        scrollParents.forEach(node =>
          node.addEventListener('scroll', this.updatePortalPosition, false)
        );

        this.setState(
          {
            isOpen: true,
            isPositioned: false,
            transitionActive: false,
            left: 0,
            top: 0,
            position: null,
            parentRect,
            portalRect: null,
            scrollParents
          },
          resolve
        );
      } else {
        resolve();
      }
    });

  finalRenderPortal = () =>
    new Promise(resolve => {
      if (
        this.state.isOpen &&
        !this.state.isPositioned &&
        this.portalRef.current &&
        this.state.parentRect
      ) {
        const portalRect = sanitizeRect(
          this.portalRef.current.getBoundingClientRect()
        );

        const { top, left, position } = (this.props.positionStrategy ||
          defaultPositionStrategy)(
          this.state.parentRect,
          portalRect,
          this.props
        );

        this.setState(
          {
            isPositioned: true,
            left,
            position,
            top,
            portalRect
          },
          resolve
        );
      } else {
        resolve();
      }
    });

  render() {
    const { children, portalContent } = this.props;
    const {
      top,
      left,
      parentRect,
      portalRect,
      isPositioned,
      isOpen,
      position,
      transitionActive
    } = this.state;
    const relatedWidth = parentRect ? parentRect.width : 0;

    const portalStyle = {
      width: portalRect ? `${portalRect.width}px` : 'auto',
      left: `${left}px`,
      top: `${top}px`
    };

    const renderPortal = () => (
      <Portal>
        <div
          className={cn('hpl2-PositioningPortal', { isPositioned })}
          ref={this.portalRef}
          style={portalStyle}
          onClick={this.markClickEvent}
        >
          {renderProps(portalContent, {
            close: this.close,
            transitionStarted: this.transitionStarted,
            transitionEnded: this.transitionEnded,
            position,
            isOpen,
            relatedWidth
          })}
        </div>
      </Portal>
    );

    return (
      <>
        {children}
        {(isOpen || transitionActive) && renderPortal()}
      </>
    );
  }
}

export default PositioningPortal;
