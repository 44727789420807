import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';
import LoadingSpinner from '../LoadingSpinner';

/**
 * General button component available in different sizes and presentations.
 * Either the label or the icon property must be defined.
 */
const Button = ({
  classNames,
  type = 'button',
  onClick = noop,
  icon,
  label,
  theme = 'default',
  onBackground = 'light',
  ghost = false,
  ghostNoSpace = false,
  outline = false,
  pill = false,
  shadow = false,
  navigation = false,
  block = false,
  disabled = false,
  loading = false,
  href,
  target
}) => {
  const handleClick = event => {
    if (href) {
      event.preventDefault();
    }

    onClick(event, href);
  };

  return React.createElement(
    href ? 'a' : 'button',
    {
      className: cn(
        'hpl2-Button',
        {
          ghost,
          ghostNoSpace,
          outline,
          pill,
          shadow,
          navigation,
          block,
          icon,
          iconOnly: icon && !label,
          loading,
          [`theme-${theme}`]: theme,
          [`onBackground-${onBackground}`]: onBackground,
          disabled
        },
        classNames
      ),
      onClick: handleClick,
      ...(href
        ? {
            href,
            target
          }
        : {
            type,
            disabled
          })
    },
    <>
      {icon}
      {label && <span className="hpl2-Button__label">{label}</span>}
      {loading && <LoadingSpinner inherit />}
    </>
  );
};

Button.displayName = 'Button';
Button.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The type of the button element. Only accepted if `href` is not set. */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Call signature: (event, href) => {} */
  onClick: PropTypes.func,
  /** An instance of Icon. */
  icon: PropTypes.element,
  /** A label string. */
  label: PropTypes.string,
  /** The color theme of the button itself. */
  theme: PropTypes.oneOf([
    'default',
    'brand',
    'white',
    'positive',
    'negative',
    'action'
  ]),
  /** The background context in which the button is placed. */
  onBackground: PropTypes.oneOf(['light', 'dark']),
  /** Transparent background variant of the button. */
  ghost: PropTypes.bool,
  /** Equivalent to `ghost` but without any padding surrounding the button. */
  ghostNoSpace: PropTypes.bool,
  /** Outlined variant of the button. */
  outline: PropTypes.bool,
  /** Border radius variant of the button. */
  pill: PropTypes.bool,
  /** Box-shadowed variant of the button. */
  shadow: PropTypes.bool,
  /** Variant of the button used in navigation bars. */
  navigation: PropTypes.bool,
  /** Full width variant. */
  block: PropTypes.bool,
  /** Non-interactive variant. */
  disabled: PropTypes.bool,
  /** Variant with loading spinner. */
  loading: PropTypes.bool,
  /** If set the button acts as a link. */
  href: PropTypes.string,
  /** If an `href` is set a target for the link can also be set. */
  target: PropTypes.string
};

export default Button;
