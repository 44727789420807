import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * ProjectCard component is a specialized variant of the `Card` component.
 */
const ProjectCard = ({
  classNames,
  title,
  topline,
  footerLeft,
  footerRight,
  href,
  target,
  onClick,
  imageSource,
  topActions
}) => {
  const isLink = href || onClick;
  const hasFooter = footerLeft || footerRight;

  const handleClick = event => {
    if (onClick) {
      event.preventDefault();
      onClick(event, href);
    }
  };

  const renderLink = content =>
    isLink ? (
      <a
        className="hpl2-ProjectCard__link"
        href={href || '#'}
        target={target}
        onClick={handleClick}
      >
        {content}
      </a>
    ) : (
      content
    );

  return (
    <div className={cn('hpl2-ProjectCard', { isLink }, classNames)}>
      {renderLink(
        <>
          <div
            className="hpl2-ProjectCard__image"
            style={{ backgroundImage: `url(${imageSource})` }}
          />
          <div className="hpl2-ProjectCard__content">
            <span className="hpl2-ProjectCard__topline">{topline}</span>
            <strong className="hpl2-ProjectCard__title">{title}</strong>
          </div>
          {hasFooter && (
            <div className="hpl2-ProjectCard__footer">
              {footerLeft && (
                <span className="hpl2-ProjectCard__footerLeft">
                  {footerLeft}
                </span>
              )}
              {footerRight && (
                <span className="hpl2-ProjectCard__footerRight">
                  {footerRight}
                </span>
              )}
            </div>
          )}
        </>
      )}
      {topActions && (
        <span className="hpl2-ProjectCard__topActions">{topActions}</span>
      )}
    </div>
  );
};

ProjectCard.displayName = 'ProjectCard';
ProjectCard.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Title of the card. */
  title: PropTypes.string.isRequired,
  /** Topline above title. */
  topline: PropTypes.node.isRequired,
  /** Image which should have a ratio of 5:3 otherwise it will be cropped. */
  imageSource: PropTypes.string.isRequired,
  /** Arbitrary content aligned left in the footer of the card. */
  footerLeft: PropTypes.node,
  /** Arbitrary content aligned right in the footer of the card. */
  footerRight: PropTypes.node,
  /** Usually an instance of CircularButton which opens a context menu. Only possible if card does not act as a link. */
  topActions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  /** Href of the card when it acts as a link. If href and/or onClick are set then the card acts as a link. */
  href: PropTypes.string,
  /** Optional link target. */
  target: PropTypes.string,
  /** If href and/or onClick are set then the card acts as a link. Call signature: (event, href) => {} */
  onClick: PropTypes.func
};

export default ProjectCard;
