import React, { Component } from "react";
import SettingsComponent from "./settings.component";
import { getLoggedUserName } from "../../Utils/Utils";
import { appScopes } from "./../../services/queries/dataPlatFormApi";
export const ScopeContext = React.createContext();
class Settings extends Component {
  state = { appScopes: [] };

  handleNavigation = () => {
    this.props.history.push("/home");
  };
  componentDidMount = () => {
    appScopes()
      .then((res) => {
        this.setState({ appScopes: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <ScopeContext.Provider value={this.state.appScopes}>
        <SettingsComponent
          // scopes={}
          name={getLoggedUserName()}
          navigateToApps={this.handleNavigation}
          history={this.props.history}
        />
      </ScopeContext.Provider>
    );
  }
}
export default Settings;
