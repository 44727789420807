import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * An animated loading spinner.
 */
const LoadingSpinner = ({ classNames, size = 100, inherit }) => (
  <svg
    className={cn(
      'hpl2-LoadingSpinner',
      { inherit, [`size-${size}`]: size },
      classNames
    )}
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle className="hpl2-LoadingSpinner__back" cx="20" cy="20" r="18" />
    <circle className="hpl2-LoadingSpinner__active" cx="20" cy="20" r="18" />
  </svg>
);

LoadingSpinner.displayName = 'LoadingSpinner';
LoadingSpinner.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The size of the loading spinner. */
  size: PropTypes.oneOf([90, 100, 200, 300]),
  /** If set the spinner inherits the current text color and font size. */
  inherit: PropTypes.bool
};

export default LoadingSpinner;
