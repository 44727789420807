import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import omit from 'lodash/omit';

import PositioningPortal from '../PositioningPortal';

const renderProps = (element, props) =>
  typeof element === 'function' ? element(props) : element;

class PositioningPortalWithState extends React.Component {
  static displayName = 'PositioningPortalWithState';

  static propTypes = {
    /** Optional array of CSS utility classes. */
    classNames: PropTypes.arrayOf(PropTypes.string),
    /**
     * A react element or a renderprop with signature: ({close, open, isOpen}) => React.Element
     * close(): This function closes the portal.
     * open(): This function opens the portal.
     * toggle(): This function opents the portal when its closed and closes the portal when its open.
     * isOpen: A bool variable, whether the portal is open or closed.
     */
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    /**
     * The content of the portal. A react element or a renderprop with signature: ({close, isOpen, position, state}) => React.Element
     * close(): This function closes the portal.
     * isOpen: A bool variable, whether the portal is open or closed.
     * position: An enum for the current position of the portal (provided by the positionStrategy).
     * relatedWidth: Width of the element in the children property. Use this width to give the portalContent the same width.
     * transitionStarted(): Call on open if the portal has a transition.
     * transitionEnded(): Call after close when portal transition ended. transitionStarted() has to have been called before.
     */
    portalContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
      .isRequired,
    /** Called when content portal did open. */
    onOpen: PropTypes.func,
    /** Called when content portal did close. */
    onClose: PropTypes.func,
    /**
     * Optional positioning strategy.
     * Call signature: (parentRect, portalRect, props) => { left, top, position }
     */
    positionStrategy: PropTypes.func
  };

  static defaultProps = {
    onClose: noop
  };

  state = {
    isOpen: false
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false }, this.props.onClose);
  };

  toggle = () => {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  render() {
    const { children, ...restProps } = this.props;
    const { isOpen } = this.state;

    return (
      <PositioningPortal
        {...omit(restProps, ['onClose'])}
        isOpen={isOpen}
        onShouldClose={this.close}
      >
        {renderProps(children, {
          open: this.open,
          close: this.close,
          toggle: this.toggle,
          isOpen
        })}
      </PositioningPortal>
    );
  }
}

export default PositioningPortalWithState;
