import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LOCALES } from './locales';
import messages from "./messages";
const translate = (id) => <FormattedMessage id={id} defaultMessage={defaultMessage(id)} />;

let defaultMessage = function (id) {
    return messages[LOCALES.ENGLISH][id]
}

export default translate;