import React, { Children } from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * Groups multiple `TableRow`s.
 */
const TableRowGroup = ({ classNames, children, computedWidths }) => (
  <div className={cn('hpl2-TableRowGroup', {}, classNames)}>
    <div className="TableRowGroup__content">
      {Children.map(children, child =>
        React.cloneElement(child, { computedWidths })
      )}
    </div>
  </div>
);

TableRowGroup.displayName = 'TableRowGroup';
TableRowGroup.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** `TableRow`s as children. */
  children: PropTypes.node.isRequired,
  /** The computed widths for each cell (injected by parent). */
  computedWidths: PropTypes.arrayOf(PropTypes.string)
};

export default TableRowGroup;
