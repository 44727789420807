import React, { useState } from "react";
import { Select, Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";
const { Option } = Select;

const SelectScope = ({ data, scopeChange }) => {
  return (
    <Select
      mode="multiple"
      size={data.length}
      placeholder="select scope"
      defaultValue={["Admin"]}
      maxTagCount={2}
      style={{ width: "100px" }}
      onChange={scopeChange}
    >
      {data.map((scope) => {
        return <Option key={scope.scope_id}>{scope.scope_name}</Option>;
      })}
    </Select>
  );
};

export const ModifyScope = ({ data, userData, scopeChange }) => {
  const [scopeChanged, setScopeChange] = useState(false);
  return (
    <Row>
      <Col>
        <Select
          mode="multiple"
          size={data.length}
          placeholder="select scope"
          defaultValue={userData.scopes || "Admin"}
          maxTagCount={2}
          style={{ width: "100px" }}
          onChange={(value) => {
            setScopeChange(true);
            scopeChange(value, userData);
          }}
        >
          {data.map((scope) => {
            return <Option key={scope.scope_id}>{scope.scope_name}</Option>;
          })}
        </Select>
      </Col>
      <Col>
        <CheckOutlined
          style={{
            marginTop: "9px",
            color: "#40cc33",
            display: scopeChanged ? "block" : "none",
          }}
        />
      </Col>
    </Row>
  );
};

export default SelectScope;
