import axios from "axios";
function requestInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/json";
      let user = localStorage.getItem("user");
      if (!user) return config;
      user = JSON.parse(user);
      if (user.access_token) {
        config.headers["Authorization"] = "Bearer " + user.access_token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}
function responseInterceptor() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      if (
        !originalRequest.url.endsWith("/refresh-token") &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        let user = localStorage.getItem("user");
        if (!user) return error;
        user = JSON.parse(user);
        return axios
          .post("/platform/refresh-token", {
            refresh_token: user.refresh_token,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("user", JSON.stringify(res.data));
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + res.data.acess_token;
              return axios(originalRequest);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return Promise.reject(error);
    }
  );
}
requestInterceptor();
responseInterceptor();

export default axios;
