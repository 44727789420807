import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import cn from '../../lib/class-name';

/**
 * A general textarea input field.
 *
 * A ref created with "React.createRef()" can be passed to the component. It makes the inputs "focus()" function accessible.
 */
class TextField extends React.Component {
  inputRef = React.createRef();

  inputId = uniqueId('text-');

  static defaultProps = {
    onChange: noop,
    onBlur: noop,
    id: this.inputId
  };

  focus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  render() {
    const {
      classNames,
      disabled,
      error,
      id,
      label,
      maxLength,
      onBlur,
      onChange,
      placeholder,
      rows,
      value
    } = this.props;

    const handleChange = event => onChange(event.currentTarget.value, event);

    return (
      <div
        className={cn(
          'hpl2-TextField',
          { error, disabled, hasValue: value.length > 0, maxLength },
          classNames
        )}
      >
        <textarea
          ref={this.inputRef}
          className="hpl2-TextField__textarea"
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={handleChange}
          disabled={disabled}
          maxLength={maxLength}
          rows={rows}
        />
        <div className="hpl2-TextField__box" aria-hidden />
        <label htmlFor={id} className="hpl2-TextField__label">
          {label}
        </label>
        {maxLength && (
          <div className="hpl2-TextField__counter">
            {value.length} / {maxLength}
          </div>
        )}
      </div>
    );
  }
}

TextField.displayName = 'TextField';

TextField.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Id to set on the HTML input field. */
  id: PropTypes.string,
  value: PropTypes.string,
  /** Call signature: (value, event) => {} */
  onChange: PropTypes.func,
  /** Optional error modifier. */
  error: PropTypes.bool,
  /** Optional disabled modifier. */
  disabled: PropTypes.bool,
  /** Label of the field. */
  label: PropTypes.string,
  /** Number that limits the characters. Adds a counter to the bottom right corner. */
  maxLength: PropTypes.number,
  /** Number of rows on the textarea. */
  rows: PropTypes.number,
  /** Placeholder to be shown before user text is entered. */
  placeholder: PropTypes.string,
  /** Call signature: (event) => {} */
  onBlur: PropTypes.func
};

export default TextField;
