import React from "react";
import { Table } from "antd";
import {
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";

function onChange(sorter, extra) {
  console.log('params', sorter, extra);
}


function updateExpandIcons(props) {
  const { expanded, onExpand, record } = props;
  if (
    record.results === null ||
    (record.results.length > 0)) {
    if (record.children) {
      return (
        expanded ? (
          <UpOutlined onClick={(e) => {
            onExpand(record, e);
          }} />
        ) : (
          <DownOutlined onClick={(e) => {
            onExpand(record, e);
          }} />
        )
      )
    } else {
      return (
        <></>
      );
    }
  }
}

const TableComponent = (props) => {
  console.log(props)
  return (
    <Table className="tb-main"
      columns={props.columns}
      rowSelection={props.rowSelection}
      dataSource={props.data}
      onChange={onChange}
      expandable={props.expandable ? ({
        expandedRowRender: (record) => (
          <p style={{ margin: 0 }}>{record.description}</p>
        ),
      }) : ""}
      pagination={pagination(props.pagination)}
      rowClassName={props.rowClassName}
      rowKey={props.rowKey}
      loading={props.loading}
      scroll={props.scroll ? props.scroll : { y: 'calc(100vh - 380px)' }}
      expandIcon={(expandProps) => updateExpandIcons(expandProps)}
      size="middle"
    />
  );
}

const pagination = (pagination) => {
  if (pagination) {
    pagination.position = ["none", "bottomCenter"];
    return pagination;
  } else {
    return false;
  }
}

export default TableComponent;