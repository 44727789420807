import React from "react";
import { Layout } from "antd";
import SideBar from "../components/Sidebar/Sidebar";

const { Content } = Layout;

const LayoutContent = (props) => {
  const { history, children } = props;
  return (
    <React.Fragment>
      <Layout className="body-layout layout-bg">
        <SideBar history={history} />
        <Content className="content-layout layout-bg">
          <div style={{ height: "100%" }}>{children}</div>
        </Content>
      </Layout>
    </React.Fragment>
  );
};
export default LayoutContent;
