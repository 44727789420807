import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./ReportRedirectButton.scss";
import ReportIcon from "../ReportIcon/All Reports.svg"
import ReportIconHover from "../ReportIcon/All Reports rollover.svg"

const ReportButton = (props) => {
  const {
    navPath,
  } = props;
  const [ reportImg, setReportImg] = useState(ReportIcon);
  return (
    <NavLink to={navPath}>
      <button
        className="report-redirect-btn"
      >
        <img
          className="report-icon"
          alt="Report Icon"
          src={reportImg}
          onMouseOver={() => setReportImg(ReportIconHover)}
          onMouseOut={() => setReportImg(ReportIcon)}
        ></img>
      </button>
    </NavLink>
  );
};

export default ReportButton;