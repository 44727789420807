import {LOCALES} from '../locales';

export default{
    [LOCALES.ENGLISH]:{
        "Sidebar.AllApps": "All Apps",
        "Sidebar.Apps": "Apps",
        "Sidebar.Reports":  "Reports",
        "Sidebar.Others": "Others",
        "Sidebar.Settings": "Settings",
        "Sidebar.LogOut": "Logout",
        "Settings.Settings": "Settings",
        "Settings.Roles": "Roles",
        "Settings.DevOpsExt": "DevOps Extension",
        "Settings.FeatureToggle": "Feature Toggle",
        "Settings.Admin": "Admin",
        "Settings.User": "User",
        "Settings.UserAccessRequest": "New Users",
        "Settings.UserReAccessRequest": "Denied Users",
        "Settings.ViewUsers": "Approved Users",
        "Settings.UserId": "User ID",
        "Settings.Application": "Application",
        "Settings.AccessRights": "Access Rights",
        "Settings.Scope": "Scope",
        "Settings.AccessApproval": "Access Approval",
        "Settings.AccessDenied": "Denied Approval",
        "Settings.Date": "Requested Date",
        "Settings.ClientApiName": "Client/API Name",
        "Settings.LatestCodeChange": "Latest Code Change",
        "Settings.LatestRelease": "Latest Release",
        "Settings.Feature": "Feature",
        "Settings.Description": "Description",
        "Settings.Enabled": "Enabled",
        "Settings.AppStatistics": "App Statistics",
        "Settings.AppStatus": "App Status",
        "Settings.AppName": "App Name",
        "Settings.Status": "Status",
        "Login.Welcome":"Welcome to the Data Science Platform",
        "Login.ButtonText":"LOGIN WITH MICROSOFT",
        "Carousel.Heading1":"Data Science Platform",
        "Carousel.Heading2":"Content Automation",
        "Carousel.Heading3":"Price Response Curves",
        "Carousel.Heading4":"SSBI Manager Dashboard",
        "Carousel.Heading5":"Price Optimizer",
        "Carousel.Heading6":"Market List Prices",
        "Carousel.Heading7":"Pricing International",
        "Carousel.Description1":"Access data science information like never before. The all new BI Dashboard is simple, efficient and accurate.",        
        "Carousel.Description2":"The Content Automation Tool is an application aimed on supporting the content team to onboard suppliers and their articles into the Hoffmann data structure.",
        "Carousel.Description3":"The Price Response Curve App is an app, hosted on the DSC Platform. It supports the national and international Pricing Managers in analyzing on which discounts the revenues in a certain AKL are generated. Furthermore, it indicates where the list prices might be outdated, where high discounts are given or where many special conditions are granted.",
        "Carousel.Description4":"SSBI Manager is used to manage Power BI user access company wide and dashboard deployment. The Key users and BI Manager needed a tool manage the users who have access to reports which need to be deployed to Power BI Production workspaces.",     
        "Carousel.Description5":"The Price Optimizer Tool for Product Management supports the price negotiations with suppliers by leveraging the informational advantage of internal sales data to forecast and optimize the price increases across the basket of products purchased.",
        "Carousel.Description6":"One of the critical recurring tasks of Product Managers and Sales personnel is to judge the right market price of the products so that the product can be priced fairly with respect to the value it provides. Market data provides a good resource to come up with these analysis.",
        "Carousel.Description7":"Pricing International supports the international Product Managers to adjust the German List Pricing (basis for all Hoffmann Pricing) and the German discounts to the needs of their country each catalogue year."  
    }
}