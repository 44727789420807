import React, { Component } from "react";
import { Row, Col, Button} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

class DeniedApproveButtonCell extends Component {
  state = {
    removeActionPerformed: false,
    isRemoved: false,
  };

  getIconSymbol = () => {
    if (this.state.isRemoved) {
      return <CheckOutlined style={{ color: "#40cc33" }} />;
    } else {
      return <CloseOutlined style={{ color: "#ff0000" }} />;
    }
  };

  handleDeny = (flag) => {
    const { onClickDenyApprove, userRole } = this.props;
    onClickDenyApprove(flag, userRole).then((res) => {
      if (res) {
        this.setState({ isRemoved: true, removeActionPerformed: true });
      } else {
        this.showModal();
        this.setState({ isRemoved: false, removeActionPerformed: false });
      }
    });
  };
  
  render() {
      return this.state.removeActionPerformed ? (
        this.getIconSymbol()
      ) : (
        <Row>
          <Col flex="auto">
            <Button
              onClick={() => this.handleDeny(true)}
              className="re-auth-btn"
            >
              <span className="lbl" style={{ color: "#ffffff" }}>
                Remove
              </span>
            </Button>
          </Col>
        </Row>
      );   
  }
}

export default DeniedApproveButtonCell;
