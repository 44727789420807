import React, { Component } from "react";
import { Carousel } from 'antd';
import "./light-theme.scss";
import { I18nProvider } from '../../i18n';
import translate from '../../i18n/translate';
import { getUserLangPreference } from "../../i18n/utils";

export default class CustomSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localePref: "",
    };
  }
  render() {
    const settings = {
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,//stopped for now 2000
      dots: true,
      duration: 100,
    };
    return (
      <I18nProvider locale={getUserLangPreference()}>
        <div className="carousel-box">
          <Carousel  {...settings}> 
            <div className="body">
              <img
                src="/Images/login_carousel_1.png"
                alt="First slide"
              />
              <h3 className="title">{translate('Carousel.Heading1')}</h3>
              <p className="desc-text">
                {translate('Carousel.Description1')}
              </p>
            </div>
            <div className="body">
              <img
                src="/Images/login_carousel_2.png"
                alt="Second slide"
              />
              <h3 className="title">{translate('Carousel.Heading2')}</h3>
              <p className="desc-text">
                {translate('Carousel.Description2')}
              </p>
            </div>
            <div className="body">
              <img
                src="/Images/login_carousel_3.png"
                alt="Third slide"
              />
              <h3 className="title">{translate('Carousel.Heading3')}</h3>
              <p className="desc-text">
                {translate('Carousel.Description3')}
              </p>
            </div>
            <div className="body">
              <img
                src="/Images/login_carousel_4.png"
                alt="Fourth slide"
              />
              <h3 className="title">{translate('Carousel.Heading4')}</h3>
              <p className="desc-text">
                {translate('Carousel.Description4')}
              </p>
            </div>
            <div className="body">
              <img
                className="pom-img"
                src="/Images/login_carousel_5.svg"
                alt="Fifth slide"
              />
              <h3 className="title">{translate('Carousel.Heading5')}</h3>
              <p className="desc-text">
                {translate('Carousel.Description5')}
              </p>
            </div>
            <div className="body">
              <img
                className="mlp-img"
                src="/Images/login_carousel_6.svg"
                alt="Sixth slide"
              />
              <h3 className="title">{translate('Carousel.Heading6')}</h3>
              <p className="desc-text">
                {translate('Carousel.Description6')}
              </p>
            </div>
            <div className="body">
              <img
                className="pin-img"
                src="/Images/login_carousel_7.svg"
                alt="Seventh slide"
              />
              <h3 className="title">{translate('Carousel.Heading7')}</h3>
              <p className="desc-text">
                {translate('Carousel.Description7')}
              </p>
            </div>
          </Carousel>
        </div>
      </I18nProvider>
    );
  }
}
