import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

import { POSITION } from '../PositioningPortal/PositioningPortal';
import PositioningPortalWithState from '../PositioningPortalWithState';
import ContextMenuContext from './context-menu-context';

const SUBMENU_OFFSET = 9; // Keep in sync with vertical padding + submenu border of submenu

const positionStrategy = (parentRect, portalRect) => {
  const scrollX = global.scrollX || global.pageXOffset;
  const scrollY = global.scrollY || global.pageYOffset;
  const body = global.document.documentElement || global.document.body;

  // Open the content portal to the right if there is not enough space at the left,
  // but if there also isn't enough space at the left, open to the right.
  const alignLeft =
    parentRect.right + portalRect.width > body.clientWidth &&
    parentRect.left - portalRect.width > 0;

  // Open the content portal below if there is not enough space above,
  // but if there also isn't enough space below, open to above.
  const openBelow =
    parentRect.top + portalRect.height > body.clientHeight &&
    parentRect.bottom - portalRect.height > 0;

  const top = openBelow
    ? parentRect.bottom - portalRect.height + SUBMENU_OFFSET + scrollY
    : parentRect.top - SUBMENU_OFFSET + scrollY;

  const left = alignLeft
    ? parentRect.left - portalRect.width + scrollX
    : parentRect.right + scrollX;

  let position = POSITION.ABOVE_RIGHT;
  if (alignLeft && openBelow) {
    position = POSITION.BELOW_LEFT;
  }
  if (!alignLeft && openBelow) {
    position = POSITION.BELOW_RIGHT;
  }
  if (alignLeft && !openBelow) {
    position = POSITION.ABOVE_LEFT;
  }
  if (!alignLeft && !openBelow) {
    position = POSITION.ABOVE_RIGHT;
  }

  return {
    top,
    left,
    position
  };
};

/**
 * A sub menu item of `ContextMenu`.
 */
class ContextMenuSub extends React.Component {
  subMenu = React.createRef();

  handleClose = (event, close) => {
    if (event.relatedTarget) {
      const child = event.relatedTarget;
      const parent = this.subMenu.current;

      // Traverse up the DOM tree.
      let node = child.parentNode;
      while (node != null) {
        // If the related target is still within our subMenu, don't close the subMenu.
        if (node === parent) {
          return;
        }
        node = node.parentNode;
      }
    }

    // If the related target is not within our subMenu, close.
    close();
  };

  render() {
    const { classNames, link, subMenu } = this.props;
    const { onClose } = this.context;

    return (
      <PositioningPortalWithState
        positionStrategy={positionStrategy}
        portalContent={() => (
          <div className="hpl2-ContextMenuSub__subMenu" ref={this.subMenu}>
            {React.cloneElement(subMenu, {
              onClose
            })}
          </div>
        )}
      >
        {({ open, close }) => {
          const { disabled } = link.props;

          return (
            <div className={cn('hpl2-ContextMenuSub', {}, classNames)}>
              {React.cloneElement(link, {
                subMenu: true,
                onMouseOver: !disabled ? open : noop,
                onFocus: !disabled ? open : noop,
                onMouseOut: event => this.handleClose(event, close),
                onBlur: event => this.handleClose(event, close)
              })}
            </div>
          );
        }}
      </PositioningPortalWithState>
    );
  }
}

ContextMenuSub.displayName = 'ContextMenuSub';
ContextMenuSub.contextType = ContextMenuContext;
ContextMenuSub.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The label of the link. */
  link: PropTypes.node.isRequired,
  /** An instance of ContextMenu to be used as subMenu. */
  subMenu: PropTypes.node.isRequired
};

export default ContextMenuSub;
