import React, { Component } from "react";
import LayoutContent from "./layout.component";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "../components/Modal/IdleTimeOutModal";

class LayoutComponent extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      timeout:
        1000 * JSON.parse(localStorage.getItem("user")).refresh_expires_in,
      userLoggedIn: false,
      isTimedOut: false,
    };
    this.idleTimer = null;
  }

  onAction = () => {
    this.setState({ isTimedOut: false });
  };

  onActive = () => {
    this.setState({ isTimedOut: false });
  };

  onIdle = () => {
    const isTimedOut = this.state.isTimedOut;
    if (!isTimedOut) {
      this.setState({ showModal: true });
      this.setState({ isTimedOut: true });
    }
  };

  handleLogout = () => {
    this.props.history.push("/");
    localStorage.removeItem("user");
  };
  render() {
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          timeout={this.state.timeout}
        />
        <LayoutContent
          history={this.props.history}
          children={this.props.children}
        ></LayoutContent>
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
      </React.Fragment>
    );
  }
}

export default LayoutComponent;
