import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

/**
 *  This component is used only as a direct child of the `TabBar`.
 */
const TabBarItem = ({
  classNames,
  label,
  active,
  disabled,
  icon,
  onClick = noop,
  href = '#'
}) => {
  const handleClick = event => {
    event.preventDefault();
    if (!disabled) {
      onClick(event, href);
    }
  };

  return (
    <a
      className={cn('hpl2-TabBarItem', { active, disabled }, classNames)}
      href={href}
      onClick={handleClick}
      aria-disabled={disabled}
    >
      {icon}
      <span className="hpl2-TabBarItem__label">{label}</span>
    </a>
  );
};

TabBarItem.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The label of the tab bar item. */
  label: PropTypes.string.isRequired,
  /** Active state modifier: The current tab bar item selected. */
  active: PropTypes.bool,
  /** Disabled state modifier. */
  disabled: PropTypes.bool,
  /** An instance of Icon. */
  icon: PropTypes.element,
  /** event.preventDefault() is already called by the TabBarItem component itself. Call signature: (event, href) => {} */
  onClick: PropTypes.func,
  /** The href target URL of the link. */
  href: PropTypes.string
};

export default TabBarItem;
