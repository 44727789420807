import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * A separator used within `ContextMenu`.
 */
const ContextMenuSeparator = ({ classNames }) => (
  <div className={cn('hpl2-ContextMenuSeparator', {}, classNames)} />
);

ContextMenuSeparator.displayName = 'ContextMenuSeparator';
ContextMenuSeparator.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string)
};

export default ContextMenuSeparator;
