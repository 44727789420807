import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';
import * as icons from '../../asset/icon';

import Icon from '../Icon';

const iconMap = {
  default: icons.Info,
  positive: icons.CheckCircle,
  negative: icons.Warning,
  warning: icons.Info
};

/**
 * A notification to hint the user about any state changes in an application.
 */
const Notification = ({
  classNames,
  children,
  theme = 'default',
  onClose = noop
}) => (
  <div
    className={cn(
      'hpl2-Notification',

      {
        [theme]: Boolean(theme)
      },
      classNames
    )}
  >
    <div className="hpl2-Notification__type">
      <Icon block source={iconMap[theme]} />
    </div>
    <div className="hpl2-Notification__content">{children}</div>
    <button
      type="button"
      className="hpl2-Notification__close"
      onClick={onClose}
    >
      <Icon block source={icons.Close} />
    </button>
  </div>
);

Notification.displayName = 'Notification';
Notification.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Content text. */
  children: PropTypes.node.isRequired,
  /** Theme of notification. */
  theme: PropTypes.oneOf(['default', 'positive', 'negative', 'warning']),
  /** Called when user clicks on close button. Call signature: (event) => {} */
  onClose: PropTypes.func
};

export default Notification;
