import { useState, useEffect, useRef } from 'react';
import noop from 'lodash/noop';

export default (timeout, onTimeout = noop) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [timedOut, setTimedOut] = useState(false);
  const timeoutRef = useRef(null);

  const stopTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const startTimeout = () => {
    stopTimeout();

    if (timeout > 0) {
      timeoutRef.current = setTimeout(() => {
        setTimedOut(true);
        onTimeout();
      }, timeout);
    }
  };

  useEffect(() => {
    startTimeout();

    return () => {
      stopTimeout();
    };
  }, []);

  return {
    mouseOver,
    timedOut,
    handleMouseEnter: () => {
      setMouseOver(true);
      stopTimeout();
    },
    handleMouseLeave: () => {
      setMouseOver(false);
      startTimeout();
    }
  };
};
