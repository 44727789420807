import React, { Component } from "react";
import ReportButton from "../ReportButton/ReportButton";
import ReportsRedirectButton from "../ReportsRedirectButton/index";
import axios from "axios";


class AllAppsReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportsList: []
        }
    }

    componentDidMount() {
        let user = localStorage.getItem("user");
        if (!user) return;
        user = JSON.parse(user);
        if (!user.user_info || !user.user_info.email) return;
        let data = {};
        data["ad-groups"] = "*";
        const options = {
            headers: { "Content-Type": "application/json" },
        };
        axios
            .post(
                `ssbi-api/v1/user-management?email=${user.user_info.email}`,
                data,
                options
            )
            .then((response) => {
                if (response && response.status === 200) {
                    this.setState({ reportsList: response.data})

                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    render() {
        const { reportsList } = this.state
        return (
            <div className="reports-container">
                    <div className="workspace-item">
                        <h2 className="heading">Reports</h2>
                        {
                            reportsList.slice(0, 5).map((report) => (
                                <ReportButton appName={report.reportname} navPath={`reports/${report.reportid}`}/>
                            ))
                        }
                        <ReportsRedirectButton navPath={`reports`}/>
                    </div>
            </div>
        );

    }
};
export default AllAppsReports