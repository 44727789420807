import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import cn from '../../lib/class-name';

import * as icons from '../../asset/icon';
import Icon from '../Icon';
import ContextMenuContext from './context-menu-context';

/**
 * A link used within `ContextMenu`.
 */
const ContextMenuLink = ({
  classNames,
  href = '#',
  label,
  disabled = false,
  icon,
  subMenu = false,
  subMenuSibling = false,
  onClick = noop,
  onMouseOver = noop,
  onFocus = noop,
  onMouseOut = noop,
  onBlur = noop
}) => {
  const { onClose } = useContext(ContextMenuContext);
  const handleClick = event => {
    event.preventDefault();
    if (!subMenu && !disabled) {
      onClose();
      onClick(event, href);
    }
  };

  return (
    <a
      className={cn(
        'hpl2-ContextMenuLink',
        { icon: !!icon, disabled, subMenuSibling },
        classNames
      )}
      href={href}
      onClick={handleClick}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      onMouseOut={onMouseOut}
      onBlur={onBlur}
    >
      {icon && <span className="hpl2-ContextMenuLink__icon">{icon}</span>}
      <span className="hpl2-ContextMenuLink__label">{label}</span>
      {subMenu && (
        <Icon
          classNames={['hpl2-ContextMenuLink__chevron']}
          source={icons.ChevronRight}
        />
      )}
    </a>
  );
};

ContextMenuLink.displayName = 'ContextMenuLink';
ContextMenuLink.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** The href target URL of the link. */
  href: PropTypes.string,
  /** The label of the link. */
  label: PropTypes.string.isRequired,
  /** Disabled state modifier. */
  disabled: PropTypes.bool,
  /** Optional instance of Icon. */
  icon: PropTypes.element,
  /** Boolean modifier that this link is used to open a submenu, for internal use only (set by ContextMenuSub). */
  subMenu: PropTypes.bool,
  /** Boolean modifier that this link is used alongside a submenu in ContextMenu. */
  subMenuSibling: PropTypes.bool,
  /**
   * event.preventDefault() is already called by the ContextMenuLink
   * component itself. Call signature: (event, href) => {}
   */
  onClick: PropTypes.func,
  /** Event handling function used for interaction with link as submenu link. */
  onMouseOver: PropTypes.func,
  /** Event handling function used for interaction with link as submenu link. */
  onFocus: PropTypes.func,
  /** Event handling function used for interaction with link as submenu link. */
  onMouseOut: PropTypes.func,
  /** Event handling function used for interaction with link as submenu link. */
  onBlur: PropTypes.func
};

export default ContextMenuLink;
