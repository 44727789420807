export default {
  ssbi: {
    textOnHover: "#29b6f6",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/SSBI Manager (Rollover).svg",
    imageOnOut: "./icons/SSBI Manager.svg",
    active: true
  },
  price_response: {
    textOnHover: "#24BF97",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Price Response Curves (Rollover).svg",
    imageOnOut: "./icons/Price Response Curves.svg",
    active: true
  },
  content_automation: {
    textOnHover: "#9F66EF",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Content Automation (Rollover).svg",
    imageOnOut: "./icons/Content Automation.svg",
    active: true
  },
  market_list: {
    textOnHover: "#fc5c65",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Market List Prices (Rollover).svg",
    imageOnOut: "./icons/Market List Prices.svg",
    active: true
  },
  kam: {
    textOnHover: "#7d7d7d",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/KAM Price Optimizer (Under Construction).svg",
    imageOnOut: "./icons/KAM Price Optimizer (Under Construction).svg",
    active: false
  },
  pm: {
    textOnHover: "#c44a83",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/PM Price Optimizer (Rollover).svg",
    imageOnOut: "./icons/PM Price Optimizer.svg",
    active: true
  },
  pin: {
    textOnHover: "#546DE5",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Pricing International (Rollover).svg",
    imageOnOut: "./icons/Pricing International.svg",
    active: true
  },
  sales_report: {
    textOnHover: "#F7AC8C",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Sales Report (Rollover).svg",
    imageOnOut: "./icons/Sales Report.svg",
  },
  price_report: {
    textOnHover: "#46C1CE",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Pricing Report (Rollover).svg",
    imageOnOut: "./icons/Pricing Report.svg",
  },
  source_report: {
    textOnHover: "#DE859E",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Sourcing Report (Rollover).svg",
    imageOnOut: "./icons/Sourcing Report.svg",
  },
  others: {
    textOnHover: "#AFC0CB",
    textonOut: "#7d7d7d",
    imageOnHover: "./icons/Berechnungsmatrix  Sonderwerkzeuge (Rollover).svg",
    imageOnOut: "./icons/Berechnungsmatrix  Sonderwerkzeuge.svg",
  },
};
