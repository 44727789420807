import React from "react";
import { Logo, Icon, icons } from "@hoffmann/pattern-library-2";
import { MdAssignment, MdDashboard } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import Open from "./SideBarOpen.svg";
import Close from "./SideBarClose.svg";
import "./styles.css";
import "antd/dist/antd.css";
import { Layout, Menu, Avatar, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { I18nProvider, LOCALES } from '../../i18n';
import translate from '../../i18n/translate';
import { getUserLangPreference } from "../../i18n/utils";
import { triggerEvents, setUserLangPref } from "../../Utils/Utils";
import { collectLogs } from "../../services/ga4/ga4.events";

const { Sider } = Layout;

const path = ["/home", "/apps", "/others", "/settings", "/reports"];

export default class SiderBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      label: "Data Science",
      topline: "Hoffmann Group",
      selected: localStorage.getItem("selectedMenu") || "/home",
      locale: getUserLangPreference(),
      enActive: true,
      deActive: false,
    }
  }

  logout() {
    collectLogs("Platform", JSON.parse(localStorage.getItem("user")).user_info.email, "Logout", "Logout Success");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedMenu");
    window.location.href = "/";
  }

  setLanguage = (event) => {
    this.setState({ locale: event.target.lang });
    setUserLangPref(event.target.lang);
    triggerEvents(event.target.lang);
  }

  getUserName(initialAlone) {
    let user = localStorage.getItem("user");
    if (!user) return "";
    user = JSON.parse(user);
    if (!user.user_info || !user.user_info.name) return "";
    let username = user.user_info.name.split("(Extern)")[0].trim();
    if (initialAlone) {
      return username.charAt(0);
    }
    if (username.length > 14) {
      return username.slice(0, 12) + "...";
    } else {
      return username;
    }
  }

  handleMenuClick = (mypath) => {
    if (path.includes(mypath)) {
      this.props.history.push(mypath);
      localStorage.setItem("selectedMenu", mypath);
      this.setState({ selected: mypath });
    }
  };

  toggle = () => {
    const isCollapsed = !this.state.collapsed;
    this.setState({
      collapsed: isCollapsed,
      label: isCollapsed ? "" : "Data Science",
      topline: isCollapsed ? "" : "Hoffmann Group",
    });
  };

  render() {
    const { selected, collapsed, locale } = this.state;
    const location = this.props.history.location.pathname;
    const selectedPath = location === "/home" ? location : selected;
    const logoclasses = collapsed ? "logo-collapsed" : "logo";
    const logolabelclasses = collapsed ? "logo-label-collapsed" : "logo-label";
    const footerClasses = collapsed ? "footer-menu-collapsed" : "footer-menu";
    const footerLabelClasses = collapsed ? "footer-label-collapsed" : "footer-label";
    const langButtonClasses = collapsed ? "lang-btn-container-collapsed" : "lang-btn-container";
    return (

      <Sider
        className="layout-bg"
        trigger={null}
        collapsedWidth={95}
        collapsible
        collapsed={collapsed}
      >
        <I18nProvider locale={getUserLangPreference()}>
          <div className="sider-layout">
            <div className={[logoclasses]}>
              <Logo
                classNames={[logolabelclasses]}
                label={this.state.label}
                topline={this.state.topline}
              />
            </div>

            <Menu
              className="main-menu layout-bg"
              mode="inline"
              defaultSelectedKeys={["/home"]}
              selectedKeys={[selectedPath]}
            >
              <Menu.Item
                key="/home"
                style={{ color: "#7d7d7d" }}
                onClick={() => {
                  this.handleMenuClick("/home");
                }}
              >
                <Icon source={icons.Apps} classNames={["icon-item"]} />
                <NavLink to="/home" className="link menu-label">
                  {translate('Sidebar.AllApps')}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="/apps"
                style={{ color: "#7d7d7d" }}
                onClick={() => {
                  this.handleMenuClick("/apps");
                }}
              >
                <MdDashboard className="icon-item" />
                <NavLink to="/apps" className="menu-label">
                  {translate('Sidebar.Apps')}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="/reports"
                style={{ color: "#7d7d7d" }}
                onClick={() => {
                  this.handleMenuClick("/reports");
                }}
              >
                <MdAssignment className="icon-item" />
                <NavLink to="/reports" className="menu-label">
                  {translate('Sidebar.Reports')}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="/others"
                style={{ color: "#7d7d7d" }}
                onClick={() => {
                  this.handleMenuClick("/others");
                }}
              >
                <AiOutlineAppstoreAdd className="icon-item" />
                <NavLink to="/others" className="menu-label">
                  {translate('Sidebar.Others')}
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="/settings"
                style={{ color: "#7d7d7d" }}
                onClick={() => {
                  this.handleMenuClick("/settings");
                }}
              >
                <Icon source={icons.Settings} classNames={["icon-item"]} />
                <NavLink to="/settings" className="link menu-label">
                  {translate('Sidebar.Settings')}
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>
          {collapsed ? (
            <img
              src={Open}
              className="open-button"
              alt="open"
              onClick={this.toggle}
            />
          ) : (
            <img
              src={Close}
              className="close-button"
              alt="close"
              onClick={this.toggle}
            />
          )}

          <div className="second-menu">
            <div className={[langButtonClasses]}>
              <button lang={LOCALES.ENGLISH} className={[locale === LOCALES.ENGLISH ? "lang-btn-active" : "lang-btn-inactive"]} onClick={this.setLanguage}>
                EN
              </button>
              <button lang={LOCALES.GERMAN} className={[locale === LOCALES.GERMAN ? "lang-btn-active" : "lang-btn-inactive"]} onClick={this.setLanguage}>
                DE
              </button>
            </div>
            <Row style={{ cursor: "default" }} className={[footerClasses]}>
            <div className="flex-layout">
              <Col>
                <Avatar style={{ cursor: "default" }} className="avatar" shape="square" size={40}>
                  {this.getUserName(true)}
                </Avatar>
              </Col>
              <Col>
                <span style={{ marginLeft: "30px", cursor: "default" }} className={[footerLabelClasses]}>
                  {this.getUserName()}
                </span>
              </Col>
              </div>
            </Row>
            <a onClick={this.logout}>
              <Row className={[footerClasses]}>
              <div className="flex-layout">
                <Col>
                  <Icon source={icons.Logout} classNames={["logout-icon"]} />
                </Col>
                <Col style={{ marginLeft: "39px" }} className={[footerLabelClasses]}>
                  {translate('Sidebar.LogOut')}
                </Col>
                </div>
              </Row>
            </a>
          </div>
        </I18nProvider>
      </Sider>
    );
  }
}
