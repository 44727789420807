import React from "react";
import "./needAccessBtn.scss";
import NeedAccessIcon from "./NeedAccessIcon.svg"

const NeedAccessButton = (props) => {
    const {
        getAccess,
    } = props;
    return (
        <div className="need-access-container">
        <button className="need-access-btn" onClick={getAccess}>
            <img 
            className="need-access-icon"
            alt="Request Access"
            src={NeedAccessIcon}></img>
            Request Access
        </button>
        </div>
    )
};
export default NeedAccessButton;