import React, { Component } from "react";
import PropTypes from "prop-types";
import { LeftOutlined } from "@ant-design/icons";
import "./ReturnButton.scss";

class ReturnButton extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="return-button">
          <button
            className="back-button"
            onClick={(e) => {
              e.preventDefault();
              this.props.onClick(this.props.path);
            }}
          >
            <LeftOutlined className="back-arrow" />
          </button>
          <p className="header-one">{this.props.headerOne}</p>
          <p className="header-two">{this.props.headerTwo}</p>
        </div>
      </React.Fragment>
    );
  }
}

ReturnButton.propTypes = {
  headerOne: PropTypes.string.isRequired,
  headerTwo: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ReturnButton;
