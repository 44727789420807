import React, { useState } from "react";
import { FilterFilled } from "@ant-design/icons";
import { Button, Row, Col, Drawer, Select, DatePicker } from "antd";
import "./statisticsFilter.scss";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from 'moment';

const { Option } = Select;

const StatisticsFilterComponent = (props) => {
    const [selectedGroupBy, setSelectedGroupBy] = useState(["app_name"]);
    const [selectedUserType, setSelectedUserType] = useState(["External"]);
    const [selectedFromMonth, setSelectedFromMonth] = useState(props.currentMonth);
    const [selectedToMonth, setSelectedToMonth] = useState(props.currentMonth);
    const [zeroHits, setZeroHits] = useState(false);

    const handleSelectChange = async (value) => {
        setSelectedGroupBy(value);
        props.groupBy = value;
    }

var  disabledDate = (current) => {
    return current && current > moment().endOf('day');
}

    const handleSelectType = async (value) => {
        setSelectedUserType(value);
        props.where = value;
    }

    const setToggleCheck = (obj) => {
        setZeroHits(obj.target.checked);
        props.zeroHits = obj.target.checked;
    }

    const handleResetChange = () => {
        setSelectedFromMonth(props.currentMonth);
        setSelectedToMonth(props.currentMonth);
        setSelectedGroupBy(["app_name"]);
        setSelectedUserType(["External"]);
        setZeroHits(false);
        props.handleReset();
    };

    const handleFromMonthChange = (value, e) => {
        if ( value == null) {
            setSelectedFromMonth(moment());
        }
        else if(value !=null) {
            setSelectedFromMonth(e);
            props.duration.fromMonth =`${e}`;
        }
       
    };

    const handleToMonthChange = (value, e) => {   
        if(value == null) {
            setSelectedToMonth(moment());
        }
        else if(value != null) {
            setSelectedToMonth(e);
            props.duration.toMonth = `${e}`;
        }  
    };
   
    const onApplyChange = () => {
        props.handleChange(props.groupBy, props.where, props.zeroHits, props.duration);
    }

    return (
        <div className="filter-container">
            <Drawer
                wrapClassName="filter-modal"
                visible={props.visible}
                maskClosable={true}
                onClose={
                    props.hideModal
                }
                footer={
                    <div>
                        <Row className="apply-row">
                            <Col flex="auto">
                                <Button onClick={onApplyChange}>APPLY</Button>
                            </Col>
                        </Row>
                        <Row className="cancel-row">
                            <Col flex="auto">
                                <Button onClick={props.hideModal}>CANCEL</Button>
                            </Col>
                        </Row>
                    </div>
                }
                closable={false}
                centered={false}
                width="331px"
            >
                <div className="filter-content">
                    <Row className="head-row">
                        <Col flex="auto">
                            <div>
                                <span className="filter-txt">
                                    <FilterFilled /> Filter
                                </span>
                                <span className="reset-txt" onClick={handleResetChange}>
                                    Reset
                                </span>
                            </div>
                        </Col>
                    </Row>
                   
                    <Col flex="auto">
                        <div className="statistics-filter-label">Group By</div>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            value={selectedGroupBy}
                            defaultValue={["Application"]}
                            onChange={handleSelectChange}
                        >
                            <Option disabled key={"Application"} value={"app_name"}>Application</Option>
                            <Option key={"User"} value={"user_email"}>User</Option>
                        </Select>
                    </Col>
                    <Col flex="auto" style={{ marginTop: 20 }}>
                        <div className="statistics-filter-label">User Type</div>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            value={selectedUserType}
                            defaultValue={["External"]}
                            onChange={handleSelectType}
                        >
                            <Option key={"Internal"} value={"Internal"}>Internal</Option>
                            <Option key={"External"} value={"External"}>External</Option>
                        </Select>
                    </Col>
                    <Col flex="auto" style={{ marginTop: 20 }}>
                        <div className="statistics-filter-label">Duration</div>
                        <div style={{ display: 'flex' }}>
                            <div className="statistics-filter-duration-content">
                                <p className="statistics-filter-duration-title">From</p>
                                <DatePicker 
                                disabledDate={disabledDate}
                                value={moment(selectedFromMonth)}
                                format = "YYYY-MMM"
                                picker="month"
                                style={{ width: '130px' }}
                                onChange={handleFromMonthChange}
                                />
                            </div>
                            <div className="statistics-filter-duration-content">
                                <p className="statistics-filter-duration-title">To</p>
                                <DatePicker 
                                disabledDate={disabledDate}
                                value={moment(selectedToMonth)}
                                format = "YYYY-MMM"
                                picker="month"
                                style={{ width: '130px' }}
                                onChange={handleToMonthChange}
                                />
                            </div>
                        </div>
                    </Col>
                    {props.selected === "App Search" ? (
                        <Col flex="auto" style={{ marginTop: 20 }}>
                            <Checkbox checked={zeroHits} onChange={setToggleCheck} />
                            <span className="filter-label"> Zero hits</span>
                        </Col>
                    ) : ""}
                </div>
            </Drawer>
        </div>
    );
};

export default StatisticsFilterComponent;
