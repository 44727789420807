export default function className(componentName, modifiers = {}, utils = []) {
  const modifiersList = Object.keys(modifiers)
    .reduce(
      (accumulator, name) =>
        modifiers[name] ? [...accumulator, name] : accumulator,
      []
    )
    .map(name => `${componentName}--${name}`);

  return [componentName, ...modifiersList, ...utils].join(' ');
}
