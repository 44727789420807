import React from 'react';
import PropTypes from 'prop-types';

import cn from '../../lib/class-name';

/**
 * A section which groups multiple form fields.
 */
const FieldGroup = ({ classNames, label, action, children }) => (
  <section className={cn('hpl2-FieldGroup', {}, classNames)}>
    {label && (
      <div className="hpl2-FieldGroup__header">
        <h3 className="hpl2-FieldGroup__label">{label}</h3>
        {action && <div className="hpl2-FieldGroup__action">{action}</div>}
      </div>
    )}
    {children}
  </section>
);

FieldGroup.displayName = 'FieldGroup';
FieldGroup.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** Label for the group. */
  label: PropTypes.string,
  /** Optional action placed in header of the field group. */
  action: PropTypes.node,
  /** Arbitrary form fields. */
  children: PropTypes.node.isRequired
};

export default FieldGroup;
