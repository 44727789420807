import React from 'react';
import PropTypes from 'prop-types';
import * as contrast from 'wcag-contrast';

import cn from '../../lib/class-name';
import vars from '../../vars.json';

const LIGHT_COLOR = vars.color.white['100'];
const DARK_COLOR = vars.color.layout['100'];

const shouldInvert = hex =>
  contrast.hex(hex, LIGHT_COLOR) > contrast.hex(hex, DARK_COLOR);

/**
 * The Color Item used for a multi color drop down.
 */
const OptionFlyoutColorItem = ({
  classNames,
  code,
  hex,
  name = '',
  selected = false,
  onClick = () => {}
}) => (
  <button
    type="button"
    className={cn(
      'hpl2-OptionFlyoutColorItem',
      { selected, invert: shouldInvert(hex) },
      classNames
    )}
    onClick={onClick}
    style={{ backgroundColor: hex }}
  >
    <span className="hpl2-OptionFlyoutColorItem__code">{code}</span>
    <span className="hpl2-OptionFlyoutColorItem__name">{name}</span>
  </button>
);

OptionFlyoutColorItem.displayName = 'OptionFlyoutColorItem';
OptionFlyoutColorItem.propTypes = {
  /** Optional array of CSS utility classes. */
  classNames: PropTypes.arrayOf(PropTypes.string),
  /** If no label is provided the value will be shown. */
  label: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  /** The color code of the DropDown element. */
  code: PropTypes.string.isRequired,
  /** The color code (must be unique). */
  value: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  /** The hex-code of the color (eg. #ffcba2). */
  hex: PropTypes.string.isRequired,
  /** The name of the color. */
  name: PropTypes.string,
  /** This property will be handled by OptionFlyout. */
  selected: PropTypes.bool,
  /** This property will be handled by OptionFlyout. */
  onClick: PropTypes.func
};

export default OptionFlyoutColorItem;
